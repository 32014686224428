import React, { useEffect, useRef, useState } from "react";
import { TextField, Popover, ClickAwayListener, Card, Button, IconButton, Typography, Box, Grid, FormControl, Divider, Fab } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { HiUpload } from "react-icons/hi";
import { BiImport } from "react-icons/bi";
import { BiLinkAlt } from "react-icons/bi";
import { CgRecord } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import SpeedDial from "@material-ui/lab/SpeedDial";
import { useStyles } from "../Creator/CreatorStyle/SpeedialCreatorStyle";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { createTheme } from "@material-ui/core/styles";
import { HiOutlineDocumentText } from "react-icons/hi";
import { GiPadlock } from "react-icons/gi";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ThemeProvider } from "@material-ui/core/styles";
import { BsMusicNoteBeamed, BsGearFill } from "react-icons/bs";
import { CgRadioChecked } from "react-icons/cg";
import { AiFillCamera } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { TiDeleteOutline } from "react-icons/ti";
import { IoStopOutline } from "react-icons/io5";
import MenuItem from "@material-ui/core/MenuItem";
import { scrapingoverview } from "../../ApiComponent/getApiFunction";
import { useReactMediaRecorder } from "react-media-recorder";
import { VscDebugStart } from "react-icons/vsc";
import "../../Stylesheet/Creator/UploadDropzone.scss";
import { default as VideoUpload } from "../../AppComponents/Video/Upload";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export default function SpeedialCreator(props) {
  const { setDataRefresher, isReload, userType } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);

  const breakpoints = createBreakpoints({
    values: {
      xs: 320,
      sm: 376,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  });

  const themes = createTheme({
    palette: {
      type: "dark",
    },
    typography: {
      fontFamily: "Manrope",
    },
    overrides: {
      MuiSpeedDialAction: {
        staticTooltipLabel: {
          backgroundColor: "white",
          width: 200,
          color: "black",
          fontSize: 13,
        },
        fab: {
          backgroundColor: "#0098D9",
          color: "white",
          minWidth: 50,
          minHeight: 50,
        },
      },
      MuiPopover: {
        paper: {
          borderRadius: "60px",
          zIndex: "980 !important",
        },
      },
    },
  });
  const SpeedialRecordTheme = createTheme({
    overrides: {
      MuiSpeedDialAction: {
        staticTooltipLabel: {
          backgroundColor: "white",
          color: "black",
          fontSize: 13,
          marginTop: "-20px",
        },
        fab: {
          backgroundColor: "#0098D9",
          color: "white",
          minWidth: 50,
          minHeight: 50,
          marginLeft: "10px",
        },
      },
      MuiPopover: {
        paper: {
          // borderRadius: "50%",
        },
      },
      MuiSpeedDial: {
        fab: {
          marginLeft: "5px",
        },
      },
    },
  });
  const speedialPopoverImport = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          borderRadius: "40px",
          marginLeft: "-68px",
          marginTop: "94px",
          background: "#141c22",
        },
      },
      MuiIconButton: {
        root: {
          color: "white",
        },
      },
      MuiInputBase: {
        input: {
          color: "white",
          borderRadius: "5px",
          border: "1px solid white",
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: "#0098D9 !important",
        },
      },
    },
  });
  const speedialPopoverRecord = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          borderRadius: "40px",
          marginLeft: "-40px",
          marginTop: "210px",
          // marginLeft: "-938px",
          // marginTop: "305px",
          background: "#141c22",
        },
      },
      MuiIconButton: {
        root: {
          color: "white",
        },
      },
      MuiOutlinedInput: {
        input: {
          color: "white",
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: "#0098D9 !important",
        },
      },
    },
  });
  const speedialPopoverUpload = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          marginLeft: "-68px",
          // marginTop: "225px",
        },
      },
      // MuiBox: {
      //   root: {
      //     minHeight: "70px !important",
      //   },
      // },
    },
  });
  const speedialPopoverStartRecord = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          borderRadius: "40px",
          marginLeft: "-2205px",
          marginTop: "273px",
          background: "#141c22",
          [breakpoints.down("2561")]: {
            marginLeft: "-2200px",
            marginTop: 380,
          },
          [breakpoints.down("1921")]: {
            marginLeft: "-1562px",
            marginTop: 298,
          },
          [breakpoints.down("1441")]: {
            marginLeft: "-1082px",
            marginTop: 496,
          },
          [breakpoints.down("1368")]: {
            marginLeft: "-1005px",
            marginTop: 215,
          },
          [breakpoints.down("1025")]: {
            marginLeft: "-665px",
            marginTop: 495,
          },
          [breakpoints.down("769")]: {
            marginLeft: "-425px",
            marginTop: 468,
          },
          [breakpoints.down("721")]: {
            marginLeft: "-425px",
            marginTop: 368,
          },
        },
      },
    },
  });
  //==================================Screen Record Popover==================================
  const [mode, setMode] = useState("");
  const [anchorElStartRecord, setAnchorElStartRecord] = useState(null);
  const [speedialStartRecord, setSpeedialStartRecord] = useState(false);

  const handleClicksStartRecord = (event) => {
    setAnchorElStartRecord(event.currentTarget);
    setAnchorElStartRecord(true);
    setSpeedialStartRecord(true);
    setAnchorElCamera(null);
    setAnchorElRecord(null);
  };

  const handleCloseStartRecord = () => {
    setAnchorElStartRecord(false);
    setSpeedialRecord(false);
    setSpeedialStartRecord(false);
    setAnchorElSound(null);
    setMode("");
  };

  const opensStartRecord = Boolean(anchorElStartRecord);
  const idStartRecord = opensStartRecord ? "simple-popover" : undefined;

  //==================================Screen Record Function==================================

  function Preview({ previewStream, audio }) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current && previewStream) ref.current.srcObject = previewStream;
    }, [previewStream]);
    if (!previewStream || audio) return null;
    return <video ref={ref} autoPlay controls width={400} height={300} />;
  }

  function Output({ mediaBlobUrl, audio }) {
    if (audio) return <audio src={mediaBlobUrl} controls autoPlay />;
    return <video src={mediaBlobUrl} controls autoPlay width={400} height={300} />;
  }

  const [getEventRecord, setGetEventRecord] = useState("");
  const handleclickRecord = (e) => {
    setMode("screen");
    setGetEventRecord(e);
  };
  const { status, error, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream } = useReactMediaRecorder({
    screen: mode === "screen",
    askPermissionOnMount: true,
    onStop: (blobUrl, blob) => console.log(blobUrl, blob),
  });

  useEffect(() => {
    if (mode === "screen") {
      startRecording();
      handleClicksStartRecord(getEventRecord);
    }
  }, [mode]);

  // TODO - logs screen recorder
  /*
  useEffect(() => {
    console.log(status, "Status");
    console.log(error, "Error");
    console.log(startRecording, "StartRecording");
    console.log(stopRecording, "StopRecording");
    console.log(mediaBlobUrl, "Media Blob Url");
    console.log(clearBlobUrl, "ClearBlobUrl");
    console.log(previewStream, "PreviewStream");
  }, [status]);
  */

  //==================================Voice Record==================================
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e) => {
      setAudioURL(URL.createObjectURL(e.data));
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecordings = () => {
    setIsRecording(true);
  };

  const stopRecordings = () => {
    setIsRecording(false);
  };

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream);
  }
  //==================================END Voice Record==============================

  //==================================Import Link===================================

  const [myValue, setValue] = useState("");
  const [isDisabledImport, setIsDisabledImport] = useState(false);

  const isValidUrl = (string) => {
    let url;

    try {
      url = new URL(string);
      return url;
    } catch (_) {
      return false;
    }
  };

  const btnImportVideo = async () => {
    if (!myValue) return false;

    const checkUrl = isValidUrl(myValue);
    if (!checkUrl) return false;

    setIsDisabledImport(true);
    const params = {
      Name: myValue,
      Url: myValue,
    };
    await scrapingoverview(params);
    setIsDisabledImport(false);
    handleClose();

    if (isReload) {
      setDataRefresher(Math.random());
    } else {
      window.location = "/#/creator/video";
    }
  };

  //==================================Popover Import==================================
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  //==================================Popover Import==================================
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  var speedialId = document.getElementById("speedial");

  const handleClicks2 = (event) => {
    setAnchorEl2(event.currentTarget);
    speedialId.style.zIndex = "1300";
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
    speedialId.style.zIndex = "1500";
  };

  const opens2 = Boolean(anchorEl2);
  const id2 = opens ? "simple-popover" : undefined;

  //==================================Popover Import Link==================================
  const [anchorElImport, setAnchorElImport] = React.useState(null);

  const handleClicksImport = (event) => {
    setAnchorElImport(event.currentTarget);
  };

  const handleCloseImport = () => {
    setAnchorElImport(null);
  };

  const opensImport = Boolean(anchorElImport);
  const idImport = opens ? "simple-popover" : undefined;

  //==================================Record Popover==================================
  const [anchorElRecord, setAnchorElRecord] = useState(null);
  const [speedialRecord, setSpeedialRecord] = useState(false);
  const [speedial, setSpeedial] = useState(true);

  const handleClicksRecord = (event) => {
    setAnchorElRecord(event.currentTarget);
    setSpeedialRecord(true);
  };

  const handleCloseRecord = () => {
    setAnchorElRecord(null);
    setSpeedialRecord(false);
    // setVisibleSpeedial(!visibleSpeedial);
  };

  const opensRecord = Boolean(anchorElRecord);
  // const idRecord = opens ? "simple-popover" : undefined;

  //==================================Camera Popover==================================

  const [anchorElCamera, setAnchorElCamera] = React.useState(null);

  const handleClicksCamera = (event) => {
    setAnchorElCamera(event.currentTarget);
  };

  const handleCloseCamera = () => {
    setAnchorElCamera(null);
  };

  const opensCamera = Boolean(anchorElCamera);
  const idCamera = opens ? "simple-popover" : undefined;

  //==================================Sound Popover==================================

  const [anchorElSound, setAnchorElSound] = React.useState(null);

  const handleClicksSound = (event) => {
    setAnchorElSound(event.currentTarget);
  };

  const handleCloseSound = () => {
    setAnchorElSound(null);
  };

  const opensSound = Boolean(anchorElSound);
  const idSound = opens ? "simple-popover" : undefined;

  //==================================Setting Popover=================================

  const [anchorElSetting, setAnchorElSetting] = React.useState(null);

  const handleClicksSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const opensSetting = Boolean(anchorElSetting);
  const idSetting = opens ? "simple-popover" : undefined;

  //==================================Voice Popover===================================

  const [anchorElVoice, setAnchorElVoice] = React.useState(null);

  const handleClicksVoice = (event) => {
    setAnchorElVoice(event.currentTarget);
  };

  const handleCloseVoice = () => {
    setAnchorElVoice(null);
  };

  const opensVoice = Boolean(anchorElVoice);
  const idVoice = opens ? "simple-popover" : undefined;

  //===================================================================================

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  //====================================================================
  const handleVisibilityRecord = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleClickRecord = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAwayRecord = () => {
    setOpen(false);
  };
  //============================Show/Hide =========================================
  const [visibles, setVisibles] = React.useState(false);
  //============================End Show/Hide =========================================
  //============================Show/Hide Speedial =========================================
  const [visibleSpeedial, setVisibleSpeedial] = React.useState(false);
  //============================End Show/Hide =========================================

  //==============================Modal Video Upload ===========================================
  const [valueUpload, setValueModalUpload] = useState(0);
  const handleChangeModalUpload = (event, newValue) => {
    setValueModalUpload(newValue);
  };

  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalUpload = () => {
    setOpenModalUpload(true);
  };
  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };
  //==============================Modal Video Upload ===========================================

  //==============================Modal Edit ===========================================
  const [valueEdit, setValueModalEdit] = useState(0);
  const handleChangeModalEdit = (event, newValue) => {
    setValueModalEdit(newValue);
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };
  //==============================End Modal Edit ===========================================

  //==============================Modal Record Screen ===========================================
  const [valueRecord, setValueModalRecord] = useState(0);
  const handleChangeModalRecord = (event, newValue) => {
    setValueModalRecord(newValue);
  };

  const [openModalRecord, setOpenModalRecord] = useState(false);
  const handleOpenModalRecord = () => {
    setOpenModalRecord(true);
  };
  const handleCloseModalRecord = () => {
    setOpenModalRecord(false);
  };
  //==============================End Modal Record Screen ===========================================

  //==============================Modal Camera ===========================================
  const [valueCamera, setValueModalCamera] = useState(0);
  const handleChangeModalCamera = (event, newValue) => {
    setValueModalCamera(newValue);
  };

  const [openModalCamera, setOpenModalCamera] = useState(false);
  const handleOpenModalCamera = () => {
    setOpenModalCamera(true);
  };
  const handleCloseModalCamera = () => {
    setOpenModalCamera(false);
  };
  //==============================End Modal  Camera ===========================================
  //==============================Modal Video Upload ===========================================

  //==============================Modal Video Upload ===========================================
  //==============================Speedial ===========================================
  const actions = [
    {
      icon: (
        <label className={classes.speeddialAction} id="speeddialActionId" onClick={handleOpenModalEdit}>
          <BiImport />
        </label>
      ),
      name: "Bulk Import",
    },
    {
      icon: (
        <label className={classes.speeddialAction} id="speeddialActionId" onClick={handleClicks}>
          <BiLinkAlt />
        </label>
      ),
      name: "Import video from YouTube or Vimeo",
    },
    {
      icon: (
        <label className={classes.speeddialAction} id="speeddialActionId" onClick={handleClicksRecord}>
          <CgRecord />
        </label>
      ),
      name: "Record your screen / camera",
    },
    {
      icon: (
        <label className={classes.speeddialAction} id="speeddialActionId" onClick={handleClicks2}>
          <HiUpload />
        </label>
      ),
      name: "Upload or drag & drop video to platform",
    },
  ];
  // const uploadSpeedial = () => {
  //   return (
  //     <label onClick={handleClicks2}>
  //       <HiUpload />
  //     </label>
  //   );
  // };
  //==============================End Speedial ===========================================

  //============================== Speedial Popups ===========================================
  // const [speedial, setSpeedial] = useState(true);
  // const [speedialRecord, setSpeedialRecord] = useState(false);
  // const [speedialStartRecord, setSpeedialStartRecord] = useState(false);

  // const speedialPopups = (popup, event) => {
  //   console.log(event);
  //   switch (
  //     popup
  //     case "showSpeedial":
  //       setSpeedial(true);
  //       setSpeedialRecord(false);
  //       setSpeedialStartRecord(false);
  //       setAnchorElRecord(null);
  //       setAnchorElStartRecord(null);
  //       break;
  //     case "showSpeedialRecord":
  //       setSpeedial(false);
  //       setSpeedialRecord(true);
  //       setAnchorElRecord(!null);
  //       setAnchorElRecord(event.currentTarget);

  //       break;
  //     case "showSpeedialRecordStart":
  //       setSpeedial(false);
  //       setSpeedialRecord(false);
  //       setSpeedialStartRecord(true);
  //       setAnchorElRecord(null);
  //       setAnchorElCamera(null);
  //       break;
  //     case "showSpeedialRecordStop":
  //       setSpeedial(false);
  //       setSpeedialRecord(false);
  //       setSpeedialStartRecord(true);
  //       setAnchorElRecord(null);
  //       setAnchorElCamera(null);
  //       break;
  //     default:
  //   ) {
  //   }
  // };

  //=========================================================================

  return (
    <div>
      {/* <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
      />
      {/*==============================Speedial ===========================================   */}
      {speedial && (
        <ThemeProvider theme={themes}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              id="speedial"
              hidden={hidden}
              icon={<SpeedDialIcon />}
              onClick={handleClick}
              open={open}
              color="secondary"
              style={{ marginLeft: 0 }}
              FabProps={{
                size: "large",
                style: { backgroundColor: "#0098D9", color: "white" },
              }}>
              {actions.map((action) => (
                // {speedialAction && (
                <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} tooltipOpen tooltipPlacement="right" className={classes.speedialContainer} />
                // )}
              ))}
            </SpeedDial>
          </ClickAwayListener>
        </ThemeProvider>
      )}
      {/*==============================End Speedial ===========================================   */}
      {/*==============================Speedial Record===========================================   */}
      {speedialRecord && (
        <ThemeProvider theme={SpeedialRecordTheme}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={hidden}
              direction="right"
              icon={<CgRadioChecked />}
              // onClick={handleClicksRecord}
              open={open}
              color="secondary"
              style={{ marginLeft: 0, zIndex: 1500 }}
              FabProps={{
                size: "large",
                style: { backgroundColor: "#0098D9", color: "white" },
              }}></SpeedDial>
          </ClickAwayListener>
        </ThemeProvider>
      )}
      {/*==============================Speedial Start Record===========================================   */}
      {speedialStartRecord && (
        <ThemeProvider theme={SpeedialRecordTheme}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={hidden}
              direction="right"
              icon={<IoStopOutline />}
              onClick={stopRecording}
              onClose={handleCloseStartRecord}
              open={open}
              color="secondary"
              style={{ marginLeft: 0, zIndex: 1500 }}
              FabProps={{
                size: "large",
                style: { backgroundColor: "red", color: "white" },
              }}></SpeedDial>
          </ClickAwayListener>
        </ThemeProvider>
      )}

      {/*==============================Popover Speedial Record===========================================*/}
      <ThemeProvider theme={speedialPopoverRecord}>
        <Popover
          open={opensRecord}
          anchorEl={anchorElRecord}
          onClose={handleCloseRecord}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}>
          <Box
            style={{
              padding: "10px 10px 10px 80px",
              display: "flex",
              justifyContent: "center",
              background: "#141C22",
            }}>
            <IconButton onClick={handleClicksCamera}>
              <AiFillCamera />
            </IconButton>
            <IconButton onClick={handleClicksSound}>
              <BsMusicNoteBeamed />
            </IconButton>
            <IconButton onClick={handleClicksSetting}>
              <BsGearFill />
            </IconButton>

            <IconButton onClick={handleCloseRecord}>
              <TiDelete style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Popover>
      </ThemeProvider>
      {/*========================================================================End Speedial Record=======================================================================================*/}
      {/*============================== Import Link Popover===========================================*/}
      <ThemeProvider theme={speedialPopoverImport}>
        <Popover
          id={id}
          open={opens}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          style={{ marginTop: 50, marginLeft: 30 }}>
          <Card className={classes.bulkImport}>
            {/* <TextField label="Outlined" /> */}
            <input type="textfield" placeholder="Enter video link" disabled={isDisabledImport} className={classes.importTextfield} onChange={(e) => setValue(e.target.value)} />
            <Button variant="contained" size="small" disabled={isDisabledImport} style={{ marginLeft: 10, background: "#0098D9", color: "white" }} onClick={btnImportVideo}>
              Import
            </Button>
            <IconButton style={{ color: "red" }} onClick={handleClose}>
              <TiDelete />
            </IconButton>
          </Card>
        </Popover>
      </ThemeProvider>
      {/*==============================Upload Popover==========================================*/}
      <ThemeProvider theme={speedialPopoverUpload}>
        <Popover
          id={id2}
          open={opens2}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          style={{ marginTop: 50, marginLeft: 30 }}>
          <Box
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              background: "#141C22",
              border: "3px dashed #0098D9",
              width: 400,
              height: 530,
              zIndex: 1500,
            }}>
            {/* <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            accept="video/*"
          /> */}

            {
              // DRILL SOME PROPS LATER
            }
            {/* <UploadDropZone {...props} /> */}
            <VideoUpload {...props} />
          </Box>
        </Popover>
      </ThemeProvider>
      {/*==============================Import Popover===========================================*/}
      <Popover
        id={idImport}
        open={opensImport}
        anchorEl={anchorElImport}
        onClose={handleCloseImport}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}>
        <Card style={{ padding: 10, display: "flex", justifyContent: "center" }}>
          <TextField label="Enter Video Link" variant="outlined" value={myValue} onChange={(e) => setValue(e.target.value)} />
          <Button variant="contained" size="small" placeholder="Enter Video Link" style={{ marginLeft: 10, background: "#0098D9", color: "white" }} onClick={btnImportVideo}>
            Import
          </Button>
          <IconButton style={{ color: "red" }} onClick={handleClose}>
            <TiDelete />
          </IconButton>
        </Card>
      </Popover>
      {/*==============================Camera Popover===========================================*/}
      <Popover
        id={idCamera}
        open={opensCamera}
        anchorEl={anchorElCamera}
        onChange={handleCloseCamera}
        onClose={handleCloseCamera}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Grid
          container
          style={{
            background: "#141C22",
            width: 280,
          }}>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleclickRecord}>
              Screen
            </MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleOpenModalCamera}>
              Screen and Camera
            </MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }}>FaceTime HD Camera (Display) (05ac:1112)</MenuItem>
          </Grid>
        </Grid>
      </Popover>
      {/*==============================Sounds Popover===========================================*/}
      <Popover
        id={idSound}
        open={opensSound}
        anchorEl={anchorElSound}
        onClose={handleCloseSound}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Grid
          container
          style={{
            background: "#141C22",
            width: 280,
          }}>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }}>Default - MacBook Pro Microphone (Built-in)</MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleClicksVoice}>
              Display Audio (05ac:1107)
            </MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }}>MacBook Pro Microphone (Build-in)</MenuItem>
          </Grid>
        </Grid>
      </Popover>
      {/*==============================Setting Popover===========================================*/}
      <Popover
        id={idSetting}
        open={opensSetting}
        anchorEl={anchorElSetting}
        onClose={handleCloseSetting}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Grid
          container
          style={{
            background: "#141C22",
            width: 320,
            padding: 10,
          }}>
          <Grid xs={12}>
            <Grid
              container
              style={{
                background: "#141D22",
                padding: 10,
                borderRadius: 16,
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}>
              <Grid xs={8} style={{ paddingLeft: 5, paddingRight: 5 }}>
                <FormControl>
                  <TextField placeholder="Max video bitrate (Kbps)" variant="outlined" size="small" />
                </FormControl>
              </Grid>
              <Grid xs={4} style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Button variant="contained" className={classes.ButtonContained}>
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "0.8rem", paddingLeft: 5, paddingRight: 5 }}>
              Average Bitrate(Kbps): 76 Latest Bitrate(Kbps): 108 Packets Lost: 3 Jitter (Secs): 0.00200
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "0.8rem", paddingLeft: 5, paddingRight: 5 }}>
              Round Trip Time (Secs): 0.0310 Source Width x Height: 2560 x 1440 On-going Width x Height: 2560 x 1440 On-going FPS: 17.2
            </Typography>
          </Grid>
        </Grid>
      </Popover>
      {/*==============================Voice Popover===========================================*/}
      <Popover
        id={idVoice}
        open={opensVoice}
        anchorEl={anchorElVoice}
        onClose={handleCloseVoice}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}>
        <Card style={{ padding: 10, width: 320 }}>
          <Grid container>
            <Grid xs={12}>
              <audio src={audioURL} controls />
            </Grid>
            <Grid xs={6} style={{ display: "flex", justifyContent: "center" }}>
              <Button size="small" style={{ background: "#0098D9" }} onClick={startRecordings} disabled={isRecording}>
                start recording
              </Button>
            </Grid>
            <Grid xs={6} style={{ display: "flex", justifyContent: "center" }}>
              <Button size="small" style={{ background: "#0098D9" }} onClick={stopRecordings} disabled={!isRecording}>
                {" "}
                stop recording
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Popover>
      {/*============================== Start Record Popover ===========================================*/}
      {/* <ThemeProvider theme={speedialPopoverStartRecord}>
        <Popover
          open={opensStartRecord}
          anchorEl={anchorElStartRecord}
          onClose={handleCloseStartRecord}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}>
          <Box
            style={{
              padding: "10px 10px 10px 80px",
              display: "flex",
              justifyContent: "center",
              background: "#141C22",
            }}>
            <IconButton onClick={handleCloseStartRecord}>
              <TiDelete style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Popover>
      </ThemeProvider> */}
      {/*===========================================================================================MODAL==================================================================================*/}
      {/*==============================Modal Edit===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalEdit}
        onClose={handleCloseModalEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalEdit}>
          <div className={classes.papersBulkImport}>
            <Grid className={classes.BulkImportModalCloseButtonDiv}>
              <Grid className={classes.BulkImportModalCloseButton}>
                <CgClose className={classes.BulkImportModalCloseButtonIcon} onClick={handleCloseModalEdit} />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ color: "white", marginBottom: 30 }}>
              <h5> Import Assistant</h5>

              <Typography variant="subtitle1" style={{ color: "white", marginBottom: 30 }}>
                By using this tool, you can directly browse through the websites
                <br /> and import videos from there
              </Typography>
            </Typography>
            <Grid
              container
              align="center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 50,
              }}>
              <Grid xs={4} style={{ padding: 10 }}>
                <Card className={classes.card}>
                  <Grid container style={{ padding: 40 }}>
                    <Grid xs={12}>
                      <HiOutlineDocumentText className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12}>
                      <Typography>We DO NOT collect browser history</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid xs={4} style={{ padding: 10 }}>
                <Card className={classes.card}>
                  <Grid container style={{ padding: 40 }}>
                    <Grid xs={12}>
                      <GiPadlock className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12}>
                      <Typography>We DO NOT save your passwords</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>

            <Divider />
            <Grid container>
              <Grid xs={4}>
                <FormControlLabel style={{ color: "white" }} control={<Checkbox />} label="Don’t show this again" />
              </Grid>

              <Grid
                xs={8}
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Box mr={2}>
                  <Button variant="outlined">Cancel</Button>
                </Box>
                <Box>
                  <Button variant="contained" className={classes.ButtonContained} target="_blank" component="a" href="https://rdi.xaasfarm.com/RDWeb/webclient/index.html?UserName=null&Pw=null">
                    Get started
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Edit===========================================*/}
      {/*==============================Modal Screen Record===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalRecord}
        onClose={handleCloseModalRecord}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalRecord}>
          <div className={classes.papers}>
            <Typography variant="h5" style={{ color: "white", marginBottom: 30 }}>
              <Grid container style={{ display: "flex", justifyContent: "center" }}>
                <Grid>
                  <Button
                    variant="outlined"
                    style={{ background: "#0098D9" }}
                    onClick={() => {
                      handleclickRecord();
                      startRecording();
                    }}>
                    Start
                  </Button>
                  <Button variant="outlined" style={{ background: "#0098D9" }} onClick={stopRecording}>
                    Stop
                  </Button>
                  <Button variant="outlined" style={{ background: "#0098D9" }} onClick={clearBlobUrl}>
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Typography>
            <Grid container>
              <Grid xs={6}>{status === "stopped" && <Output screen={mode === "screen"} mediaBlobUrl={mediaBlobUrl} />}</Grid>
            </Grid>

            <Divider />
            <Grid container>
              <Grid
                xs={12}
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Box mr={2}>
                  <Button variant="outlined">Cancel</Button>
                </Box>
                <Box>
                  <Button variant="contained" className={classes.ButtonContained}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================MOdal Screen Record===========================================*/}
      {/*==============================Modal Video Upload===========================================*/}
      {/* <Modal
        className={classes.modal}
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalUpload}>
          <div className={classes.papers} style={{ minWidth: 500 }}>
            <Grid container>
              <Grid xs={6}>
                <Typography variant="h5" style={{ marginBottom: 10 }}>
                  <h4>Upload Videos</h4>
                </Typography>
              </Grid>
              <Grid xs={6} style={{ display: "flex", justifyContent: "right" }}>
                <TiDeleteOutline
                  fontSize="large"
                  onClick={handleCloseModalUpload}
                />
              </Grid>
            </Grid>

            <Divider />
            {!visibles && (
              <div {...getRootProps()}>
                <Grid container>
                  <Grid
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card
                      style={{
                        width: 300,
                        height: 250,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <input {...getInputProps()} />
                      {videos}
                    </Card>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    xs="12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                
                  </Grid>

                  <Grid
                    xs="12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>Drag and drop video files to upload</Typography>
                  </Grid>
                  <Grid
                    xs="12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{ background: "#0098D9", color: "white" }}
                    >
                      Select Files
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
            {visibles && (
              <LinearProgress variant="determinate" value={progress} />
            )}
            <Divider style={{ marginTop: 20 }} />
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: 20,
              }}
            >
              <Button
                variant="outlined"
                style={{ lor: "white", margin: 10 }}
                onClick={() => {
                  setVisibles(!visibles);
                  sendUpload();
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                style={{ color: "white", margin: 10 }}
                onClick={removeAll}
              >
                Clear
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal> */}
      {/*==============================End Modal Video Upload===========================================*/}
      {/*==============================Modal Camera===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalCamera}
        onClose={handleCloseModalCamera}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalCamera}>
          <div className={classes.papers} style={{ minWidth: 500 }}>
            <Grid container>
              <Grid xs={6}>
                <Typography variant="h5" style={{ marginBottom: 10 }}>
                  <h4>Record</h4>
                </Typography>
              </Grid>
              <Grid xs={6} style={{ display: "flex", justifyContent: "right" }}>
                <TiDeleteOutline fontSize="large" onClick={handleCloseModalUpload} />
              </Grid>
            </Grid>

            <Divider />

            <Grid container>
              <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  style={{
                    width: 500,
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}></Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                xs="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Typography>Record Your Camera</Typography>
              </Grid>

              <Grid
                xs="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}>
                <Fab color="secondary" style={{ background: "#0098D9" }}>
                  <VscDebugStart />
                </Fab>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: 20 }} />
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: 20,
              }}>
              <Button variant="outlined" style={{ lor: "white", margin: 10 }}>
                Save
              </Button>
              <Button variant="outlined" style={{ color: "white", margin: 10 }}>
                Clear
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Camera===========================================*/}
    </div>
  );
}
