import { makeStyles } from "@material-ui/core/styles";
const theme = makeStyles({
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1500,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    background: "#141D22",
  },
  FontWhite: {
    color: "white",
  },

  TextfieldSign: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  multilineColor: {
    color: "#fff",
    border: "1px solid #B3B6B8",
  },
  Ellipse1Div: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
    width: "50%",
  },
  Ellipse1: {
    width: "100%",
  },
  Ellipse2Div: {
    position: "absolute",
    top: theme.spacing(0),
    left: theme.spacing(0),
    zIndex: "0",
    width: "40%",
    [theme.breakpoints.up("xl")]: {
      top: theme.spacing(-15),
      left: theme.spacing(0),
      zIndex: "0",
      width: "40%",
    },
  },
  Ellipse2: {
    width: "100%",
  },

  //SignInBusinessCode
  TextFieldCode: {
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 3px",
    },
  },
  //SignuBusiness

  signUpButton: {
    marginTop: 30,
    minHeight: 50,
    color: "white",
    background: "#7FC94F",
  },

  TextTwo: {
    marginTop: 10,
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    color: "#999B9D",
    cursor: "pointer",
  },
  TextOne: {
    display: "flex",
    color: "white",
    margin: "auto",
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center",
  },
  TextTwoFont: {
    display: "flex",
    justifyContent: "center",
    color: "#999B9D",
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
    },
  },
  ButtonSignin: {
    background: "#7FC94F",
    marginTop: 30,
    minHeight: 50,
    color: "white",
    width: "100%",
    [theme.breakpoints.down(400)]: {
      width: "100%",
    },
  },
  TextTwoTitle: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
}));
export { useStyles };
