import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "../Business/BusinessStyle/BusinessSettingsStyle";
import Drawer from "../Business/BusinessDrawer";
import Form from "../../AppComponents/Settings/Settings";
import BusinessFooterNavbar from "../BusinessFooterNavbar";
import SpeedialBusiness from "../Business/SpeedialBusiness";
import MobileSpeedialBusiness from "../Business/MobileSpeedialBusiness";
import CurveDesign from "../../AppComponents/SideBar/CurveDesign";

export default function BusinessSettings() {
  const userType = "business";
  const [isReload, setIsReload] = useState(false);
  const [dataRefresher, setDataRefresher] = useState("");

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid className={classes.Speedial}>
        <SpeedialBusiness setDataRefresher={setDataRefresher} isReload={isReload} setIsReload={setIsReload} userType={userType} />
        <Grid className={classes.speedialBackshadowDiv}>
          <Grid className={classes.speedialBackshadowCurved1}></Grid>
          <Grid className={classes.speedialBackshadowCurved2}></Grid>
          <Grid className={classes.speedialBackshadow}></Grid>
          <Grid className={classes.speedialBackshadowCurved3}></Grid>
          <Grid className={classes.speedialBackshadowCurved4}></Grid>
        </Grid>
      </Grid>
      <Grid className={classes.SpeedialMobile}>
        <MobileSpeedialBusiness setDataRefresher={setDataRefresher} isReload={isReload} setIsReload={setIsReload} userType={userType} />
      </Grid>
      <Grid container className={classes.dashboardDiv}>
        <Grid xs={0} sm={2} md={3} lg={2} style={{ zIndex: 500 }} className={classes.drawer}>
          <Drawer />
        </Grid>
        <Grid xs={12} sm={9} md={9} lg={9} className={classes.contentDiv}>
          <h1 className={classes.Dashboard} style={{ marginTop: 10 }}>
            Settings
          </h1>
          <Grid container spacing={2}>
            <Form classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      <Grid id="VideoFooterNavBar">
        <BusinessFooterNavbar />
      </Grid>
    </div>
  );
}
