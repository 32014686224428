import axios from "axios";

// =======================================Set App Configs========================================
export const token = localStorage.getItem("token");
export const Auth = localStorage.getItem("Auth");
export const Email = localStorage.getItem("Email");
export const client = axios.create({
  headers: {
    "Content-type": "application/json",
    Authorization: `JWT ${Auth}`,
    "Access-Control-Allow-Origin": "*",
    token: `JWT ${token}`,
  },
});
// REMOVE ALLOW ORIGIN FOR THIS ONE
export const clientMinio = axios.create({
  headers: {
    "Content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `JWT ${Auth}`,
    token: `JWT ${token}`,
  },
});

const baseURL = "https://backend-dev.vaip.ai/odata/";
const baseURLFile = "https://ostoragedev1.xaasfarm.com:9000/";
export const routesAPI = {
  vimeovideos: `${baseURL}vimeovideos`, //Get all videos
  searchhistory: `${baseURL}searchhistory`, //Searh history for videos
  userInfo: `${baseURL}UserInfo`, //Method GET :get user info ex (Email, Language, etc...)
  sendEmail: `${baseURL}UserInfo`, //Sends OTP to user
  loginUser: `${baseURL}login`, //Log the user in
  logoutUser: `${baseURL}logout`, //Logout the user in
  publicVideo: `${baseURL}publicVideo`,
  baseURLFile: `${baseURLFile}`,
  VideoRecord: `${baseURL}VideoRecord`,
  rangeStatic: `${baseURL}RangeStatics`,
  static: `${baseURL}Statics`,
  rangeStaticGraph: `${baseURL}RangeStatics('')`,
  deleteVideo: `${baseURL}centralTable`,
  VimeoTags: `${baseURL}VimeoTags`,
  AddVideoTranscription: `${baseURL}AddVideoTranscription`,
  aivindexerLabels: `${baseURL}aivindexerLabels`, //Edit video title
};

// =========== send Email Credentials ==============================
export const getRegisteredWithEmail = async (payload) => {
  let { data } = await axios.post("https://backend-dev.vaip.ai/odata/UserInfo", payload);
  return data;
};

//  ========= Login Api Send Client Information and get JWT Token and Authorization (not in use)=============
export const loginAPi = async (payload) => {
  let res = await axios.post("https://backend-dev.vaip.ai/odata/login", payload);
  let value = res.data.value.split("#");
  let token = value[1];
  let Auth = value[2];

  console.log(token);
  console.log(Auth);
  if (res.status === 201) {
    // -------Redirect to ClientId Dashboard----
  } else {
    // ---------------Error Modal ------------
    alert("Error: invalid email or hash code.");
  }
};

// =========== Search Video==============================
export const getSearchHistory = async (payload) => {
  let { data } = await axios.post("https://backend-dev.vaip.ai/odata/SearchHistory", payload);
  return data;
};

// ===========Post Search Video==============================
export const postSearchHistory = async (payload) => {
  let res = await axios.post("https://backend-dev.vaip.ai/odata/SearchHistory", payload);

  let value = res.data.value.split("#");
  let token = value[1];
  let Auth = value[2];
  console.log(token);
  console.log(Auth);
};

// =============== get EmailCredentials ======================
export const getClient = async (Auth) => {
  let { data } = await client.get(`https://backend-dev.vaip.ai/odata/UserInfo('${Auth}')`);
  console.log(data);
  return data;
};

// // need Payload = { }
export const UploadVideotoMimlo = async (params) => {
  try {
    return await clientMinio.post("https://backend-dev.vaip.ai/odata/UploadVideoToMimIo", params);
  } catch (e) {
    return e;
  }
};

export const AfterMimloRequest = async (params) => {
  try {
    return await client.put(params);
  } catch (e) {
    return e;
  }
};
// ========================= Upload Video =================================

// need Payload = {"FileName": "ECX_Digital Messaging.mp4","FileSize": "2182017","FileType": "/vaipinput/616be4efee2bfd3496000001.mp4","StatusUpload": "uploaded","UploadType": "manual","videoDuration": "00:00:17" }
export const ImportVideo = async (params) => {
  let { data } = await client.post("https://backend-dev.vaip.ai/odata/scrapingoverview", params);
  return data;
};

// ========= get response of return object using Callback and dot.notation ============
export const searchHistory = async () => {
  let { data } = await client.post("https://backend-dev.vaip.ai/odata/searchhistory");
  return data;
};

export const getSearchHistoryDetails = async (params) => {
  let { data } = await client.post("https://backend-dev.vaip.ai/odata/vimeovideos", params);
  return data;
};

// ============================= get Authe('Id') =================================

export const getClientId = async (params) => {
  let { data } = await client.get(`https://backend-dev.vaip.ai/odata/Authe`);
  const user = data.value.filter((e) => e.Email.toLowerCase() === params);

  const clientId = user[0].ClientId;
  return clientId;
};
// ========================== need Payload =========================
// {"Id": "616be50f98107622d0be4465","Thumbnail": "thumb_616be4efee2bfd3496000001mp4-Scene-001-01.png"}

export const videoAssignThumnail = async () => {
  let { data } = await client.post("https://backend-dev.vaip.ai/odata/VideoImageAssign");
  return data;
};

// after login
// menu
export const getMenu = async (Auth, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/Menu('${Auth}')`);
  return data;
};
// client
export const getClientPositive = async (Auth, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/Client('${Auth}')`);
  return data;
};

// client-
export const getClientNegative = async (Auth, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/Client('-${Auth}')`);
  return data;
};

// register
export const getRegister = async (token, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/register('${token}')`);
  return data;
};

// element menu
export const getElementMenu = async (Auth, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/ElementMenu('${Auth}')`);
  return data;
};

// vimeovideos
export const getVimeoVideos = async (header, payload) => {
  let { data } = await header.post("https://backend-dev.vaip.ai/odata/vimeovideos", payload);
  return data;
};
// SocialMediaEntriesTiktok
export const getSocialMediaEntriesTiktok = async (Auth, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/SocialMediaEntries('tiktok-616544f2ef481526a4903e7a-0-0-Query-Null-Null-Null'('${Auth}')`);
  return data;
};
// SocialMediaEntriesYoutubes
export const getSocialMediaEntriesYoutube = async (Auth, header) => {
  let { data } = await header.get(`https://backend-dev.vaip.ai/odata/SocialMediaEntries('youtube-616544f2ef481526a4903e7a-0-0-Query-Null-Null-Null'('${Auth}')`);
  return data;
};
export const getThumbnail = async (header, payload) => {
  let { data } = await header.get(`${payload}`);
  return data;
};

// TODO - New way of Accessing API's
const AxiosNoHeader = axios;
const AxiosNoHeaderInstance = () => {
  return AxiosNoHeader.create({
    baseURL: baseURL,
  });
};
export const AxiosInstance = () => {
  const headerData = {
    auth: localStorage.getItem("Auth") ?? "",
    token: localStorage.getItem("token") ?? "",
    email: localStorage.getItem("Email") ?? "",
  };

  return axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `JWT ${headerData.auth}`,
      "Access-Control-Allow-Origin": "*",
      token: `JWT ${headerData.token}`,
    },
    baseURL: baseURL,
  });
};

export const AxiosLogoutInstance = () => {
  const headerData = {
    token: localStorage.getItem("token") ?? "",
  };

  return axios.create({
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${headerData.token}`,
    },
    baseURL: baseURL,
  });
};

/* get user details base on email */
export const userInfo = async (email) => {
  try {
    return await AxiosNoHeaderInstance().get(`UserInfo('${email}')`);
  } catch (e) {
    return false;
  }
};

/* send otp to user's email */
export const sendOTP = async (params) => {
  try {
    return await AxiosNoHeaderInstance().post("UserInfo", params);
  } catch (e) {
    return false;
  }
};

export const login = async (params) => {
  try {
    return await AxiosNoHeaderInstance().post("login", params);
  } catch (e) {
    return false;
  }
};

export const logout = async (token) => {
  try {
    return await AxiosLogoutInstance().delete(`login/${token}`);
  } catch (e) {
    return false;
  }
};

export const isClientLoginIn = async (auth) => {
  try {
    return await AxiosInstance().get(`Client('${auth}')`);
  } catch (e) {
    return false;
  }
};

export const vimeovideos = async (params) => {
  try {
    return await AxiosInstance().post("vimeovideos", params);
  } catch (e) {
    return false;
  }
};

export const searchhistory = async (params) => {
  try {
    return await AxiosInstance().post("searchhistory", params);
  } catch (e) {
    return false;
  }
};

export const publicVideo = async (vidId) => {
  try {
    return await AxiosInstance().get(`publicVideo('${vidId}')`);
  } catch (e) {
    return false;
  }
};

export const VideoRecord = async (vidId) => {
  try {
    return await AxiosInstance().get(`VideoRecord('${vidId}')`);
  } catch (e) {
    return false;
  }
};

export const UploadVideoToMimIo = async (params) => {
  try {
    return await AxiosInstance().post("UploadVideoToMimIo", params);
  } catch (e) {
    return false;
  }
};

export const UploadVideo = async (params) => {
  try {
    return await AxiosInstance().post("UploadVideo", params);
  } catch (e) {
    return false;
  }
};

export const editVideoTitle = async (params) => {
  try {
    return await AxiosInstance().put(`aivindexerLabels/${params?.Id}`, params);
  } catch (e) {
    return false;
  }
};

export const editVideoDescription = async (params) => {
  try {
    return await AxiosInstance().put(`aivindexerLabels/${params?.Id}`, params);
  } catch (e) {
    return false;
  }
};

/* reprocess video */
export const reprocessVideo = async (vidId) => {
  try {
    return await AxiosInstance().put(`centralTable/${vidId}`, { Id: vidId });
  } catch (e) {
    return false;
  }
};

export const deleteVideo = async (vidId) => {
  try {
    return await AxiosInstance().delete(`centralTable('${vidId}')`);
  } catch (e) {
    return false;
  }
};

export const changeThumbnail = async (params) => {
  try {
    return await AxiosInstance().post("VideoImageAssign", params);
  } catch (e) {
    return false;
  }
};

/* Get the position of tag name in the video */
export const AiLabelsHandler = async (params) => {
  try {
    const { vidId, tagName } = params;
    return await AxiosInstance().get(`AiLabelsHandler('${vidId}-${tagName}')`);
  } catch (e) {
    return false;
  }
};

/* Delete video tag */
export const aivindexerLabels = async (params) => {
  try {
    const { vidId, tagName } = params;
    return await AxiosInstance().delete(`aivindexerLabels('${tagName}-${vidId}')`);
  } catch (e) {
    return false;
  }
};

/* Analyze video */
export const AddVideoTranscription = async (params) => {
  try {
    const { vidId, selectedLanguage } = params;
    return await AxiosInstance().put(`AddVideoTranscription/${vidId}@${selectedLanguage}`);
  } catch (e) {
    return false;
  }
};

/* Add video tags */
export const VimeoTags = async (params) => {
  try {
    return await AxiosInstance().post("VimeoTags", params);
  } catch (e) {
    return false;
  }
};

/* Import video from YouTube or Vimeo */
export const scrapingoverview = async (params) => {
  try {
    return await AxiosInstance().post("scrapingoverview", params);
  } catch (e) {
    return false;
  }
};

export const Statics = async () => {
  try {
    return await AxiosInstance().get("Statics");
  } catch (e) {
    return false;
  }
};

export const RangeStatics = async () => {
  try {
    return await AxiosInstance().get("RangeStatics");
  } catch (e) {
    return false;
  }
};

export const topVideosGrp = async () => {
  try {
    return await AxiosInstance().get("TargetGrpStatics");
  } catch (e) {
    return false;
  }
};

export const paymentGraphBar = async () => {
  try {
    return await AxiosInstance().get("PlayTimeStatics('')");
  } catch (e) {
    return false;
  }
};

export const iViewersGraphBar = async () => {
  try {
    return await AxiosInstance().get("RangeStatics('')");
  } catch (e) {
    return false;
  }
};

export const savePlaylist = async (params) => {
  try {
    return await AxiosInstance().post("wishlist", params);
  } catch (e) {
    return false;
  }
};

export const userPlayList = async (email) => {
  try {
    return await AxiosInstance().get(`wishlistentry('${email}')`);
  } catch (e) {
    return false;
  }
};

export const ScreenRecordingS3 = async (params) => {
  try {
    return await AxiosInstance().put(`${params}`);
  } catch (e) {
    return false;
  }
};

export const removePlaylist = async (playListId) => {
  try {
    return await AxiosInstance().delete(`wishlist('${playListId}')`);
  } catch (e) {
    return false;
  }
};

export const insertVideoInPlaylist = async (params) => {
  try {
    return await AxiosInstance().post("wishlistentry", params);
  } catch (e) {
    return false;
  }
};

export const removeVideoInPlaylist = async (videoId) => {
  try {
    return await AxiosInstance().delete(`wishlistsingleentry('${videoId}')`);
  } catch (e) {
    return false;
  }
};

export const getClientCredentials = async () => {
  try {
    return await AxiosInstance().get("PublicClient");
  } catch (e) {
    return false;
  }
};

export const getClientUpdateCredentials = async (params) => {
  try {
    return await AxiosInstance().get(`Client('${params}')`);
  } catch (e) {
    return false;
  }
};

export const updateCredentials = async (Auth, params) => {
  try {
    return await AxiosInstance().put(`PublicClient('${Auth}')`, params);
  } catch (e) {
    return false;
  }
};

export const sendConfirmationCodeForDelete = async (Auth) => {
  try {
    return await AxiosInstance().delete(`Client('${Auth}+true')`);
  } catch (e) {
    return false;
  }
};

export const deleteUserCredentials = async (confirmationCode, Auth) => {
  try {
    return await AxiosInstance().delete(`ClientDeleteConfirm('${confirmationCode},${Auth},true')`);
  } catch (e) {
    return false;
  }
};

export const transferAccount = async (params) => {
  try {
    return await AxiosInstance().post("TransferClient", params);
  } catch (e) {
    return false;
  }
};

export const filterVideoViaSocMed = async (socMedType, auth) => {
  try {
    return await AxiosInstance().get(`SocialMediaEntries('${socMedType}-${auth}-0-0-Query-Null-Null-Null')`);
  } catch (e) {
    return false;
  }
};
