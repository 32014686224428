import { useStyles as businessStyles } from "../../Dashboarad/Business/BusinessStyle/BusinessVideoStyle";
import { useStyles as creatorStyles } from "../../Dashboarad/Creator/CreatorStyle/CreatorVideoStyle";
import { useStyles as studentStyles } from "../../Dashboarad/Student/StudentStyle/StudentVideoStyle";

export default function VideoStyle(userType = "") {
  let classes;
  switch (userType) {
    case "business":
      classes = businessStyles();
      break;
    case "creator":
      classes = creatorStyles();
      break;
    case "student":
      classes = studentStyles();
      break;
    default:
      classes = {};
  }
  return classes;
}
