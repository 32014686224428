import { makeStyles } from "@material-ui/core/styles";
const theme = makeStyles({
  breakpoints: {
    values: {
      mobile: 375,
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1500,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    background: "#0a0e11",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: 50,
    paddingTop: 50,
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 100,
      paddingTop: 100,
    },
  },
  dashboardDiv: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  Dashboard: {
    color: "white",
    margin: "20px 0",
  },
  DashboardText1: {
    display: "flex",
    justifyContent: "right",
  },
  CardRow1: {
    backgroundColor: "#141C22",
    color: "white",
  },
  CardRow2: {
    minHeight: 300,
    backgroundColor: "#141C22",
    color: "white",
  },
  CardRow3: {
    backgroundColor: "#141C22",
    color: "white",
  },
  title: {
    fontSize: 15,
    color: "#898B8D",
    [theme.breakpoints.only("sm")]: {
      fontSize: 10,
    },
  },
  pos: {
    fontSize: 30,
    color: "white",
    [theme.breakpoints.only("sm")]: {
      fontSize: 18,
    },
  },
  CardRow1Content: {
    [theme.breakpoints.only("sm")]: {
      padding: "15px 10px !important",
    },
  },
  CardRow1Content2: {
    [theme.breakpoints.only("sm")]: {
      padding: "15px 10px 0 10px !important",
    },
  },
  CardIcon: {
    display: "flex",
    alignItems: "center",
    paddingRight: 20,
    color: "#7fc94f",
    [theme.breakpoints.only("sm")]: {
      padding: "0",
    },
  },
  CardIconSvg: {
    [theme.breakpoints.only("sm")]: {
      width: "15px",
    },
  },
  CardIconEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#898B8D",
    [theme.breakpoints.only("sm")]: {
      marginLeft: "8px",
    },
  },
  dashboardArrowIcon: {
    [theme.breakpoints.only("sm")]: {
      width: "18px",
    },
  },
  CardIcon1: {
    display: "flex",
    alignItems: "center",
    color: "#7fc94f",
  },
  CardIconEnd1: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#898B8D",
  },
  //Card with Images
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  textFloat: {
    float: "left",
    fontSize: 10,
    backgroundColor: "#99b9d",
    marginTop: 4,
  },
  textFloat1: {
    float: "left",
    fontSize: 10,
    color: "white",
    backgroundColor: "green",
    marginTop: 4,
  },
  textFloatBelow: {
    float: "right",
    fontSize: 10,
    backgroundColor: "#99b9d",
  },

  //Speedial

  list: {
    color: "white",
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: "1500",
    border: "8px solid black",
    fab: {
      position: "sticky",
      border: "8px solid #0a0e11",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  Speedial: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  SpeedialMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  speedialBackshadowDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  speedialBackshadowCurved1: {
    position: "fixed",
    bottom: theme.spacing(17.5),
    left: theme.spacing(26.3),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderBottomRightRadius: "30px",
  },
  speedialBackshadowCurved2: {
    position: "fixed",
    bottom: theme.spacing(18),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadowCurved3: {
    position: "fixed",
    bottom: theme.spacing(5.7),
    left: theme.spacing(26.4),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderTopRightRadius: "30px",
  },
  speedialBackshadowCurved4: {
    position: "fixed",
    bottom: theme.spacing(7),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadow: {
    position: "fixed",
    bottom: theme.spacing(8.5),
    left: theme.spacing(25.1),
    zIndex: "1000",
    background: "#0a0e11",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },

  //Chart Size
  chart: {
    width: "100%",
    height: "300px !important",
    [theme.breakpoints.only("sm")]: {
      height: "250px !important",
    },
    [theme.breakpoints.down("xs")]: {
      height: "210px !important",
    },
  },
}));
export { useStyles };
