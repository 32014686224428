import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import history from "../Landingpage/history";
import { FaPhotoVideo } from "react-icons/fa";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import SettingsIcon from "@material-ui/icons/Settings";
import Avatar from "@material-ui/core/Avatar";
import { NavLink } from "react-router-dom";
import "../Stylesheet/Student/StudentDrawer.scss"

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: "auto",
      bottom: 0,
      height: "70px",
      zIndex: "1",
      background: "#141D22",
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    grow: {
      flexGrow: 1,
    },
    fabButton: {
      position: "absolute",
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: "0 auto",
    },
    footerNavList: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 !important",
      height: "100%",
    },
    footerNavListItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100%",
      padding: "0",
      color: "white",
      textDecoration: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        width: "80px",
      },
      [theme.breakpoints.down("281")]: {
        width: "70px",
      },
    },
    footerNavAvatar: {
      width: "28px",
      height: "28px",
      [theme.breakpoints.down("321")]: {
        width: "25px",
        height: "25px",
      },
    },
    footerNavFont: {
      color: "white",
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    footerNavToolbar: {
      height: "100%",
      padding: "0 !important",
      [theme.breakpoints.down("321")]: {
        paddingTop: "10px",
      },
      [theme.breakpoints.down("281")]: {
        paddingTop: "8px",
      },
    },
    footerNavIcons: {
      fontSize: "25px",
    },
    footerNavListActive: {
      color: "#F29600",
      borderTop: "3px solid #F29600",
    },
  })
);

export default function BottomAppBar() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.footerNavToolbar}>
          <List className={classes.footerNavList}>
            <NavLink exact to="/student/video" className={classes.footerNavListItem} activeClassName="footerMenuActive">
              {/* <FaPhotoVideo className={classes.footerNavIcons} /> */}
              <div className="videoLogo">
                <svg className="footerVideoLogoIcon" width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 1V4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5C12.7348 5 12.4804 4.89464 12.2929 4.70711C12.1054 4.51957 12 4.26522 12 4V2H2V12H4C4.26522 12 4.51957 12.1054 4.70711 12.2929C4.89464 12.4804 5 12.7348 5 13C5 13.2652 4.89464 13.5196 4.70711 13.7071C4.51957 13.8946 4.26522 14 4 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1ZM8 18V16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16V19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H16C15.7348 6 15.4804 6.10536 15.2929 6.29289C15.1054 6.48043 15 6.73478 15 7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8H18V18H8ZM6 13V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13ZM8 12H12V8H8V12Z" fill="white"/>
                </svg>
              </div>
              <div className="videoLogoActive">
                <svg className="footerVideoLogoActiveIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 1V4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5C12.7348 5 12.4804 4.89464 12.2929 4.70711C12.1054 4.51957 12 4.26522 12 4V2H2V12H4C4.26522 12 4.51957 12.1054 4.70711 12.2929C4.89464 12.4804 5 12.7348 5 13C5 13.2652 4.89464 13.5196 4.70711 13.7071C4.51957 13.8946 4.26522 14 4 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1ZM8 18V16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16V19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H16C15.7348 6 15.4804 6.10536 15.2929 6.29289C15.1054 6.48043 15 6.73478 15 7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8H18V18H8ZM6 13V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13ZM8 12H12V8H8V12Z" fill="#F29600"/>
                </svg>
              </div>
              <Typography className={classes.footerNavFont}>My Videos</Typography>
            </NavLink>
            <NavLink exact to="/student/settings" className={classes.footerNavListItem} activeClassName={classes.footerNavListActive}>
              <SettingsIcon className={classes.footerNavIcons} onClick={() => history.push("/")} />
              <Typography className={classes.footerNavFont}>Settings</Typography>
            </NavLink>
            <NavLink exact to="" className={classes.footerNavListItem} activeClassName={classes.footerNavListActive}>
              <Avatar className={classes.footerNavAvatar} />
              <Typography className={classes.footerNavFont}>Profile</Typography>
            </NavLink>
          </List>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
