import { useStyles as businessStyles } from "../../Dashboarad/Business/BusinessStyle/BusinessVideoDetailStyle";
import { useStyles as creatorStyles } from "../../Dashboarad/Creator/CreatorStyle/CreatorVideoDetailStyle";
import { useStyles as studentStyles } from "../../Dashboarad/Student/StudentStyle/StudentVideoDetailStyle";

export default function VideoDetailStyle(userType = "") {
  let classes;
  switch (userType) {
    case "business":
      classes = businessStyles();
      break;
    case "creator":
      classes = creatorStyles();
      break;
    case "student":
      classes = studentStyles();
      break;
    default:
      classes = {};
  }
  return classes;
}
