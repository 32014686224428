import { Box, TextareaAutosize, Grid, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { CgClose } from "react-icons/cg";
import { makeStyles } from "@material-ui/core";
import "../../Stylesheet/Business/BusinessVideoDetails.scss";
const useStyles = makeStyles((theme) => ({
  logoutModalContainer: {
    background: "#2A3135",
    borderRadius: "7px",
    width: "30%",
  },
  logoutModalHeader: {
    background: "#141D22",
    padding: "10px 15px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    outline: "none",
  },
  logoutModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  modalCloseDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  modalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    background: "#2A3135",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutModalContent: {
    padding: "10px 40px",
  },
  logoutModalButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  logoutModalButtons: {
    background: "#F29600 !important",
    margin: "0px 0px 0px 10px !important",
    color: "white !important",
  },
}));
function EditVideoDescription({ classes, openModal, closeModal, textDescVideoEdit, onChangeVidDesc, saveDesc }) {
  const classess = useStyles();
  console.log(classes);
  return (
    <>
      <Modal open={openModal} onClose={closeModal} className={classess.logoutModal}>
        <Box className={classess.logoutModalContainer}>
          <Grid className={classess.logoutModalHeader}>
            <Grid className={classess.modalCloseDiv}>
              <Grid className={classess.modalCloseButtonDiv} onClick={closeModal}>
                <CgClose />
              </Grid>
            </Grid>

            <Typography variant="h6" component="h2" style={{ color: "white" }}>
              Edit Description
            </Typography>
          </Grid>
          <Grid style={{ padding: 10 }}>
            <TextareaAutosize
              className="insertDescriptionTextarea"
              aria-label="minimum height"
              minRows={7}
              value={textDescVideoEdit}
              onChange={onChangeVidDesc}
              placeholder="Insert description here..."
              style={{ width: "100%" }}
            />
            <Stack className="descriptionButtonsDiv" spacing={2} direction="row">
              <button className="modalButtonCancel" variant="contained" color="danger" onClick={closeModal}>
                Cancel
              </button>
              <button className={classes.savePlaylistButton} variant="contained" color="success" onClick={saveDesc}>
                Save
              </button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default EditVideoDescription;
