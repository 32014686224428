import { useState } from "react";
import { Grid, Typography, Link, IconButton } from "@material-ui/core";
import { AiOutlinePlus } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import VideoDetailStyle from "../ImportStyles/VideoDetailStyle";
import { default as ModalEditVideoDescription } from "../Modals/EditVideoDescription";
import { editVideoDescription } from "../../ApiComponent/getApiFunction";

export default function Details(props) {
  const { classes, videoData, anchorRefplaylist, handleTogglePlaylist, textDescVideo, setTextDescVideo, textDescVideoEdit, setTextDescVideoEdit, userType = "" } = props;
  const { vidId, vidUploaded, vidAnalyzed, vidSource } = videoData;

  const ImportedStyles = VideoDetailStyle(userType);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const clickOpenModal = () => {
    setTextDescVideoEdit(textDescVideo);
    setOpenModal(true);
  };

  const onChangeVidDesc = (e) => {
    const { value = "" } = e.target;
    setTextDescVideoEdit(value);
  };

  const saveDesc = async () => {
    const params = {
      Description: textDescVideoEdit,
      Id: vidId,
      Name: "",
      Notes: "",
      RegisseurId: "",
    };

    await editVideoDescription(params);

    setTextDescVideo(textDescVideoEdit);
    closeModal();
  };

  const renderDetails = () => {
    if (!videoData.vidId) {
      return (
        <div xs={12} className={classes.loadingDesignVidDetails}>
          <CircularProgress className={classes.loadingSvg} />
        </div>
      );
    }

    return (
      <>
        <Grid sm={2}></Grid>
        <Grid item xs={4} sm={4} className="videoDetailsTitleContainer">
          <Typography className="videoDetailsTitle"> Category</Typography>
          <Typography className="videoDetailsTitle">Uploaded </Typography>
          <Typography className="videoDetailsTitle"> Analyzed</Typography>
          <Typography className="videoDetailsTitle">Source</Typography>
          <Typography className="videoDetailsTitle"> Description</Typography>
        </Grid>
        <Grid item xs={8} sm={6} className="videoDetailsTitleContainer">
          <Typography className="videoDetailsTitle">
            {/* <Link className="addPlaylistVideo" variant="text" ref={anchorRefplaylist} onMouseEnter={() => handleTogglePlaylist()} onMouseLeave={() => handleCloseMenuPlaylist()} aria-haspopup="true"> */}
            <Link className={classes.savePlaylistText} variant="text" ref={anchorRefplaylist} onClick={handleTogglePlaylist} aria-haspopup="true">
              <AiOutlinePlus className={classes.savePlaylistAdd} /> <p> Save to playlist </p>
            </Link>
          </Typography>
          <Typography className="videoDetailsTitle">{moment(vidUploaded).local().format(" YYYY-MM-DD, hh:mm:ss A")} </Typography>
          <Typography className="videoDetailsTitle"> {moment(vidAnalyzed).local().format(" YYYY-MM-DD, hh:mm:ss A")}</Typography>
          <Typography className="videoSource">{vidSource ? vidSource.charAt(0).toUpperCase() + vidSource.slice(1) : ""} </Typography>
          <Typography className="videoDetailsTitle">
            {textDescVideo === null || textDescVideo === "" ? "No description provided" : textDescVideo}
            <IconButton onClick={clickOpenModal}>
              <BiEdit style={{ fontSize: 18 }} />
            </IconButton>
          </Typography>
        </Grid>
        <ModalEditVideoDescription classes={classes} openModal={openModal} closeModal={closeModal} textDescVideoEdit={textDescVideoEdit} onChangeVidDesc={onChangeVidDesc} saveDesc={saveDesc} />
      </>
    );
  };

  return renderDetails();
}
