import { makeStyles } from "@material-ui/core/styles";
// import stepsBg from "../Landingpage/img/stepsBg.png";

const theme = makeStyles({
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1500,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    return {
      color: "#9aa0a5",
      border: "1px solid #9aa0a5",
      borderRadius: "50px",
      fontSize: "0.8rem",
    };
  },
  selected: {
    "&&": {
      backgroundColor: "#0098D9",
      color: "#fff",
    },
  },
  container: {
    minHeight: "100vh",
    background: "#141D22",
  },
  MarginAuto: {
    margin: "auto",
  },

  //Global

  FifthGridOne: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },

  TextField: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#999B9D",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0 !important",
      display: "flex",
      justifyContent: "center",
    },
  },
  //ButtonBgCreatorLandingPage
  ButtonGray: {
    background: "#015F87",
    color: "white",
    marginTop: "30px",
  },

  ButtonSignUpDashboard: {
    background: "#0098d9",
    color: "white",
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  ButtonSignUpDiv: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "20px 0",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  SignUpTextfield: {
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      margin: "0 10px 0 0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "85%",
      margin: "0 !important",
    },
    [theme.breakpoints.up("xl")]: {
      width: "35%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
  //FONT STYLES
  FontWhite: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      margin: "30px 0",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "28px",
      margin: "30px 0",
    },
  },
  FontSubFooter: {
    color: "#0098D9",
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "45px",
    },
  },
  FontGray: {
    color: "#B3B6B8",
    marginBottom: "30px",
  },

  //End Global

  //ToolBar
  appbar: {
    background: "none",
    position: "fix",
  },
  appbarWrapper: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      background: "#141D22",
      width: "100%",
    },
  },
  // =================for scroll mobile view========================
  PricingScroll: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  ButtonSignInScroll: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,

      padding: 0,
    },
  },
  ButtonSignUpScroll: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,

      padding: 0,
    },
  },
  Toggle: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      textTransform: "none",
      fontSize: "10px !important",
    },
  },
  // =================for XS mobile view========================
  Pricing: {
    textTransform: "none",
    color: "white",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ButtonSignIn: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ButtonSignUp: {
    textTransform: "none",
    background: "#0098d9",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      boxShadow: "none",
      color: "#0098d9",
      fontSize: 14,
      padding: 4,
    },
  },
  // =================End for XS mobile view========================
  Hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  appbarTitle: {
    flexGrow: "1",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  title: {
    color: "#fff",
    fontSize: "4.5rem",
  },

  logo: {
    height: "30px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      marginTop: "0",
    },
  },

  pricingButton: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  signInButton: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 !important",
    },
  },

  menuButton: {
    color: "#0098D9",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  //End ToolBar
  logoFooter: {
    height: "30px",
    marginTop: 20,
  },

  // Toggle Menu
  ToggleMenu: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    background: "#141D22",
    zIndex: "2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  IoClose: {
    color: "#0098D9",
  },

  ToggleMenuContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
  },

  ToggleMenuButton: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },

  ToggleMenuNav: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },

  ToggleSignInMenu: {
    width: "160px",
    height: "50px",
  },

  ToggleSignUpMenu: {
    width: "160px",
    height: "50px",
    background: "#0098D9",
    color: "white",
  },

  ToggleMenuList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "20px 0",
    fontSize: "15px",
    color: "#999B9D",
  },

  //First Container
  ButtonGroup: {
    marginTop: 150,
    display: "flex",

    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      marginBottom: 10,
    },
    [theme.breakpoints.only("sm")]: {
      margin: "40px 0",
    },
  },

  ButtonGroupDiv: {
    display: "flex",
    justifyContent: "center",
  },

  TextOneTitle: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px",
    },
  },
  TextOne: {
    marginTop: 30,
    display: "flex",
    paddingLeft: 300,
    paddingRight: 300,
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center",
    color: "#0098D9",
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 10,
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  TextTwo: {
    margin: "30px 10px",
    display: "flex",
    justifyContent: "center",
    color: "#999B9D",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 30px",
    },
  },

  TextTwoTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  //End First Container

  //Second Container
  GridCenter: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "10px 30px",
    },
    [theme.breakpoints.only("sm")]: {
      margin: "15px 5px",
    },
  },

  card: {
    minWidth: 225,
    minHeight: 200,
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    background: "#0A0E11",
    [theme.breakpoints.down("xs")]: {
      minWidth: 170,
      minHeight: 180,
    },
  },
  CardContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginTop: 100,
    marginBottom: 100,
    flexGrow: 1,
    zIndex: 1,
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  CardIconSize: {
    minWidth: 70,
    minHeight: 70,
    color: "#3BB8ED",
  },
  CardText: {
    color: "#D5D6D7",
    fontSize: 20,
    margin: "auto",
    flexGrow: 1,
    textAlign: "center",
  },

  ContainerPadding: {
    padding: "80px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
      margin: "0 !important",
    },
    [theme.breakpoints.between("xs", "md")]: {
      padding: "100px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "50px 20px",
      margin: "0 !important",
    },
  },
  //End Second Container

  //Sixth Container
  SixthContainer: {
    // background:
    //   "linear-gradient(14deg, rgba(41,174,231,1) 0%, rgba(14,160,220,1) 37%, rgba(0,135,190,1) 100%)",
    backgroundImage: "url('{ stepsBg }')",
    display: "flex",
    justifyContent: "center",
    marginTop: 100,
  },
  logoSixth: {
    maxheight: "50px",
    marginTop: 20,
    marginBottom: 20,
  },
  Ellipse1Div: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  Ellipse1: {
    width: "100%",
  },
  Ellipse2Div: {
    position: "absolute",
    top: theme.spacing(45),
    left: theme.spacing(0),
    zIndex: "0",
  },
  Ellipse2: {
    width: "100%",
  },

  // GridCenter: {
  //   [theme.breakpoints.down('xs')]: {
  //     padding: "0 40px",
  //   },
  // },
  //Sixth Container
  textFieldDiv: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  GridCenterCard: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.between("2000", "2300")]: {
      margin: "0 30px",
    },
    // [theme.breakpoints.between('2301', '2500')]: {
    //   margin: "0 35px"
    // },
    // [theme.breakpoints.up('2501')]: {
    //   margin: "0 20px"
    // },
    [theme.breakpoints.between("1919", "1999")]: {
      margin: "0 36px",
    },
    [theme.breakpoints.between("1800", "1920")]: {
      margin: "0 0",
    },
    [theme.breakpoints.down("1401")]: {
      margin: "0 0",
    },
    [theme.breakpoints.down("961")]: {
      margin: "10px 0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 30px",
    },
    // [theme.breakpoints.only('sm')]: {
    //   margin: "15px 5px"
    // },
  },
  navButtonGroup: {
    display: "none",
    marginLeft: "-100px",
    // [theme.breakpoints.down("xs")]: {
    //   margin: "30px 0",
    // },
    // [theme.breakpoints.only("sm")]: {
    //   margin: "40px 0",
    // },
  },

  navButtonGroupDiv: {
    display: "flex",
    justifyContent: "center",
  },

  CardContainerDiv: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
    },
  },
}));
export { useStyles };
