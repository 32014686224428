import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, TextareaAutosize, Button, Box } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Select from "@mui/material/Select";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { CgClose } from "react-icons/cg";
import { FaLink } from "react-icons/fa";
import "../../Stylesheet/Student/StudentVideoDetails.scss";
import { getClientCredentials, getClientUpdateCredentials, updateCredentials, sendConfirmationCodeForDelete, deleteUserCredentials, transferAccount, logout } from "../../ApiComponent/getApiFunction";
// import { country } from "./utils";
import history from "../../Landingpage/history";
import NotificationSnackbar from "../Notification/Snackbar";

export default function Settings(props) {
  const { classes } = props;
  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState([]);
  const [isFetching, setFetching] = useState(true);
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [ustLdnr, setUStldNr] = useState("");
  const [Id, setId] = useState("");
  const [clientId, setClientId] = useState("");
  const [isOpenModal, setOpenModal] = useState(false);
  const [inputConfirmationCode, setInputConfirmationCode] = useState("");
  const [inputEmailTransfer, setInputemailTransfer] = useState("");
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isModalInviteFriendOpen, setModalInviteFriend] = useState(false);
  const [isCopyLink, setCopyLink] = useState(false);
  const [httpLinkURL, setHttpLink] = useState("");
  const Auth = localStorage.getItem("Auth");
  const [isCopyClipboard, setCopyClipboard] = useState(false);
  const token = localStorage.getItem("token");
  const durationNotif = 3000;
  const [msgNotif, setMsgNotif] = useState("");
  const [errTypeNotif, setErrTypeNotif] = useState("info");
  const [openNotif, setOpenNotif] = useState(false);

  const closeNotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif(false);
  };

  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
    console.log(event.target.value);
  };

  const updateEmail = (e) => {
    const { value = "" } = e.target;
    setEmail(value);
  };

  const updateCompany = (e) => {
    const { value = "" } = e.target;
    setCompany(value);
  };

  const updateAddress = (e) => {
    const { value = "" } = e.target;
    setAddress(value);
  };

  const updateContact = (e) => {
    const { value = "" } = e.target;
    setContact(value);
  };

  const UStldNr = (e) => {
    const { value = "" } = e.target;
    setUStldNr(value);
  };

  function deleteHoverEnter() {
    var deleteButton = document.getElementById("delete-button");
    deleteButton.style.background = "red";
  }

  function deleteHoverLeave() {
    var deleteButton = document.getElementById("delete-button");
    deleteButton.style.background = "transparent";
  }

  function inviteHoverEnter() {
    var deleteButton = document.getElementById("invite-button");
    deleteButton.style.background = "green";
  }

  function inviteHoverLeave() {
    var deleteButton = document.getElementById("invite-button");
    deleteButton.style.background = "transparent";
  }

  const countryApi = async () => {
    let fetchCountry = await axios.get("https://restcountries.com/v2/all");
    const { data } = fetchCountry;
    setCountry(data);
    setFetching(false);
  };

  const ClientCrdtls = async () => {
    const formDatav1 = await getClientCredentials();
    let { data } = formDatav1;
    setEmail(data.Email);
    setId(data.Id);
    setClientId(data.ClientId);
  };

  const Client = async () => {
    const Auth = localStorage.getItem("Auth");
    const formDatav2 = await getClientUpdateCredentials(Auth);
    let { data } = formDatav2;
    setAddress(data.Adress);
    setCompany(data.CompanyName);
    setContact(data.Phone);
    setUStldNr(data.UStIdNr);
    setSelectedCountry(data.Country);
  };

  useEffect(() => {
    ClientCrdtls();
    Client();
    countryApi();
  }, []);

  const updateCrdtls = async () => {
    const Auth = localStorage.getItem("Auth");
    const data = {
      UserId: Id,
      Id: clientId,
      Adress: address,
      CompanyName: company,
      Country: selectedCountry,
      Email: email,
      Phone: contact,
      UStIdNr: ustLdnr,
    };
    await updateCredentials(Auth, data);
    history.push("/student/video");
  };

  const deleteUserHandler = async () => {
    setOpenModal(true);
    await sendConfirmationCodeForDelete(Auth);
  };

  const inputCodeforDelete = (e) => {
    const { value = "" } = e.target;
    setInputConfirmationCode(value);
  };

  const deleteUser = async () => {
    await deleteUserCredentials(inputConfirmationCode, Auth).then(() => {
      logout(token);
      setOpenNotif(true);
      setErrTypeNotif("success");
      setMsgNotif("Delete account successfuly");
      localStorage.clear();
      history.push("/student");
    });
  };

  const resendCnfrmCodeHandler = async () => {
    await sendConfirmationCodeForDelete(Auth);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const transferUser = () => {
    setTransferModalOpen(true);
  };

  const handleCloseModalTransfer = () => {
    setTransferModalOpen(false);
  };

  const inputEmailToTrasfer = (e) => {
    const { value = "" } = e.target;
    setInputemailTransfer(value);
  };

  const HandlertransferUser = async () => {
    const data = {
      Id: "1",
      SourceId: Auth,
      TargetEmail: inputEmailTransfer,
    };
    await transferAccount(data).then(() => {
      logout(token);
      setOpenNotif(true);
      setErrTypeNotif("success");
      setMsgNotif("Transfer account successfully");
      localStorage.clear();
      history.push("/student");
    });
  };

  const openModalInvite = () => {
    setModalInviteFriend(true);
  };

  const handleCloseModalInvite = () => {
    setModalInviteFriend(false);
  };

  const httpsLink = `https://vaip-dev.xaasfarm.com/?pk_cpn=${email}`;
  const HandlerInviteFriend = () => {
    setHttpLink(httpsLink);
    setCopyLink(true);
    setOpenNotif(true);
    setErrTypeNotif("success");
    setMsgNotif("Link Copied to Clipboard");
  };

  const getStyle = () => {
    return {
      display: isCopyLink ? "block" : "none",
      width: "100%",
    };
  };

  return (
    <div className={classes.SettingsDiv}>
      <Grid className={classes.SettingsUpperButtonContainer}>
        <Grid className={classes.SettingsUpperButtonDiv1}>
          <button onClick={openModalInvite} className={classes.InviteButton} onMouseEnter={inviteHoverEnter} onMouseLeave={inviteHoverLeave} id="invite-button">
            Invite friends
          </button>
        </Grid>
        <Grid className={classes.SettingsUpperButtonDiv2}>
          <button className={classes.SettingsButton} onClick={transferUser}>
            Transfer Account
          </button>
          <button className={classes.SettingsButton} onMouseEnter={deleteHoverEnter} onMouseLeave={deleteHoverLeave} id="delete-button" onClick={deleteUserHandler}>
            Delete Account
          </button>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Grid className={classes.SettingsTextfieldDiv}>
          <Typography>Email:</Typography>
          <TextField className={classes.SettingsTextfield} placeholder="Email" variant="outlined" size="small" onChange={updateEmail} value={email} />
        </Grid>
        <Grid className={classes.SettingsTextfieldDiv}>
          <Typography>Company name:</Typography>
          <TextField className={classes.SettingsTextfield} placeholder="Company name" variant="outlined" size="small" onChange={updateCompany} value={company} />
        </Grid>
        <Grid className={classes.SettingsTextfieldDiv}>
          <Typography>Address:</Typography>
          <TextareaAutosize
            className={classes.SettingsTextarea}
            value={address}
            aria-label="minimum height"
            minRows={4}
            placeholder="Enter your address"
            onChange={updateAddress}
            style={{ width: "60%" }}
          />
        </Grid>
        <Grid className={classes.SettingsTextfieldDiv}>
          <Typography>Country:</Typography>
          <FormControl variant="outlined" size="small" className={classes.SettingsSelect}>
            <Select native value={selectedCountry} onChange={handleChange} className={classes.SettingsSelectDiv}>
              <option selected value={selectedCountry} style={{ color: "#000" }}>
                {selectedCountry}
              </option>
              {country.map((countryData) => (
                <option value={countryData.name} style={{ color: "#000" }}>
                  {countryData.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid className={classes.SettingsTextfieldDiv}>
          <Typography>Phone:</Typography>
          <TextField className={classes.SettingsTextfield} value={contact} placeholder="Phone number" variant="outlined" size="small" onChange={updateContact} />
        </Grid>
        <Grid className={classes.SettingsTextfieldDiv}>
          <Typography>USt-ldNr:</Typography>
          <TextField className={classes.SettingsTextfield} value={ustLdnr} placeholder="USt-ldNr" variant="outlined" size="small" onChange={UStldNr} />
        </Grid>
        <Grid className={classes.SettingsBottomButtonContainer}>
          <button className={classes.SettingsButton}>Cancel</button>
          <button onClick={updateCrdtls} className={classes.SettingsButtonSave}>
            Save
          </button>
        </Grid>
      </Grid>

      {/* == @@ Modal for UpdateProfile == */}

      <Modal open={isOpenModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={classes.logoutModal}>
        <Box className={classes.logoutModalContainer}>
          <Grid className={classes.logoutModalHeader}>
            <Grid className={classes.modalCloseDiv}>
              <Grid className={classes.modalCloseButtonDiv}>
                <CgClose onClick={handleCloseModal} />
              </Grid>
            </Grid>
            <Typography variant="h6" component="h2" style={{ color: "white" }}>
              Confirm Profile Deletion at vaip.ai
            </Typography>
          </Grid>
          <Grid className={classes.logoutModalContent}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={1}
              placeholder="Confirmation Key... "
              className="insertDescriptionTextarea"
              style={{ width: "100%" }}
              onChange={inputCodeforDelete}
            />
            <Grid className={classes.logoutModalButtonDiv}>
              <Button onClick={handleCloseModal} variant="outlined">
                <typography> Resend Mail</typography>
              </Button>
              <Button className={classes.logoutModalButtons}>
                <typography> Delete</typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* == @@ Modal for Transfer Account == */}
      <Modal open={isTransferModalOpen} onClose={handleCloseModalTransfer} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={classes.logoutModal}>
        <Box className={classes.logoutModalContainer}>
          <Grid className={classes.logoutModalHeader}>
            <Grid className={classes.modalCloseDiv}>
              <Grid className={classes.modalCloseButtonDiv}>
                <CgClose onClick={handleCloseModalTransfer} />
              </Grid>
            </Grid>
            <Typography variant="h6" component="h2" style={{ color: "white" }}>
              Transfer Account
            </Typography>
          </Grid>
          <Grid className={classes.logoutModalContent}>
            <TextareaAutosize aria-label="minimum height" minRows={1} placeholder="New Owner (Email)" className="insertDescriptionTextarea" style={{ width: "100%" }} onChange={inputEmailToTrasfer} />
            <Grid className={classes.logoutModalButtonDiv}>
              <Button onClick={HandlertransferUser} className={classes.logoutModalButtons}>
                <typography> Transfer</typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* @@ Modal for Invite Friend */}

      <Modal open={isModalInviteFriendOpen} onClose={handleCloseModalInvite} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={classes.logoutModal}>
        <Box className={classes.logoutModalContainer}>
          <Grid className={classes.logoutModalHeader}>
            <Grid className={classes.modalCloseDiv}>
              <Grid className={classes.modalCloseButtonDiv}>
                <CgClose onClick={handleCloseModalInvite} />
              </Grid>
            </Grid>
            <Typography variant="h6" component="h2" style={{ color: "white" }}>
              Invite Friends
            </Typography>
          </Grid>
          <Grid className={classes.logoutModalContent}>
            <Grid container>
              <Grid xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CopyToClipboard text={httpLinkURL} onCopy={() => setCopyClipboard(true)}>
                  <Button onClick={HandlerInviteFriend} className={classes.logoutModalButtons} startIcon={<FaLink />}>
                    <typography> Copy Link</typography>
                  </Button>
                </CopyToClipboard>
              </Grid>
              <Grid xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <TextareaAutosize aria-label="minimum height" minRows={1} className="insertDescriptionTextarea" style={getStyle()} value={httpLinkURL} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* @@snackBar  */}
      <NotificationSnackbar openNotif={openNotif} closeNotif={closeNotif} errTypeNotif={errTypeNotif} msgNotif={msgNotif} durationNotif={durationNotif} />
    </div>
  );
}
