import { Grid, CardMedia, Typography, Button, TextField, FormControl } from "@material-ui/core";
import image25 from "../../Dashboarad/img/image 25.png";
import image26 from "../../Dashboarad/img/image 26.png";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import VideoDetailStyle from "../ImportStyles/VideoDetailStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

const timeline = createTheme({
  overrides: {
    MuiTimelineOppositeContent: {
      root: {
        flex: "0.01  !important",
      },
    },
  },
});

export default function Notes(props) {
  const { userType = "" } = props;

  const classes = VideoDetailStyle(userType);

  return (
    <>
      <Grid container className={classes.addVideoTags}>
        <Grid xs={12} sm={11} className={classes.addVideoTagsDiv}>
          <FormControl fullWidth>
            <TextField placeholder="Write your note here" variant="outlined" size="small" />
          </FormControl>
          <Button variant="contained" className={classes.ButtonContained}>
            Add Note
          </Button>
        </Grid>
      </Grid>
      <ThemeProvider theme={timeline}>
        <Timeline className={classes.oppositeContent}>
          <TimelineItem>
            <TimelineOppositeContent className={classes.oppositeContent} color="textSecondary"></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Grid container>
                <Grid xs={4}>
                  <CardMedia className={classes.mediaTwo} image={image25} />
                </Grid>
                <Grid xs={8}>
                  <Grid container spacing={3} style={{ padding: 30 }}>
                    <Typography>
                      A video description is a piece of metadata that helps YouTube understand the content of a video. Along with your video title and tags, YouTube uses your description to understand
                      the content.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem style={{ background: "#222628", borderRadius: 16 }}>
            <TimelineOppositeContent className={classes.oppositeContent} color="textSecondary"></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Grid container>
                <Grid xs={4}>
                  <CardMedia className={classes.mediaTwo} image={image26} />
                </Grid>
                <Grid xs={8}>
                  <Grid container spacing={3} style={{ padding: 30 }}>
                    <Typography>
                      A video description is a piece of metadata that helps YouTube understand the content of a video. Along with your video title and tags, YouTube uses your description to understand
                      the content.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </ThemeProvider>
    </>
  );
}
