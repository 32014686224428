import { TabPanel as businessTabPanel } from "../../Dashboarad/Business/BusinessVideoDetails";
import { TabPanel as creatorTabPanel } from "../../Dashboarad/Creator/CreatorVideoDetails";
import { TabPanel as studentTabPanel } from "../../Dashboarad/Student/StudentVideoDetails";

export default function VideoDetailTabPanel(userType = "") {
  let tabPanel;
  switch (userType) {
    case "business":
      tabPanel = businessTabPanel;
      break;
    case "creator":
      tabPanel = creatorTabPanel;
      break;
    case "student":
      tabPanel = studentTabPanel;
      break;
    default:
      tabPanel = {};
  }
  return tabPanel;
}
