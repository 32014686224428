import React from "react";
import { Grid, Button, Typography, TextField, FormControl, Link } from "@material-ui/core";
import history from "../history";
import ToolbarSignUpBusiness from "../Toolbar/ToolbarSignUpBusiness";
import Ellipse1 from "../../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../../Landingpage/img/BusinessEllipse2.png";
import { useStyles } from "../../Landingpage/BusinessLogIn/BusinessLoginStyle/SiginBusinessStyle";

export default function SignUpCodeCreator(theme) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid className={classes.Ellipse1Div}>
        <img src={Ellipse1} className={classes.Ellipse1} alt="" />
      </Grid>
      <Grid className={classes.Ellipse2Div}>
        <img src={Ellipse2} className={classes.Ellipse2} alt="" />
      </Grid>
      <ToolbarSignUpBusiness />
      <Grid container>
        {/*First Container--------------------------------------------------------------------- */}

        <Grid container justifyContent="center">
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h5">
              <h3>Sign Up</h3>
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="subtitle1">Please check your inbox and enter the token below.</Typography>
          </Grid>
          <Grid sm={4} xs={10} md={5} xl={2} className={classes.TextfieldSign}>
            <FormControl fullWidth variant="filled">
              <Grid container>
                <Grid xs={2} className={classes.TextFieldCode}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                  />
                </Grid>
                <Grid xs={2} className={classes.TextFieldCode}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                  />
                </Grid>
                <Grid xs={2} className={classes.TextFieldCode}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                  />
                </Grid>
                <Grid xs={2} className={classes.TextFieldCode}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                  />
                </Grid>
                <Grid xs={2} className={classes.TextFieldCode}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                  />
                </Grid>
                <Grid xs={2} className={classes.TextFieldCode}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                  />
                </Grid>
              </Grid>
              <Button variant="outlined" size="large" onClick={() => history.push("/business")} className={classes.signUpButton}>
                Sign Up
              </Button>
              <Grid xs={12} className={classes.TextTwo}>
                <Typography variant="subtitle1">
                  You didn’t received the token? <Link>Resend token</Link>
                </Typography>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {/*Footer--------------------------------------------------------------------- */}
    </div>
  );
}
