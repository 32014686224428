import React, { useState, useEffect } from "react";
import axios from "axios";

import OtpInput from "react-otp-input";
import { Grid, Button, Typography, FormControl, Link } from "@material-ui/core";
import history from "../history";
import ToolbarSignInStudent from "../Toolbar/ToolbarSignInStudent";
import Ellipse1 from "../../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../../Landingpage/img/StudentEllipse2.png";
import { useStyles } from "../../Landingpage/StudentLogIn/StudentLoginStyle/StudentLoginStyle";
import { routesAPI } from "../../ApiComponent/getApiFunction";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import "../../Stylesheet/LoginModule/index.scss";

const SignInStudentCode = (props) => {
  const classes = useStyles();
  const [hashCode, setHashCode] = useState("");
  const email = localStorage.getItem("Email") ? localStorage.getItem("Email") : "";
  const [validationMessage, setValidationMessage] = useState("");
  const [is200Response, set200Response] = useState(false);
  const [isSnackBar, setSnackBarOpen] = useState(false);

  useEffect(() => {
    addEventKeypress();
    const containerOTP = document.getElementsByClassName("MuiGrid-root OTPCodeInputContainer")[0].firstElementChild;
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
  }, []);
  const addEventKeypress = () => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        sendLoginCredentials();
      }
    };
    document.addEventListener("keydown", listener);
  };
  //@@ get hash code input by user
  const getHashCodeInput = (hashCodeInput) => {
    hashCodeInput = hashCodeInput.replace(/\D/g, "");
    setHashCode(hashCodeInput);
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const sendLoginCredentials = async () => {
    try {
      const userHashCode = hashCode;
      const digits = userHashCode.toString().split("");
      let hashDigits = "";
      for (let i = 0; i < digits.length; i++) {
        hashDigits = hashDigits.concat(`${digits[i]}-`);
      }

      const userCredentials = {
        Email: email,
        Password: "Empty",
        Hash: hashDigits.slice(0, -1),
      };

      const response = await axios.post(`${routesAPI.loginUser}`, userCredentials);
      if (response) {
        const userData = response.data.value.split("#");
        const token = userData[1];
        const Auth = userData[2];
        localStorage.setItem("userType", "student");
        localStorage.setItem("token", token);
        localStorage.setItem("Auth", Auth);
        localStorage.setItem("userData", response.data.value);
        history.push({ pathname: "/student/dashboard" });
      }
    } catch (e) {
      set200Response(false);
      setSnackBarOpen(true);
      setValidationMessage("Invalid Confirmation Key! ");
    }
  };

  //@@ Resend Token/ OTP

  const resendOTPCode = async () => {
    try {
      const emailData = {
        Email: localStorage.getItem("Email"),
        Id: localStorage.getItem("Id"),
        Language: "en",
        New: false,
        Package: null,
      };

      const resendEmailToGetToken = await axios.post(`${routesAPI.sendEmail}`, emailData);
      if (!resendEmailToGetToken) {
        setValidationMessage("Try again, unable to send OTP!");
      } else {
        if (resendEmailToGetToken.data.value === 200) {
          set200Response(true);
          setSnackBarOpen(true);
          setValidationMessage("Confirmation token successfully resend!");
        } else {
          setSnackBarOpen(true);
          setValidationMessage("Try again, unable to send OTP!");
        }
      }
    } catch (e) {
      setSnackBarOpen(true);
      setValidationMessage("Try again, unable to send OTP!");
    }
  };

  return (
    <div className={classes.container}>
      <Grid className={classes.Ellipse1Div}>
        <img src={Ellipse1} alt="" className={classes.Ellipse1} />
      </Grid>
      <Grid className={classes.Ellipse2Div}>
        <img src={Ellipse2} alt="" className={classes.Ellipse2} />
      </Grid>
      <ToolbarSignInStudent />

      <Grid container>
        <Grid container justifyContent="center">
          <Grid xs={12}>
            <Typography className="siginTitle"> Sign In </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography className={classes.signinSubTitle}>Please check your inbox and enter the token below.</Typography>
          </Grid>
          <Grid className={classes.TextfieldSign}>
            <FormControl fullWidth variant="filled" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Grid xs={12} className="OTPCodeInputContainer">
                <OtpInput
                  value={hashCode}
                  onChange={getHashCodeInput}
                  numInputs={11}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: "1px solid #F5F5F5",
                    borderRadius: "8px",
                    width: "35px",
                    height: "45px",
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "400",
                    caretColor: "blue",
                    background: "transparent",
                    marginLeft: "12px",
                  }}
                  focusStyle={{ border: "1px solid #CFD3DB", outline: "none" }}
                />
              </Grid>
              <Button variant="outlined" size="large" onClick={sendLoginCredentials} className={classes.ButtonSignin}>
                Sign In
              </Button>
              <Grid className={classes.resendTokenContainer}>
                <Typography className={classes.resendTokenMessage}>
                  You didn’t received the token?{" "}
                  <Link className="resendTokenLink" onClick={resendOTPCode}>
                    Resend token
                  </Link>
                </Typography>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>

        <Snackbar open={isSnackBar} autoHideDuration={3000} onClose={closeSnackBar} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert severity={is200Response ? "success" : "error"} variant="filled" onClose={closeSnackBar} sx={{ width: "100%" }}>
            {validationMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
};
export default SignInStudentCode;
