import React, { useState } from "react";
import { Grid, Button, Card, CardMedia, Typography, TextField, withStyles, CardContent, IconButton, CardActionArea, Divider, Link, ThemeProvider, AppBar } from "@material-ui/core";
import {} from "@material-ui/core/styles";
import VaipAi from "../Landingpage/img/vaipAi.png";
import Ellipse1 from "../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../Landingpage/img/CreatorEllipse2.png";
import stepsBg from "../Landingpage/img/stepsBg.png";
import One from "../Landingpage/img/one.png";
import Two from "../Landingpage/img/two.png";
import Three from "../Landingpage/img/Three.png";
import { Toolbar } from "@material-ui/core";
import SafeImage from "../Landingpage/img/pexels-photo-532864 1.png";
import EasyImage from "../Landingpage/img/pexels-photo-6147067 1.png";
import FairImage from "../Landingpage/img/pexels-photo-3184430 2.png";
import Sensitive from "../Landingpage/img/pexels-photo-373543 1.png";
import Footer from "./Footer";
import history from "./history";
import { useStyles } from "./LandingpagesStyle/CreatorStyle";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import { createTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
//======================Import Icons======================
import { IoClose } from "react-icons/io5";
import { FaDatabase } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { BsFillShieldLockFill } from "react-icons/bs";
import { GiPadlock } from "react-icons/gi";
import { MdDataUsage } from "react-icons/md";

const landingCard = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        minWidth: "175px !important",
      },
    },
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#999B9D",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#999B9D",
      },
      "&:hover fieldset": {
        borderColor: "#0098D9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);
function MyToggleButton(props) {
  const { color, ...other } = props;
  const classes = useStyles({ color });
  return <ToggleButton classes={classes} {...other} />;
}
export default function Creator(theme) {
  const [color, setColor] = React.useState("primary");
  const handleColor = (e, value) => setColor(value);

  const [isOpened, setIsOpened] = useState(false);

  const classes = useStyles();

  //============For Scroll
  function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
      <Slide appear={false} direction="down" in={trigger}>
        {children}
      </Slide>
    );
  }

  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
  return (
    <div className={classes.container}>
      <Grid className={classes.Ellipse1Div}>
        <img src={Ellipse1} className={classes.Ellipse1} alt="" />
      </Grid>
      <Grid className={classes.Ellipse2Div}>
        <img src={Ellipse2} className={classes.Ellipse2} alt="" />
      </Grid>

      {/* ========= Fetch Api and render to Jsx  ==================== */}

      <Grid container>
        {/* Toolbar*/}

        <Toolbar className={classes.appbarWrapper}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setIsOpened(true)}>
            <HiMenu />
          </IconButton>
          <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
            <img src={VaipAi} className={classes.logo} alt="" />
          </h1>
          <Button onClick={() => history.push("/Pricing")} style={{ color: "white", marginRight: 20 }} className={classes.Pricing}>
            Pricing
          </Button>
          <Button variant="outlined" style={{ marginRight: 20 }} onClick={() => history.push("/sign-in/creator")} className={classes.ButtonSignIn}>
            Sign-In
          </Button>
          <Button variant="contained" color="primary" onClick={() => history.push("/sign-up/creator")} className={classes.ButtonSignUp}>
            Sign-Up
          </Button>
        </Toolbar>

        <HideOnScroll>
          <AppBar style={{ backgroundColor: "#141c22" }}>
            <Toolbar className={classes.Hide}>
              <Grid xs={4} style={{ display: "flex", justifyContent: "center" }}>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setIsOpened(true)}>
                  <HiMenu />
                </IconButton>
                <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
                  <img src={VaipAi} className={classes.logo} alt="" />
                </h1>
              </Grid>
              <Grid xs={4} style={{ display: "flex", justifyContent: "center" }}>
                <ToggleButtonGroup onChange={handleColor} value={color} exclusive>
                  <MyToggleButton value="1" onClick={() => history.push("/business")} className={classes.Toggle}>
                    Business
                  </MyToggleButton>
                  <MyToggleButton value="2" onClick={() => history.push("/student")} className={classes.Toggle}>
                    Student
                  </MyToggleButton>
                  <MyToggleButton value="primary" className={classes.Toggle}>
                    Creator
                  </MyToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid xs={4} style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={() => history.push("/pricingstudent")} style={{ color: "white", marginRight: 20 }} className={classes.PricingScroll}>
                  Pricing
                </Button>
                <Button variant="outlined" style={{ marginRight: 20 }} onClick={() => history.push("/sign-in/student")} className={classes.ButtonSignInScroll}>
                  {" "}
                  Sign-In
                </Button>
                <Button variant="contained" color="primary" onClick={() => history.push("/sign-up/student")} className={classes.ButtonSignUpScroll}>
                  Sign-Up
                </Button>
              </Grid>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        {/* Toggle Menu---------------------------------------------------------------------*/}
        {isOpened ? (
          <Grid xs={12} className={classes.ToggleMenu}>
            <Grid style={{ width: "100%" }}>
              <Grid xs={12} className={classes.ToggleMenuNav}>
                <IoClose style={{ color: "#f29600", fontSize: "30px", position: "absolute", top: "10px", left: "10px" }} onClick={() => setIsOpened(false)} />
                <img src={VaipAi} className={classes.logo} alt="" />
              </Grid>
              <Divider />
            </Grid>
            <Grid xs={12} style={{ width: "100%", height: "auto" }} className={classes.ToggleMenuContent}>
              <Grid className={classes.ToggleMenuButton}>
                <Button variant="outlined" onClick={() => history.push("/sign-in/creator")} className={classes.ToggleSignInMenu}>
                  Sign-In
                </Button>
                <Button variant="contained" onClick={() => history.push("/sign-up/creator")} className={classes.ToggleSignUpMenu}>
                  Sign-Up
                </Button>
              </Grid>
              <Grid xs={12} className={classes.ToggleMenuList}>
                <Grid>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/")}>
                      Home
                    </Typography>
                  </Link>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/business")}>
                      Business
                    </Typography>
                  </Link>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/student")}>
                      Student
                    </Typography>
                  </Link>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/")}>
                      Creators
                    </Typography>
                  </Link>
                </Grid>
                <Typography variant="small">Company</Typography>
                <Grid>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/pricing")}>
                      Pricing
                    </Typography>
                  </Link>
                  <Link href="https://xaas.farm/vaip-ai/" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      About us
                    </Typography>
                  </Link>
                  <Link href="https://xaasfarm.freshdesk.com/support/home" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      FAQ
                    </Typography>
                  </Link>
                  <Link href="https://shipright.community/vaip" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Feature Request
                    </Typography>
                  </Link>
                </Grid>
                <Typography variant="small">Legal</Typography>
                <Grid>
                  <Link href="https://xaas.farm/impressum-2" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Impressum
                    </Typography>
                  </Link>
                  <Link href="https://vaip-dev.xaasfarm.com/main/agb" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      AGB & Kundeninfo
                    </Typography>
                  </Link>
                  <Link href="https://vaip-dev.xaasfarm.com/main/Widerrufsbelehrung" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Widerrufsbelehrung
                    </Typography>
                  </Link>
                  <Link href="https://vaip-dev.xaasfarm.com/main/dsgvo" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Datenschutzerklärung
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {/* First Container---------------------------------------------------------------------*/}
        {/* First Row */}
        <Grid xs={12} className={classes.ButtonGroup}>
          <ToggleButtonGroup onChange={handleColor} value={color} exclusive>
            <MyToggleButton value="1" onClick={() => history.push("/business")} className={classes.Toggle}>
              Business
            </MyToggleButton>
            <MyToggleButton value="2" onClick={() => history.push("/student")} className={classes.Toggle}>
              Student
            </MyToggleButton>
            <MyToggleButton value="primary" className={classes.Toggle}>
              Creator
            </MyToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {/*Second Row */}
        <Grid className={classes.TextOne}>
          <Typography variant="h2">
            <h3 className={classes.TextOneTitle}>Change the way your video is valued✨</h3>
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.TextTwo}>
          <Typography variant="subtitle1" className={classes.TextTwoTitle}>
            Register now and join the artist-friendly video platform
          </Typography>
        </Grid>
        {/*Thrid Row */}
        <Grid item xs={12} className={classes.textFieldDiv}>
          <Grid item xl={7} md={7} xs={12} sm={8} className={classes.TextField}>
            <CssTextField
              variant="outlined"
              id="custom-css-outlined-input"
              size="small"
              placeholder="Your email address"
              style={{ color: "white", marginRight: 10 }}
              className={classes.SignUpTextfield}
            />
          </Grid>
          <Grid md={5} xs={12} sm={4} className={classes.ButtonSignUpDiv}>
            <Button variant="contained" color="primary" className={classes.ButtonSignUpDashboard} onClick={() => history.push("/sign-up/creator")}>
              Sign-Up
            </Button>
          </Grid>
        </Grid>

        {/* Second Container---------------------------------------------------------------------*/}
        <Grid container className={classes.CardContainer}>
          <ThemeProvider theme={landingCard}>
            <Grid className={classes.CardContainerDiv} sm={12} md={12} lg={9} xl={6}>
              <Grid xs={4} sm={5} md={3} lg={3} xl={2} className={classes.GridCenterCard}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <Grid xs={12} className={classes.GridCenterCard}>
                      <FaDatabase className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12} className={classes.GridCenterCard}>
                      <CardContent className={classes.CardText}>
                        <Typography>
                          Local data
                          <br /> storage
                        </Typography>
                      </CardContent>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid xs={4} sm={5} md={3} lg={3} xl={2} className={classes.GridCenterCard}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <Grid xs={12} className={classes.GridCenter}>
                      <BsFillShieldLockFill className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12} className={classes.GridCenter}>
                      <CardContent className={classes.CardText}>
                        <Typography>
                          Privacy <br />
                          Protection
                        </Typography>
                      </CardContent>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid xs={4} sm={5} md={3} lg={3} xl={2} className={classes.GridCenterCard}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <Grid xs={12} className={classes.GridCenter}>
                      <GiPadlock className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12} className={classes.GridCenter}>
                      <CardContent className={classes.CardText} style={{ padding: "20" }}>
                        <Typography>
                          Security & <br />
                          Compliance
                        </Typography>
                      </CardContent>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid xs={4} sm={5} md={3} lg={3} xl={2} className={classes.GridCenterCard}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <Grid xs={12} className={classes.GridCenter}>
                      <MdDataUsage className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12} className={classes.GridCenter}>
                      <CardContent className={classes.CardText}>
                        <Typography
                          style={{
                            align: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}>
                          {" "}
                          Commissioned data <br />
                          processing
                        </Typography>
                      </CardContent>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Grid>
        {/* Third Container---------------------------------------------------------------------*/}
        <Grid container className={classes.ContainerPadding}>
          <Grid item xs={12} sm={6} className={classes.FifthGridOne}>
            <Typography variant="h4">
              <Button variant="contained" className={classes.ButtonGray}>
                Easy
              </Button>
              <h2 className={classes.FontWhite}>
                Share & Sell your videos in <br />
                seconds 👌
              </h2>
              <Typography variant="subtitle1" className={classes.FontGray}>
                <subtitle>
                  {" "}
                  We are tired of platforms offering you thousands of functions, constantly changing and completely ignoring your needs! We are different! We offer you one simple tool to create, share
                  and sell your videos.
                </subtitle>
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5} style={{ margin: "auto" }}>
            <Card>
              <CardMedia className={classes.media} image={EasyImage} />
            </Card>
          </Grid>
        </Grid>
        {/*Fourth Container---------------------------------------------------------------------*/}
        <Grid container className={classes.ContainerPadding}>
          <Grid xs={12} sm={5} style={{ margin: "auto" }}>
            <Card>
              <CardMedia className={classes.media} image={SafeImage} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.FifthGridOne}>
            <Typography variant="h4">
              <Button variant="contained" className={classes.ButtonGray}>
                Safe
              </Button>
              <h2 className={classes.FontWhite}>Your private cloud in Germany 🇩🇪</h2>
              <Typography variant="subtitle1" className={classes.FontGray}>
                <subtitle>
                  Can you tell me where your videos are stored at the moment and who has really access to it? Do you know who you can write or call if you have problems or questions? From now on we
                  take care of everything. We deliver your personal video cloud, where everything is private by default. You decide what you share and how.
                </subtitle>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {/*Fifth Container---------------------------------------------------------------------*/}
        <Grid container className={classes.ContainerPadding}>
          <Grid item xs={12} sm={5} className={classes.FifthGridOne}>
            <Typography variant="h4">
              <Button variant="contained" className={classes.ButtonGray}>
                Fair
              </Button>
              <h2 className={classes.FontWhite}>We are your partner 🤝</h2>
              <Typography variant="subtitle1" className={classes.FontGray}>
                <subtitle>
                  {" "}
                  Youtube has earned 15.15 billion USD in 2019 with video content. How much have their creators earned? We think that the relationship between platform and content creator is unfair.
                  Small artists don't earn anything and once you've made it, the platform decides which content can be advertising-friendly and thus monitarized. We change the game. You get the money
                  for your content and we just get a small fee for our service
                </subtitle>
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5} style={{ margin: "auto" }}>
            <Card>
              <CardMedia className={classes.media} image={FairImage} />
            </Card>
          </Grid>
        </Grid>
        {/*Sixth Container---------------------------------------------------------------------*/}
        <Grid container className={classes.SixthContainer} style={{ backgroundImage: "url(" + stepsBg + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundColor: "#0098D9" }}>
          <Grid item sm={12} className={classes.GridCenter}>
            <Typography variant="h4" style={{ marginTop: 50, marginBottom: 50, textAlign: "center" }}>
              <h3>Follow these 3 easy steps</h3>
            </Typography>
          </Grid>
          <Grid item sm={3} className={classes.GridCenter} style={{ marginBottom: 100 }}>
            <Typography>
              <img src={One} className={classes.logoSixth} alt="" />
              <h3>Upload, Record or Import your media</h3>
              <subtitle>You can import your existing media or record directly to our platform</subtitle>
            </Typography>
          </Grid>
          <Grid item sm={3} className={classes.GridCenter} style={{ marginBottom: 100 }}>
            <Typography>
              <img src={Two} className={classes.logoSixth} alt="" />
              <h3>Manage and Share</h3>
              <subtitle>Manage your video content just as your personal archive or share videos with single persons or the whole world</subtitle>
            </Typography>
          </Grid>
          <Grid item sm={3} className={classes.GridCenter} style={{ marginBottom: 100 }}>
            <Typography>
              <img src={Three} className={classes.logoSixth} alt="" />
              <h3>Create Value</h3>
              <subtitle>You decide whether and how you want to market your content. We offer you a wide range of opportunities to generate added value from your videos</subtitle>
            </Typography>
          </Grid>
        </Grid>
        {/*Seven Container---------------------------------------------------------------------*/}
        <Grid container className={classes.ContainerPadding}>
          <Grid item xs={12} sm={5} className={classes.FifthGridOne}>
            <Typography variant="h3">
              <h5 className={classes.FontWhite}>Sensitive data?</h5>
              <h2 className={classes.FontSubFooter} style={{ color: "#0098D9" }}>
                No problem!
              </h2>
              <Typography variant="subtitle1">
                <subtitle className={classes.FontGray}>Upon request, our platform can be installed on-premise and integrated into your workflow. Your data does not leave the company.</subtitle>
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5} style={{ margin: "auto" }}>
            <Card>
              <CardMedia className={classes.media} image={Sensitive} />
            </Card>
          </Grid>
        </Grid>
        {/*Eight Container---------------------------------------------------------------------*/}
        <Grid container className={classes.ContainerPadding} style={{ background: "#000" }}>
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h3">
              <h3>Are you ready to join?</h3>
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="subtitle1">Become part of our exclusive beta program and test the platform for free!</Typography>
          </Grid>
          <Grid xs={12} className={classes.textFieldDiv}>
            <Grid md={7} xs={12} sm={8} className={classes.TextField}>
              <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                size="small"
                placeholder="Your email address"
                style={{ color: "white", marginRight: 10 }}
                className={classes.SignUpTextfield}
              />
            </Grid>
            <Grid md={5} xs={12} sm={4} className={classes.ButtonSignUpDiv}>
              <Button variant="contained" color="primary" className={classes.ButtonSignUpDashboard} onClick={() => history.push("/sign-up/creator")}>
                Sign-Up
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/*Footer--------------------------------------------------------------------- */}
        <Footer />
        {/*Footer--------------------------------------------------------------------- */}
      </Grid>
    </div>
  );
}
