import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import Routes from "./Routes";
const breakpoints = createBreakpoints({});
const themes = createTheme({
  overrides: {
    palette: {
      [breakpoints.down("md")]: {
        type: "dark",
      },
    },
  },
});
const theme = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontFamily: ["Manrope"].join(","),
  },
  overrides: {
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        backgroundColor: "blue",
        width: 250,
        color: "black",
        fontSize: 13,
      },
    },
  },
});

const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    background: "linear-gradient(14deg, rgba(20,28,34,1) 0%, rgba(34,49,60,1) 64%, rgba(0,94,113,1) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
});

export default function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}
