import { createSlice } from "@reduxjs/toolkit";

const authe = createSlice({
  name: "Auth/Token",
  initialState: {
    Authe: null,
    Token: null,
  },
  reducers: {
    importAuthe(state, action) {
      state.Authe = action.payload;
    },
    importToken(state, action) {
      state.Token = action.payload;
    },
  },
});

export const autheActions = authe.actions;
export default authe;
