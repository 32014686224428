import { createSlice } from "@reduxjs/toolkit";

const clientDetails = createSlice({
  name: "clientDetails",
  initialState: {
    Client: null,
    Header: null,
  },
  reducers: {
    setClient(state, action) {
      state.Client = action.payload;
    },
    setHeader(state, action) {
      state.Header = action.payload;
    },
  },
});

export const clientDetailsActions = clientDetails.actions;
export default clientDetails;
