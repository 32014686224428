import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Grid, Button, Typography, FormControl, Link } from "@material-ui/core";
import history from "../history";
import ToolbarSignInCreator from "../Toolbar/ToolbarSignInCreator";
import Ellipse1 from "../../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../../Landingpage/img/CreatorEllipse2.png";
import { useStyles } from "../../Landingpage/CreatorLogin/CreatorLoginStyle/CreatorLoginStyle";
import { login, sendOTP } from "./../../ApiComponent/getApiFunction";
import NotificationSnackbar from "../../AppComponents/Notification/Snackbar";

const SigInCreatorCode = (props) => {
  const classes = useStyles();
  const sizeNotif = "100%";
  const durationNotif = 3000;
  const email = localStorage.getItem("Email") ? localStorage.getItem("Email") : "";
  const Id = localStorage.getItem("Id") ? localStorage.getItem("Id") : "";
  const [isDisable, setIsDisable] = useState(false);
  const [msgNotif, setMsgNotif] = useState("");
  const [errTypeNotif, setErrTypeNotif] = useState("info");
  const [openNotif, setOpenNotif] = useState(false);
  const [hashCode, setHashCode] = useState("");
  useEffect(() => {
    addEventKeypress();
    const containerOTP = document.getElementsByClassName("MuiGrid-root OTPCodeInputContainer")[0].firstElementChild;
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
    containerOTP.removeChild(containerOTP.childNodes[6]);
  }, []);

  const getHashCodeInput = (hashCodeInput) => {
    hashCodeInput = hashCodeInput.replace(/\D/g, "");
    setHashCode(hashCodeInput);
  };

  const closeNotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif(false);
  };

  const addEventKeypress = () => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        btnSignIn();
      }
    };
    document.addEventListener("keydown", listener);
  };
  const btnSignIn = async () => {
    const userHashCode = hashCode;
    const digits = userHashCode.toString().split("");
    let hashDigits = "";
    for (let i = 0; i < digits.length; i++) {
      hashDigits = hashDigits.concat(`${digits[i]}-`);
    }

    if (hashDigits.length !== 12) {
      setMsgNotif("Please type 6 OTP codes!");
      setErrTypeNotif("info");
      setOpenNotif(true);
      return false;
    }

    const params = {
      Email: email,
      Password: "Empty",
      Hash: hashDigits.slice(0, -1),
    };

    setIsDisable(true);
    const resLogin = await login(params);
    setIsDisable(false);

    if (resLogin?.status !== 201) {
      setMsgNotif("Invalid Confirmation Key!");
      setErrTypeNotif("error");
      setOpenNotif(true);
      return false;
    }

    const userLoginData = resLogin.data.value.split("#");
    const token = userLoginData?.[1];
    const auth = userLoginData?.[2];
    if (!token || !auth) {
      setMsgNotif("Please try again, unable to login!");
      setErrTypeNotif("info");
      setOpenNotif(true);
      return false;
    }

    localStorage.setItem("userType", "creator");
    localStorage.setItem("token", token);
    localStorage.setItem("Auth", auth);
    localStorage.setItem("userData", resLogin.data.value);
    history.push({
      pathname: "/creator/dashboard",
    });
  };

  const resendOTPCode = async () => {
    const params = {
      Email: email,
      Id,
      Language: "en",
      New: false,
      Package: null,
    };

    const resOTP = await sendOTP(params);
    if (resOTP?.status !== 200) {
      setMsgNotif("Try again, unable to send OTP!");
      setErrTypeNotif("error");
      setOpenNotif(true);
      return false;
    }

    setMsgNotif("Confirmation token successfully resend!");
    setErrTypeNotif("success");
    setOpenNotif(true);

    return false;
  };

  return (
    <div className={classes.container}>
      <Grid className={classes.Ellipse1Div}>
        <img src={Ellipse1} className={classes.Ellipse1} alt="" />
      </Grid>
      <Grid className={classes.Ellipse2Div}>
        <img src={Ellipse2} className={classes.Ellipse2} alt="" />
      </Grid>
      <ToolbarSignInCreator />
      <Grid container>
        <Grid container justifyContent="center">
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h5">
              <h3>Sign In</h3>
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="subtitle1" className={classes.TextTwoFont}>
              Please check your inbox and enter the token below.
            </Typography>
          </Grid>
          <Grid className={classes.TextfieldSign}>
            <FormControl fullWidth variant="filled">
              <Grid container>
                <Grid xs={12} className="OTPCodeInputContainer">
                  <OtpInput
                    id="OtpInputs"
                    value={hashCode}
                    onChange={(e) => getHashCodeInput(e)}
                    numInputs={11}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: "1px solid #f5f5f5",
                      borderRadius: "8px",
                      width: "35px",
                      height: "45px",
                      fontSize: "15px",
                      color: "#fff",
                      fontWeight: "400",
                      caretColor: "blue",
                      background: "transparent",
                      marginLeft: "12px",
                    }}
                    focusStyle={{ border: "1px solid #CFD3DB", outline: "none" }}
                  />
                </Grid>
              </Grid>
              <Button variant="outlined" size="large" className={classes.ButtonSignin} onClick={btnSignIn} disabled={isDisable}>
                Sign In
              </Button>
              <Grid xs={12} className={classes.TextTwo}>
                <Typography variant="subtitle1" className={classes.TextTwoFont}>
                  You didn’t received the token? <Link onClick={resendOTPCode}>Resend token</Link>
                </Typography>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>

        <NotificationSnackbar openNotif={openNotif} closeNotif={closeNotif} errTypeNotif={errTypeNotif} msgNotif={msgNotif} sizeNotif={sizeNotif} durationNotif={durationNotif} />
      </Grid>
    </div>
  );
};
export default SigInCreatorCode;
