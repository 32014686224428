import React, { useState, useEffect } from "react";
import { Drawer as MUIDrawer, List, Divider, Grid, Typography, Link, Modal, Box, makeStyles } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import Avatar from "@material-ui/core/Avatar";
import { withRouter } from "react-router-dom";
import avatar1 from "../img/avatar1.jpg";
import VaipAi from "../../Landingpage/img/vaipAi.png";
import history from "../../Landingpage/history";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
//=============import Icons=============================
import { RiFilterLine } from "react-icons/ri";
import { RiCloseLine } from "react-icons/ri";
import { FaPhotoVideo } from "react-icons/fa";
import { RiLogoutBoxLine } from "react-icons/ri";
import { CgClose } from "react-icons/cg";
import { IoStorefrontOutline } from "react-icons/io5";
import { logout } from "../../ApiComponent/getApiFunction";
import "../../Stylesheet/Business/BusinessDrawer.scss";
const drawerWidth = 240;

//Styles---------------------------------------------------
const useStyles = makeStyles((theme) => ({
  logo: {
    height: "21px",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      marginTop: 10,
    },
  },
  appbarTitle: {
    paddingLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#141c22",
    zIndex: "0",
    border: "none !important",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: theme.spacing(0),
      left: theme.spacing(0),
      width: "100%",
      height: "60px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  list: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  listDiv: {
    margin: "10px 0",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  listContainer: {
    width: "100%",
    margin: "40px 0",
  },
  listDivider: {
    background: "#0a0e11",
    height: "2px",
  },
  filterIcon: {
    display: "none",
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      position: "absolute",
      top: theme.spacing(1.5),
      right: theme.spacing(-15),
    },
    [theme.breakpoints.down("321")]: {
      top: theme.spacing(1.5),
      right: theme.spacing(-12),
    },
    [theme.breakpoints.down("281")]: {
      top: theme.spacing(1.5),
      right: theme.spacing(-10),
    },
    [theme.breakpoints.up("760")]: {
      display: "none !important",
    },
  },
  filterNavbar: {
    width: "100%",
    height: "100%",
    display: "none",
  },
  filterNavbarMenu: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
    marginTop: "7px",
    padding: "0 15px",
  },
  filterCloseButton: {
    fontSize: "40px",
    color: "#7fc94f",
  },
  filterTitle: {
    fontSize: "20px",
  },
  filterApply: {
    fontSize: "20px",
    color: "#7fc94f",
  },
  navMenu: {
    color: "white",
    width: "100%",
    padding: "15px 0",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  navMenuActive: {
    background: "#0a0e11",
    color: "#7fc94f",
    borderLeft: "6px solid #7fc94f",
  },
  menuIconDrawer: {
    margin: "10px",
    width: "25px",
    height: "25px",
  },
  menuIconSignOut: {
    margin: "10px",
    width: "20px",
  },
  signOutDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
  },
  logoutModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logoutModalContainer: {
    background: "#2A3135",
    borderRadius: "7px",
  },
  logoutModalHeader: {
    background: "#141D22",
    padding: "10px 15px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    outline: "none",
  },
  modalCloseDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  modalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    background: "#2A3135",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutModalContent: {
    padding: "20px 40px",
  },
  logoutModalButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  logoutModalButtons: {
    background: "#7fc94f !important",
    margin: "0 10px !important",
    color: "white !important",
  },
}));

function videoFilterToggle() {
  var VideoFilterDiv = document.getElementById("VideoFilter");
  var VideoSpeedDial = document.getElementById("VideoSpeedDial");
  var VideoFooterNavbar = document.getElementById("VideoFooterNavBar");
  var VideoContent = document.getElementById("VideoContent");
  var VideoLogo = document.getElementById("VideoNavbarLogo");
  var VideoFilterButton = document.getElementById("VideoFilterButton");
  var FilterNavbar = document.getElementById("FilterNavbar");

  VideoFilterDiv.style.display = "block";
  VideoSpeedDial.style.display = "none";
  VideoFooterNavbar.style.display = "none";
  VideoContent.style.display = "none";
  VideoLogo.style.display = "none";
  VideoFilterButton.style.display = "none";
  FilterNavbar.style.display = "block";
}

function videoFilterToggleClose() {
  var VideoFilterDiv = document.getElementById("VideoFilter");
  var VideoSpeedDial = document.getElementById("VideoSpeedDial");
  var VideoFooterNavbar = document.getElementById("VideoFooterNavBar");
  var VideoContent = document.getElementById("VideoContent");
  var VideoLogo = document.getElementById("VideoNavbarLogo");
  var VideoFilterButton = document.getElementById("VideoFilterButton");
  var FilterNavbar = document.getElementById("FilterNavbar");

  VideoFilterDiv.style.display = "none";
  VideoSpeedDial.style.display = "block";
  VideoFooterNavbar.style.display = "block";
  VideoContent.style.display = "block";
  VideoLogo.style.display = "block";
  VideoFilterButton.style.display = "block";
  FilterNavbar.style.display = "none";
}

const BusinessDrawer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signout = () => {
    logout().then(() => {
      localStorage.clear();
      history.push("/business");
    });
  };
  //List
  const itemsList = [
    {
      text: "My Video",
      icon: <DashboardIcon style={{ color: "white" }} />,
      onClick: () => history.push("/business/dashboard"),
    },
  ];
  const itemsList2 = [
    {
      text: "Settings",
      icon: <SettingsIcon style={{ color: "white" }} />,
      onClick: () => history.push("/"),
    },
    {
      text: "Profile",
      secondarytext: "Basic.Upgrade?",
      icon: <Avatar alt="Remy Sharp" image={avatar1} className={classes.small} />,
      onClick: () => history.push("/"),
    },
  ];
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdnjs.cloudflare.com/ajax/libs/RecordRTC/5.5.5/RecordRTC.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [openLogout, setOpenLogout] = React.useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  //TEsting for RTC
  const test = () => {};

  return (
    <MUIDrawer variant="permanent" className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
      <Grid xs={12} className={classes.filterNavbar} id="FilterNavbar">
        <Grid className={classes.filterNavbarMenu}>
          <RiCloseLine className={classes.filterCloseButton} onClick={videoFilterToggleClose} />
          <Typography className={classes.filterTitle}>Filters</Typography>
          <Typography className={classes.filterApply} onClick={videoFilterToggleClose}>
            Apply
          </Typography>
        </Grid>
      </Grid>
      <List className={classes.list}>
        <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
          <img src={VaipAi} className={classes.logo} id="VideoNavbarLogo" />
        </h1>
        <RiFilterLine className={classes.filterIcon} onClick={videoFilterToggle} id="VideoFilterButton" />
        <Grid className={classes.listContainer}>
          <NavLink exact to="/business/dashboard" className={classes.navMenu} activeClassName={classes.navMenuActive}>
            <DashboardIcon className={classes.menuIconDrawer} />
            <Typography style={{ color: "white" }}>Dashboard</Typography>
          </NavLink>
          <NavLink exact to="/business/video" className={classes.navMenu} activeClassName="navMenuActiveBusiness">
            {/* <FaPhotoVideo className={classes.menuIconDrawer} /> */}
            <div className="videoLogo">
              <svg className="videoLogoIcon" width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1V4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5C12.7348 5 12.4804 4.89464 12.2929 4.70711C12.1054 4.51957 12 4.26522 12 4V2H2V12H4C4.26522 12 4.51957 12.1054 4.70711 12.2929C4.89464 12.4804 5 12.7348 5 13C5 13.2652 4.89464 13.5196 4.70711 13.7071C4.51957 13.8946 4.26522 14 4 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1ZM8 18V16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16V19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H16C15.7348 6 15.4804 6.10536 15.2929 6.29289C15.1054 6.48043 15 6.73478 15 7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8H18V18H8ZM6 13V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13ZM8 12H12V8H8V12Z" fill="white"/>
              </svg>
            </div>
            <div className="videoLogoActive">
              <svg className="videoLogoActiveIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1V4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5C12.7348 5 12.4804 4.89464 12.2929 4.70711C12.1054 4.51957 12 4.26522 12 4V2H2V12H4C4.26522 12 4.51957 12.1054 4.70711 12.2929C4.89464 12.4804 5 12.7348 5 13C5 13.2652 4.89464 13.5196 4.70711 13.7071C4.51957 13.8946 4.26522 14 4 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1ZM8 18V16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16V19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H16C15.7348 6 15.4804 6.10536 15.2929 6.29289C15.1054 6.48043 15 6.73478 15 7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8H18V18H8ZM6 13V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13ZM8 12H12V8H8V12Z" fill="#7fc94f"/>
              </svg>
            </div>
            <Typography style={{ color: "white" }}>My Videos</Typography>
          </NavLink>
          <NavLink exact to="/student/marketplace" className={classes.navMenu} activeClassName={classes.navMenuActive}>
            <IoStorefrontOutline className={classes.menuIconDrawer} />
            <Typography style={{ color: "white" }}>Marketplace</Typography>
          </NavLink>
        </Grid>
      </List>
      <Divider className={classes.listDivider} />
      <List className={classes.list}>
        <Grid className={classes.listContainer}>
          <NavLink exact to="/business/settings" className={classes.navMenu} activeClassName={classes.navMenuActive}>
            <SettingsIcon className={classes.menuIconDrawer} />
            <Typography style={{ color: "white" }}>Settings</Typography>
          </NavLink>
          <NavLink exact to="/" className={classes.navMenu} activeClassName={classes.navMenuActive}>
            <Avatar className={classes.menuIconDrawer} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>Profile</p>
              <p style={{ fontSize: "11px", color: "#999B9D" }}>
                Basic.<span style={{ color: "#7fc94f" }}>Upgrade?</span>
              </p>
            </div>
          </NavLink>
          <Link variant="outlined" className={classes.signOutDiv} onClick={handleOpenLogout}>
            <RiLogoutBoxLine className={classes.menuIconDrawer} /> Sign Out
          </Link>
          <Modal open={openLogout} onClose={handleCloseLogout} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={classes.logoutModal}>
            <Box className={classes.logoutModalContainer}>
              <Grid className={classes.logoutModalHeader}>
                <Grid className={classes.modalCloseDiv}>
                  <Grid className={classes.modalCloseButtonDiv}>
                    <CgClose onClick={handleCloseLogout} />
                  </Grid>
                </Grid>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Sign Out
                </Typography>
              </Grid>
              <Grid className={classes.logoutModalContent}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Are you sure you want to sign out?
                </Typography>
                <Grid className={classes.logoutModalButtonDiv}>
                  <Button onClick={handleCloseLogout} className={classes.logoutModalButtons}>
                    <typography>Cancel</typography>
                  </Button>
                  <Button onClick={signout} className={classes.logoutModalButtons}>
                    <typography>Yes</typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </List>
      <Divider className={classes.listDivider} />
      <div></div>
    </MUIDrawer>
  );
};

export default withRouter(BusinessDrawer);
