import { useState } from "react";
import { Grid, Card, CardMedia, Chip } from "@material-ui/core";
import history from "./../../Landingpage/history";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationSnackbar from "../Notification/Snackbar";
import VideoStyle from "../ImportStyles/VideoStyle";
import moment from "moment";

export default function Gallery(props) {
  const { userType = "", videos } = props;

  const classes = VideoStyle(userType);

  const durationNotif = 3000;
  const [msgNotif, setMsgNotif] = useState("");
  const [sizeNotif, setSizeNotif] = useState("");
  const [errTypeNotif, setErrTypeNotif] = useState("info");
  const [openNotif, setOpenNotif] = useState(false);
  const [hoverImgId, setImgId] = useState("");

  const closeNotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif(false);
  };

  const getSelectedVideo = (e, id) => {
    const dataIndex = parseInt(e.currentTarget.attributes["dataIndex"].value);

    if (dataIndex === 0) {
      setMsgNotif("We are currently processing the video. We will make it shortly available for you. You can continue to upload more files.");
      setErrTypeNotif("info");
      setSizeNotif("50%");
      setOpenNotif(true);
    } else if (dataIndex < 0) {
      setMsgNotif("Unable to open error video!");
      setErrTypeNotif("error");
      setSizeNotif("100%");
      setOpenNotif(true);
    } else {
      localStorage.setItem("vidId", id);
      history.push(`/${userType}/videodetails/${id}`);
    }
  };

  const hoverEffect = (e, id) => {
    setImgId(id);
  };

  const hoverEffectEnd = () => {
    setImgId("");
  };

  if (!videos) {
    return (
      <div xs={12} className={classes.loadingDesign}>
        <CircularProgress className={classes.loadingSvg} />
      </div>
    );
  }

  console.log(videos);
  return (
    <>
      {videos !== null &&
        videos.map((data) => (
          <Grid item xs={6} md={3} key={data.Id}>
            <Card className={classes.CardRow3} onMouseEnter={(e) => hoverEffect(e, data.Id)} onMouseLeave={hoverEffectEnd}>
              {data.Index === 0 ? (
                <Chip label="Processing..." className={classes.textFloat} size="small" />
              ) : moment(data.Date).format(" YYYY-MM-DD") === moment(new Date()).local().format(" YYYY-MM-DD") && data.Index > 0 ? (
                <Chip label="New" className={classes.textFloat} style={{ backgroundColor: "green" }} size="small" />
              ) : data.Index < 0 ? (
                <Chip label="Error" className={classes.textFloat} style={{ backgroundColor: "red" }} size="small" />
              ) : (
                <></>
              )}

              <CardMedia className={classes.media} image={data.Thumbnail} />
              {hoverImgId === data.Id && (
                <Grid className={classes.videoOverlay} dataIndex={data.Index} onClick={(e) => getSelectedVideo(e, data.Id)}>
                  <Grid className={classes.videoOverlayTitle}>{data.Title}</Grid>
                </Grid>
              )}
            </Card>
          </Grid>
        ))}
      <NotificationSnackbar openNotif={openNotif} closeNotif={closeNotif} errTypeNotif={errTypeNotif} msgNotif={msgNotif} sizeNotif={sizeNotif} durationNotif={durationNotif} />
    </>
  );
}
