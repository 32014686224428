import React from "react";
import { makeStyles, Grid, Typography, Box, Link } from "@material-ui/core";
import {} from "@material-ui/core/styles";
import MadeInGermany from "../Landingpage/img/MadeinGermany.png";
import DSGVO from "../Landingpage/img/DSGVO.png";
import vaipAiFooter from "../Landingpage/img/vaipAiFooter.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
    paddingRight: 50,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      paddingRight: 0,
    },
  },
  //Footer
  Footer: {
    padding: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 10px 20px 20px",
    },
  },
  FooterH4: {
    fontWeight: 500,
    color: "white",
  },
  FooterLink: {
    fontSize: "14px",
    color: "#999B9D",
  },
  sectionFooter: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      justifyContent: "flex-start !important",
    },
  },
}));

export default function Footer(theme) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.Footer}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" className={classes.FooterH4} gutterBottom>
            <img src={vaipAiFooter} className={classes.logoFooter} alt="" />
          </Typography>
          <Box>
            <Link href="/" color="inherit">
              <Typography variant="subtitle1" className={classes.FooterLink}>
                XaaSfarm GmbH
                <br />
                Kistlerhofstraße 70
                <br />
                81379 München
              </Typography>
            </Link>
            <h1>
              <img src={MadeInGermany} className={classes.logo} style={{ marginRight: 20 }} alt="" />
              <img src={DSGVO} className={classes.logo} alt="" />
            </h1>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "center" }} className={classes.sectionFooter}>
          <Typography>
            <Typography variant="h4" className={classes.FooterH4} gutterBottom>
              Company
            </Typography>
            <Box>
              <Link href="https://xaas.farm/vaip-ai/" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink}>
                  About Us
                </Typography>
              </Link>
              <Link href="https://xaasfarm.freshdesk.com/support/home" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink}>
                  FAQ
                </Typography>
              </Link>
              <Link href="https://shipright.community/vaip" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink}>
                  Feauture request
                </Typography>
              </Link>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "center" }} className={classes.sectionFooter}>
          <Typography>
            <Typography variant="h4" className={classes.FooterH4} gutterBottom>
              Legal
            </Typography>
            <Box>
              <Link href="https://xaas.farm/impressum-2" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink} gutterBottom>
                  Impressum
                </Typography>
              </Link>
              <Link href="https://vaip-dev.xaasfarm.com/main/agb" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink} gutterBottom>
                  AGB & Kundeninfo
                </Typography>
              </Link>
              <Link href="https://vaip-dev.xaasfarm.com/main/Widerrufsbelehrung" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink} gutterBottom>
                  Widerrufsbelehrung
                </Typography>
              </Link>
              <Link href="https://vaip-dev.xaasfarm.com/main/dsgvo" color="inherit">
                <Typography variant="subtitle1" className={classes.FooterLink}>
                  Datenschutzerklärung
                </Typography>
              </Link>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
