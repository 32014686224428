import { Card, CardMedia, Grid } from "@material-ui/core";
import { BiCog } from "react-icons/bi";
import LoadingWhileRender from "../../Dashboarad/img/loading-while-rendering-video.gif";
import { useRef } from "react";
import { VideoJS } from "./Video";

const Player = (props) => {
  const { classes, videoData, anchorRefVideoOption, handleToggleVideoOption, isVidAnalyzed } = props;
  const { vidStorageUrl, vidVideoType } = videoData;

  const playerRef = useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    responsive: true,
    fluid: true,
    autoplay: false,
    preload: "metadata",
    controls: true,
    loadingSpinner: true,
    playbackRates: [0.5, 1, 1.5, 2],
    html5: {
      nativeAudioTracks: false,
      nativeTextTracks: false,
    },
    hls: {
      overrideNative: true,
    },
    sources: [
      {
        src: vidStorageUrl,
        type: vidVideoType,
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  const renderPlayer = () => {
    if (!vidStorageUrl) {
      return (
        <div style={{ width: "100%" }}>
          <Grid xs={12}>
            <Card className={classes.CardRow3}>
              <CardMedia className={classes.media} image={LoadingWhileRender} />
            </Card>
          </Grid>
        </div>
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <Grid xs={12} className={classes.videoPlaybackContainer}>
          <Grid className={classes.videoPlaybackDiv}>
            <Grid className={classes.videoOptionsToggle} onClick={handleToggleVideoOption} ref={anchorRefVideoOption}>
              <BiCog className={classes.videoOptionsToggleIcon} />
            </Grid>
          </Grid>
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} videoData={videoData} isVidAnalyzed={isVidAnalyzed} />
        </Grid>
      </div>
    );
  };
  return renderPlayer();
};

export default Player;
