import { makeStyles } from "@material-ui/core/styles";
const theme = makeStyles({
  breakpoints: {
    values: {
      xs: 375,
      sm: 425,
      md: 992,
      lg: 1200,
      xl: 1500,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    backgroundColor: "red",
  },

  list: {
    color: "white",
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(10),
    left: theme.spacing(26),
    zIndex: "1400",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-13),
    },
    [theme.breakpoints.down("376")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-9),
    },
    [theme.breakpoints.down("321")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-3),
    },
    [theme.breakpoints.down("281")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(0),
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  papers: {
    background: "#7fc947",
    border: "2px solid #000",
    borderRadius: 16,
    boxShadow: theme.shadows[5],
    maxHeight: 900,
    maxWidth: 850,
    padding: 30,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  CardIconSize: {
    minWidth: 45,
    minHeight: 45,
    color: "#7fc947",
  },

  card: {
    borderRadius: "10%",

    background: "#141c22",
  },
  ButtonContained: {
    background: "#7fc947",
    color: "white",
  },
  bulkImport: {
    padding: "10px 10px 10px 80px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#141c22",
  },
  importTextfield: {
    background: "transparent",
    color: "white",
    border: "1px solid white",
    borderRadius: "5px",
    outline: "none",
  },
  BulkImportModalCloseButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  papersBulkImport: {
    background: "#2A3135",
    borderRadius: 7,
    maxHeight: 900,
    maxWidth: 850,
    padding: 30,
  },
  BulkImportModalCloseButton: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    background: "#444B50",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    cursor: "pointer",
    marginTop: "-10px",
  },
  BulkImportModalCloseButtonIcon: {
    color: "#E4E7E9",
  },
  speeddialAction: {
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));
export { useStyles };
