import { configureStore } from "@reduxjs/toolkit";
import authe from "./AutheIndex";
import ClientDetails from "./ClientDetails";

const Store = configureStore({
  reducer: {
    authe: authe.reducer,
    ClientDetails: ClientDetails.reducer,
  },
});

export default Store;
