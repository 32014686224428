import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#141D22",
    zIndex: "2",
  },
  FontWhite: {
    color: "white",
  },
  //ToolBar
  appbar: {
    background: "none",
    position: "fix",
  },
  appbarWrapper: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      background: "#141D22",
      width: "100%",
    },
  },
  appbarTitle: {
    flexGrow: "1",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  title: {
    color: "#fff",
    fontSize: "4.5rem",
  },
  logo: {
    height: "30px",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      height: "35px",
      marginTop: "0",
      width: "150px",
    },
  },

  signInButton: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 !important",
      display: "none",
    },
  },

  menuButton: {
    color: "#0098D9",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  CardContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 100,
    flexGrow: 1,
  },
  CardMiddle: {
    background: "#0A0E11",
    color: "#B3B6B8",
    marginBottom: 10,
  },
  CardFirst: {
    background: "#0A0E11",
    color: "#ffff",
    marginBottom: 10,
  },
  CardUpper: {
    marginBottom: 10,
    fontSize: "2rem",
  },
  CardCheck: {
    minHeight: 100,
    background: "#0A0E11",
    color: "#f29600",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginBottom: 10,
  },

  TextOne: {
    marginTop: 20,
    display: "flex",
    color: "white",
    margin: "auto",
    flexGrow: 1,
    textAlign: "center",

    justifyContent: "center",
  },
  TextTwo: {
    marginTop: 10,
    marginBottom: 50,
    display: "flex",
    justifyContent: "center",
    color: "#999B9D",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },

  pricingButton: {
    [theme.breakpoints.only("md")]: {
      minWidth: "130px !important",
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "80px !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  pricingPrice: {
    fontSize: "40px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "20px",
    },
  },
  pricingHeader: {
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px",
      padding: "5px",
    },
  },
  Ellipse1Div: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  Ellipse1: {
    width: "100%",
  },
  Ellipse2Div: {
    position: "absolute",
    top: theme.spacing(20),
    left: theme.spacing(0),
    zIndex: "0",
    width: "50%",
  },
  Ellipse2: {
    width: "50%",
  },
  pricingCardsDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export { useStyles };
