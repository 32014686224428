import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
const themes = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontFamily: "Manrope",
  },
  overrides: {
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        backgroundColor: "white",
        width: 250,
        color: "black",
        fontSize: 13,
      },
      fab: {
        backgroundColor: "#f29600",
        color: "white",
        minWidth: 50,
        minHeight: 50,
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  containers: {
    minHeight: "100vh",
    background: "#0a0e11",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingRight: 50,
    paddingLeft: 50,
    paddingBottom: 50,
    paddingTop: 40,
    [theme.breakpoints.down("xs")]: {
      paddingRight: 30,
      paddingLeft: 30,
      paddingBottom: 100,
      paddingTop: 100,
    },
  },
  Dashboard: {
    color: "white",
    [theme.breakpoints.only("xs")]: {
      fontSize: "20px",
    },
  },
  Dashboard1: {
    marginRight: 100,
  },

  DashboardText1: {
    color: "white",
    display: "flex",
    justifyContent: "right",
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectbox: {
    border: "2px solid #444B50",
  },

  //Card with Images
  CardRow3: {
    position: "relative",
    backgroundColor: "#141C22",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  textFloat: {
    float: "left",
    fontSize: 10,
    backgroundColor: "#99b9d",
    marginTop: 4,
    marginLeft: 4,
  },
  textFloat1: {
    float: "left",
    fontSize: 10,
    color: "white",
    backgroundColor: "green",
    marginTop: 4,
  },
  textFloatBelow: {
    float: "right",
    fontSize: 10,
    backgroundColor: "#99b9d",
  },
  //divider
  divider: {
    backgroundColor: "#2A3135",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 30px 0",
    },
  },
  videosContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  //Dashboard
  //Search bar
  search: {
    width: "0%",
    transition: "width .8s",
  },
  searchExpanded: {
    width: "70%",
    border: "0px",
    transition: "width .8s",
  },
  searchBar: {
    width: "100% !important",
    display: "flex",
    alignItems: "center", //center vertically
  },

  inputView: {
    width: "100%",
    borderBottom: "2px solid #444B50",
  },
  inputBase: {
    marginTop: 10,
    color: "white",
    border: "none",
    borderBottom: "2px solid #444B50",
    width: "100%",
    background: "#0A0E11",
    transition: "width .8s",

    "&:focus": { width: "100%", border: "none", outline: "none" }, //THIS DOESNT WORK
  },
  studentSpeedial: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  studentSpeedialMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  speedialBackshadowDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  speedialBackshadowCurved1: {
    position: "fixed",
    bottom: theme.spacing(17.5),
    left: theme.spacing(26.3),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderBottomRightRadius: "30px",
  },
  speedialBackshadowCurved2: {
    position: "fixed",
    bottom: theme.spacing(18),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadowCurved3: {
    position: "fixed",
    bottom: theme.spacing(5.5),
    left: theme.spacing(26.4),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderTopRightRadius: "30px",
  },
  speedialBackshadowCurved4: {
    position: "fixed",
    bottom: theme.spacing(7),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadow: {
    position: "fixed",
    bottom: theme.spacing(8.4),
    left: theme.spacing(25),
    zIndex: "1000",
    background: "#0a0e11",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },

  videoContainerDiv: {
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },

  formControl: {
    marginTop: 20,
    marginBottom: 60,
    marginLeft: 15,
    minWidth: 200,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 0 20px 0",
    },
  },

  VideoFilterInput: {
    marginRight: "30px",
    [theme.breakpoints.down("md")]: {
      marginRight: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
    },
  },

  inputFilter: {
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  },
  searchBarDivContainer: {
    display: "flex",
    flexDirection: "row",
  },
  searchBarDiv: {
    display: "none",
  },

  searchIcon: {
    fontSize: "35px",
  },
  searchBarClose: {
    fontSize: "25px",
    marginTop: "10px",
    color: "red",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
  titleDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  loadingDesign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "50px",
  },
  loadingSvg: {
    color: "#F29600 !important",
  },
  speedialMobileBackshadowDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  speedialMobileBackshadowCurved1: {
    position: "fixed",
    bottom: theme.spacing(17.5),
    left: theme.spacing(26.3),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderBottomRightRadius: "30px",
  },
  speedialMobileBackshadowCurved2: {
    position: "fixed",
    bottom: theme.spacing(18),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialMobileBackshadowCurved3: {
    position: "fixed",
    bottom: theme.spacing(5.5),
    left: theme.spacing(26.4),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderTopRightRadius: "30px",
  },
  speedialMobileBackshadowCurved4: {
    position: "fixed",
    bottom: theme.spacing(7),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialMobileBackshadow: {
    position: "fixed",
    bottom: theme.spacing(3.4),
    right: theme.spacing(2),
    zIndex: "1000",
    background: "#0a0e11",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
  videoOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(0,0,0,0.5)",
    zIndex: "1",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  videoOverlayTitle: {
    fontSize: "16px",
  },
}));
export { useStyles };
