import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./Landingpage/history";
import business from "./Landingpage/Business";
import Creator from "./Landingpage/Creator";
import Student from "./Landingpage/Student";
import testdesign from "./Landingpage/testdesign";
import Pricing from "./Landingpage/Pricing";
import BusinessPricing from "./Landingpage/BusinessLogIn/BusinessPricing";
import StudentPricing from "./Landingpage/StudentLogIn/StudentPricing";
import SignUpCreator from "./Landingpage/CreatorLogin/SignUpCreator";
import SignUpCodeCreator from "./Landingpage/CreatorLogin/SignUpCodeCreator";
import SignInCreator from "./Landingpage/CreatorLogin/SignInCreator";
import SignInCreatorCode from "./Landingpage/CreatorLogin/SignInCreatorCode";
import CreatorDashboard from "./Dashboarad/Creator/CreatorDashboard";
import CreatorVideo from "./Dashboarad/Creator/CreatorVideo";
import CreatorVideoDetails from "./Dashboarad/Creator/CreatorVideoDetails";
import CreatorSettings from "./Dashboarad/Creator/CreatorSettings";
import SignInStudent from "./Landingpage/StudentLogIn/SignInStudent";
import SignInStudentCode from "./Landingpage/StudentLogIn/SignInStudentCode";
import SignUpStudentCodes from "./Landingpage/StudentLogIn/SignUpStudentCodes";
import SignUpStudent from "./Landingpage/StudentLogIn/SignUpStudent";
import SignInBusiness from "./Landingpage/BusinessLogIn/SignInBusiness";
import SignInBusinessCode from "./Landingpage/BusinessLogIn/SignInBusinessCode";
import SignUpBusiness from "./Landingpage/BusinessLogIn/SignUpBusiness";
import SignUpBusinessCodes from "./Landingpage/BusinessLogIn/SignUpBusinessCodes";
import BusinessDashboard from "./Dashboarad/Business/BusinessDashboard";
import BusinessVideo from "./Dashboarad/Business/BusinessVideo";
import BusinessVideoDetails from "./Dashboarad/Business/BusinessVideoDetails";
import BusinessSettings from "./Dashboarad/Business/BusinessSettings";
import StudentDashboard from "./Dashboarad/Student/StudentDashboard";
import StudentVideo from "./Dashboarad/Student/StudentVideo";
import StudentDrawer from "./Dashboarad/Student/StudentDrawer";
import StudentVideoDetails from "./Dashboarad/Student/StudentVideoDetails";
import StudentSettings from "./Dashboarad/Student/StudentSettings";
import ErrorPage from "./Error-Pages/PageNotFound";
import { isClientLoginIn } from "./ApiComponent/getApiFunction";
const Routes = () => {
  const [isAuth, setAuth] = useState(true);
  const auth = localStorage.getItem("Auth") ?? "";
  const getClient = async () => {
    const res = await isClientLoginIn(auth);
    if (res.status === 200) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };
  useEffect(() => {
    getClient();
  }, []);
  const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
    const [isAuth, setAuth] = useState(true);
    const auth = localStorage.getItem("Auth") ?? "";
    const userRole = localStorage.getItem("userType") ?? "";
    const getClient = async () => {
      const res = await isClientLoginIn(auth);
      if (res.status === 200) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    };
    useEffect(() => {
      getClient();
    }, []);
    if (!userRole) {
      return <Route {...restOfProps} render={(restOfProps) => <Redirect to="/" />} />;
    } else {
    }

    let pathUser = restOfProps.path.split("/")[1];
    if (pathUser === userRole) {
      return <Route {...restOfProps} render={(restOfProps) => (isAuth ? <Component {...restOfProps} /> : <Redirect to="/" />)} />;
    } else {
      const newPath = `/${userRole}/dashboard`;
      return <Route {...restOfProps} render={(restOfProps) => (isAuth ? <Redirect to={newPath} /> : <Redirect to="/" />)} />;
    }
  };
  const PublicRoute = ({ children, ...rest }) => {
    const [isAuth, setAuth] = useState(false);
    const auth = localStorage.getItem("Auth") ?? "";
    const getClient = async () => {
      const res = await isClientLoginIn(auth);
      if (res.status === 200) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    };
    useEffect(() => {
      getClient();
    }, []);
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !isAuth ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: localStorage.getItem("userType") ? `/${localStorage.getItem("userType")}/dashboard` : `/`,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" exact component={Creator} />
        <ProtectedRoute path="/business/dashboard" component={BusinessDashboard} />
        <ProtectedRoute path="/business/video" component={BusinessVideo} />
        <ProtectedRoute path="/business/videodetails/:id" component={BusinessVideoDetails} />
        <ProtectedRoute path="/business/settings" component={BusinessSettings} />
        <PublicRoute path="/sign-in/business" isAuth={isAuth}>
          <SignInBusiness />
        </PublicRoute>
        <PublicRoute path="/sign-up/business" isAuth={isAuth}>
          <SignUpBusiness />
        </PublicRoute>
        <PublicRoute path="/sign-in/businesscode" isAuth={isAuth}>
          <SignInBusinessCode />
        </PublicRoute>
        <PublicRoute path="/sign-up/businesscode" isAuth={isAuth}>
          <SignUpBusinessCodes />
        </PublicRoute>
        <Route path="/business" component={business} />
        <ProtectedRoute path="/creator/video" component={CreatorVideo} />
        <ProtectedRoute path="/creator/dashboard" component={CreatorDashboard} />
        <ProtectedRoute path="/creator/videodetails/:id" component={CreatorVideoDetails} />
        <ProtectedRoute path="/creator/settings" component={CreatorSettings} />
        <PublicRoute path="/sign-up/creator" isAuth={isAuth}>
          <SignUpCreator />
        </PublicRoute>
        <PublicRoute path="/sign-up/creatorcode" isAuth={isAuth}>
          <SignUpCodeCreator />
        </PublicRoute>
        <PublicRoute path="/sign-in/creator" isAuth={isAuth}>
          <SignInCreator />
        </PublicRoute>
        <PublicRoute path="/sign-in/creatorcode" isAuth={isAuth}>
          <SignInCreatorCode />
        </PublicRoute>
        <PublicRoute path="/sign-up/studentcode" isAuth={isAuth}>
          <SignUpStudentCodes />
        </PublicRoute>
        <PublicRoute path="/sign-up/student" isAuth={isAuth}>
          <SignUpStudent />
        </PublicRoute>
        <PublicRoute path="/sign-in/studentcode" isAuth={isAuth}>
          <SignInStudentCode />
        </PublicRoute>
        <PublicRoute path="/sign-in/student" isAuth={isAuth}>
          <SignInStudent />
        </PublicRoute>
        <Route path="/student/dashboard" component={StudentDashboard} />
        <ProtectedRoute path="/student/drawer" component={StudentDrawer} />
        <ProtectedRoute path="/student/video" component={StudentVideo} />
        <ProtectedRoute path="/student/videodetails/:id" component={StudentVideoDetails} />
        <ProtectedRoute path="/student/settings" component={StudentSettings} />
        <Route path="/student" exact component={Student} />
        <Route path="/testdesign" exact component={testdesign} />
        <Route path="/pricingstudent" component={StudentPricing} />
        <Route path="/pricingbusiness" component={BusinessPricing} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/pricingbusiness" component={BusinessPricing} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  );
};
export default Routes;
