import { useState } from "react";
import { useStyles } from "../StudentStyle/StudentVideoStyle";
import { Grid, Card, CardMedia, Chip } from "@material-ui/core";
import history from "../../../Landingpage/history";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";

const GridVideoGallery = (props) => {
  const { videos } = props;

  const classes = useStyles();

  const [validationMessage, setValidationMessage] = useState("");
  const [is200Response, set200Response] = useState("info");
  const [isSnackBar, setSnackBarOpen] = useState(false);
  const [hoverImgId, setImgId] = useState("");

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackBarOpen(false);
  };

  const getSelectedVideo = (e, id) => {
    const dataIndex = parseInt(e.currentTarget.attributes["dataIndex"].value);

    if (dataIndex === 0) {
      setValidationMessage("We are currently processing the video. We will make it shortly available for you. You can continue to upload more files.");
      set200Response("info");
      setSnackBarOpen(true);
    } else if (dataIndex < 0) {
      setValidationMessage("Unable to open an error video!");
      set200Response("error");
      setSnackBarOpen(true);
    } else {
      localStorage.setItem("vidId", id);
      history.push(`/student/videodetails/${id}`);
    }
  };

  const hoverEffect = (e, id) => {
    setImgId(id);
  };

  const hoverEffectEnd = () => {
    setImgId("");
  };

  if (!videos) {
    return (
      <div xs={12} className={classes.loadingDesign}>
        <CircularProgress className={classes.loadingSvg} />
      </div>
    );
  }

  console.log(videos);
  return (
    <>
      {videos !== null &&
        videos.map((data) => (
          <Grid item xs={6} md={3} key={data.Id}>
            <Card className={classes.CardRow3} onMouseEnter={(e) => hoverEffect(e, data.Id)} onMouseLeave={hoverEffectEnd}>
              {data.Index === 0 ? (
                <Chip label="Processing..." className={classes.textFloat} size="small" />
              ) : moment(data.Date).format(" YYYY-MM-DD") === moment(new Date()).local().format(" YYYY-MM-DD") && data.Index > 0 ? (
                <Chip label="New" className={classes.textFloat} style={{ backgroundColor: "green" }} size="small" />
              ) : data.Index < 0 ? (
                <Chip label="Error" className={classes.textFloat} style={{ backgroundColor: "red" }} size="small" />
              ) : (
                <></>
              )}
              <CardMedia className={classes.media} image={data.Thumbnail} />
              {hoverImgId === data.Id && (
                <Grid className={classes.videoOverlay} dataIndex={data.Index} onClick={(e) => getSelectedVideo(e, data.Id)}>
                  <Grid className={classes.videoOverlayTitle}>{data.Title}</Grid>
                </Grid>
              )}
            </Card>
          </Grid>
        ))}

      {/* Notification Snackbar */}
      <Snackbar open={isSnackBar} autoHideDuration={3000} onClose={closeSnackBar} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity={is200Response} variant="filled" onClose={closeSnackBar} sx={{ width: "50%" }}>
          {validationMessage}
        </Alert>
      </Snackbar>
      {/* Notification Snackbar */}
    </>
  );
};

export default GridVideoGallery;
