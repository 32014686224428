import axios from "axios";
const token = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");

export const ApiConfigHeader = () => {
  return axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `JWT ${Auth}`,
      "Access-Control-Allow-Origin": "*",
      token: `JWT ${token}`,
    },
  });
};
export const ApiConfigHeaderV2 = (ptoken, pAuth) => {
  return axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `JWT ${pAuth}`,
      "Access-Control-Allow-Origin": "*",
      token: `JWT ${ptoken}`,
    },
  });
};

export const ApplicationHeader = () => {

  const headerData = {
    auth : localStorage.getItem("Auth")  ?? '',
    token: localStorage.getItem("token") ?? '',
    email: localStorage.getItem("Email") ?? '',
  }; 
  
  return axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `JWT ${headerData.auth}`,
      "Access-Control-Allow-Origin": "*",
      token: `JWT ${headerData.token}`,
    },
  });
};
