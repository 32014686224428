import { IconButton, Typography, Button } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import SearchIcon from "@material-ui/icons/Search";
import { useStyles } from "../StudentStyle/StudentVideoDetailStyle";
import CircularProgress from "@mui/material/CircularProgress";
import { BiShareAlt } from "react-icons/bi";

const VideoSpeechRecognition = (props) => {
  const { videoData, isVidAnalyzed, setOpenLanguage, btnAnalyzeVideoStatus, loadingState, speechToWordRecognition } = props;

  const classes = useStyles();

  const openModal = () => {
    setOpenLanguage(true);
  };

  const renderTimeline = () => {
    if (!videoData.vidId) {
      return (
        <div xs={12} className={classes.loadingDesign}>
          <CircularProgress className={classes.loadingSvg} />
        </div>
      );
    }

    if (!isVidAnalyzed) {
      return (
        <Typography align="center">
          <h1>Start analyzing</h1>
          <Typography align="center" style={{ marginTop: 10 }}>
            Before getting any data you need to start analyzing <br />
            the video. Push the button below.
          </Typography>
          <Button onClick={openModal} disabled={btnAnalyzeVideoStatus} startIcon={<BiShareAlt />} variant="contained" className={classes.ButtonAnalyze}>
            {loadingState.valueState}
          </Button>
        </Typography>
      );
    }

    if (videoData.vidSpeechToWord === null) {
      return (
        <Typography align="center">
          <Button disabled variant="contained">
            Processing...
            <CircularProgress size={14} />
          </Button>
        </Typography>
      );
    } else {
      return <Typography style={{ marginTop: 10 }}>{speechToWordRecognition}</Typography>;
    }
  };

  const renderComponent = () => {
    return (
      <>
        <IconButton aria-label="search" color="inherit">
          <SearchIcon />
        </IconButton>
        <Timeline align="left" className={classes.tabContent}>
          {renderTimeline()}
        </Timeline>
      </>
    );
  };

  return <>{renderComponent()}</>;
};

export default VideoSpeechRecognition;
