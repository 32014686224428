import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Container: {
    background: "radial-gradient(circle, rgba(25,53,172,1) 0%, rgba(9,39,144,1) 100%)",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  Logo: {
    height: "700px",
    [theme.breakpoints.down("sm")]: {
      height: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "350px",
    },
    [theme.breakpoints.down(380)]: {
      height: "320px",
    },
    [theme.breakpoints.down(330)]: {
      height: "250px",
    },
  },
  TextTitle: {
    color: "white",
    fontSize: 24,
    marginTop: "-70px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginTop: "-200px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginTop: "-220px",
    },
  },
  ButtonGoHome: {
    backgroundColor: "#7FC947",
    color: "white",
    width: 180,
    height: 40,
    marginTop: "-70px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-350px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-350px",
    },
  },
}));
export { useStyles };
