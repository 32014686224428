import React, { useEffect, useRef, useState } from "react";
import { TextField, Popover, ClickAwayListener, Card, Button, IconButton, Typography, Box, Grid, FormControl, Divider, Fab } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import SpeedDial from "@material-ui/lab/SpeedDial";
import { useStyles } from "../Business/BusinessStyle/MobileSpeedialBusinessStyle";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { createTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ThemeProvider } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { scrapingoverview } from "../../ApiComponent/getApiFunction";
import { useReactMediaRecorder } from "react-media-recorder";
import "../../Stylesheet/Student/UploadDropzone.scss";
import UploadDropZone from "../Student/UploadDropZone";

//=============import Icons=============================
import { HiUpload } from "react-icons/hi";
import { BiImport } from "react-icons/bi";
import { BiLinkAlt } from "react-icons/bi";
import { CgRecord } from "react-icons/cg";
import { CgRadioChecked } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import { AiFillCamera } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { TiDeleteOutline } from "react-icons/ti";
import { IoStopOutline } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi";
import { GiPadlock } from "react-icons/gi";
import { BsMusicNoteBeamed, BsGearFill, BsRecord2 } from "react-icons/bs";
import { VscDebugStart } from "react-icons/vsc";
// NEW COMPONENT FOR UPLOAD FILE DROPZONE
import { default as VideoUpload } from "../../AppComponents/Video/Upload";

import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export default function SpeedialBusiness(props) {
  const { setDataRefresher, isReload, setIsReload, userType } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);

  const breakpoints = createBreakpoints({
    values: {
      xs: 320,
      sm: 376,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  });

  const themes = createTheme({
    palette: {
      type: "dark",
    },
    typography: {
      fontFamily: "Manrope",
    },
    overrides: {
      MuiSpeedDialAction: {
        staticTooltipLabel: {
          backgroundColor: "white",
          width: 200,
          color: "black",
          fontSize: 13,
        },
        fab: {
          backgroundColor: "#7fc94f",
          color: "white",
          minWidth: 50,
          minHeight: 50,
        },
      },
      MuiPopover: {
        paper: {
          borderRadius: "60px",
          zIndex: "980 !important",
        },
      },
    },
  });
  const SpeedialRecordTheme = createTheme({
    overrides: {
      MuiSpeedDialAction: {
        staticTooltipLabel: {
          backgroundColor: "white",
          color: "black",
          fontSize: 13,
          marginTop: "-20px",
        },
        fab: {
          backgroundColor: "#7fc94f",
          color: "white",
          minWidth: 50,
          minHeight: 50,
          marginLeft: "10px",
        },
      },
      MuiPopover: {
        paper: {
          // borderRadius: "50%",
        },
      },
      MuiSpeedDial: {
        fab: {
          marginLeft: "177px",
          [breakpoints.down("376")]: {
            marginLeft: "140px",
          },
          [breakpoints.down("321")]: {
            marginLeft: "85px",
          },
        },
      },
    },
  });
  const speedialPopoverImport = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          borderRadius: "40px",
          marginLeft: "-68px",
          marginTop: "94px",
          background: "#141c22",
        },
      },
      MuiIconButton: {
        root: {
          color: "white",
        },
      },
      MuiInputBase: {
        input: {
          color: "white",
          borderRadius: "5px",
          border: "1px solid white",
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: "#7fc94f !important",
        },
      },
    },
  });
  const speedialPopoverRecord = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          borderRadius: "40px",
          marginLeft: "-10px",
          marginTop: "100px",

          background: "#141c22",
          [breakpoints.down("376")]: {
            marginLeft: "2px",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "white",
        },
      },
      MuiOutlinedInput: {
        input: {
          color: "white",
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: "#7fc94f !important",
        },
      },
    },
  });
  const speedialPopoverUpload = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          marginLeft: "-68px",
        },
      },
    },
  });
  const speedialPopoverStartRecord = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          borderRadius: "40px",
          marginLeft: "-10px",
          marginTop: "87px",
          background: "#141c22",
          [breakpoints.down("376")]: {
            marginLeft: "2px",
          },
          [breakpoints.down("321")]: {
            marginLeft: "2px",
          },
        },
      },
    },
  });

  //==================================Voice Record Function==================================

  function Preview({ previewStream, audio }) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current && previewStream) ref.current.srcObject = previewStream;
    }, [previewStream]);
    if (!previewStream || audio) return null;
    return <video ref={ref} autoPlay controls width={400} height={300} />;
  }

  function Output({ mediaBlobUrl, audio }) {
    if (audio) return <audio src={mediaBlobUrl} controls autoPlay />;
    return <video src={mediaBlobUrl} controls autoPlay width={400} height={300} />;
  }
  const [mode, setMode] = useState("");

  const handleclickRecord = () => {
    setMode((prevState) => (prevState = "screen"));
  };

  const { status, error, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream } = useReactMediaRecorder({
    screen: mode === "screen",
    askPermissionOnMount: true,
    onStop: (blobUrl, blob) => console.log(blobUrl, blob),
  });

  //==================================Voice Record==================================
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e) => {
      setAudioURL(URL.createObjectURL(e.data));
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecordings = () => {
    setIsRecording(true);
  };

  const stopRecordings = () => {
    setIsRecording(false);
  };

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream);
  }
  //==================================END Voice Record==================================
  //==================================Drag and Drop Upload=====================================
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  var speedialId = document.getElementById("speedial");

  const handleClicks7 = (event) => {
    setAnchorEl7(event.currentTarget);
    speedialId.style.zIndex = "1300";
  };

  const handleClose7 = () => {
    setAnchorEl7(null);
    speedialId.style.zIndex = "1500";
  };

  const opens7 = Boolean(anchorEl7);
  const id7 = opens7 ? "simple-popover" : undefined;
  //==================================Import Link==================================
  const [myValue, setValue] = useState("");
  const [isDisabledImport, setIsDisabledImport] = useState(false);
  const btnImportVideo = async () => {
    if (!myValue) return false;

    setIsDisabledImport(true);
    const params = {
      Name: myValue,
      Url: myValue,
    };

    await scrapingoverview(params);

    setIsDisabledImport(false);
    handleClose();

    if (isReload) {
      setDataRefresher(Math.random());
    } else {
      window.location = "/#/student/video";
    }
  };

  //==================================Popover Import==================================
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  //==================================Popover Import==================================
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClicks2 = (event) => {
    setAnchorEl2(event.currentTarget);
    console.log(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const opens2 = Boolean(anchorEl2);
  const id2 = opens ? "simple-popover" : undefined;
  //==================================Popover Import Link==================================
  const [anchorElImport, setAnchorElImport] = React.useState(null);

  const handleClicksImport = (event) => {
    setAnchorElImport(event.currentTarget);
  };

  const handleCloseImport = () => {
    setAnchorElImport(null);
  };

  const opensImport = Boolean(anchorElImport);
  const idImport = opens ? "simple-popover" : undefined;
  //==================================Record Popover==================================
  const [anchorElRecord, setAnchorElRecord] = useState(null);
  const [speedialRecord, setSpeedialRecord] = useState(false);
  const [speedial, setSpeedial] = useState(true);

  const handleClicksRecord = (event) => {
    setAnchorElRecord(event.currentTarget);
    setSpeedialRecord(true);
  };

  const handleCloseRecord = () => {
    setAnchorElRecord(null);
    setSpeedialRecord(false);
  };

  const opensRecord = Boolean(anchorElRecord);

  //==================================Camera Popover==================================
  const [anchorElCamera, setAnchorElCamera] = React.useState(null);

  const handleClicksCamera = (event) => {
    setAnchorElCamera(event.currentTarget);
  };

  const handleCloseCamera = () => {
    setAnchorElCamera(null);
  };

  const opensCamera = Boolean(anchorElCamera);
  const idCamera = opens ? "simple-popover" : undefined;
  //==================================Sound Popover==================================
  const [anchorElSound, setAnchorElSound] = React.useState(null);

  const handleClicksSound = (event) => {
    setAnchorElSound(event.currentTarget);
  };

  const handleCloseSound = () => {
    setAnchorElSound(null);
  };

  const opensSound = Boolean(anchorElSound);
  const idSound = opens ? "simple-popover" : undefined;
  //==================================Setting Popover==================================
  const [anchorElSetting, setAnchorElSetting] = React.useState(null);

  const handleClicksSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const opensSetting = Boolean(anchorElSetting);
  const idSetting = opens ? "simple-popover" : undefined;
  //==================================Voice Popover==================================
  const [anchorElVoice, setAnchorElVoice] = React.useState(null);

  const handleClicksVoice = (event) => {
    setAnchorElVoice(event.currentTarget);
  };

  const handleCloseVoice = () => {
    setAnchorElVoice(null);
  };

  const opensVoice = Boolean(anchorElVoice);
  const idVoice = opens ? "simple-popover" : undefined;
  //====================================================================
  //==================================Start Record Popover==================================
  const [anchorElStartRecord, setAnchorElStartRecord] = React.useState(null);
  const [speedialStartRecord, setSpeedialStartRecord] = useState(false);

  const handleClicksStartRecord = (event) => {
    setAnchorElStartRecord(event.currentTarget);
    setSpeedialStartRecord(true);
    setAnchorElCamera(null);
    setAnchorElRecord(null);
  };

  const handleCloseStartRecord = () => {
    setAnchorElStartRecord(null);
    setSpeedialRecord(false);
    setSpeedialStartRecord(false);
  };

  const opensStartRecord = Boolean(anchorElStartRecord);
  const idStartRecord = opensStartRecord ? "simple-popover" : undefined;
  //====================================================================
  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  //====================================================================
  const handleVisibilityRecord = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleClickRecord = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAwayRecord = () => {
    setOpen(false);
  };
  //============================Show/Hide =========================================
  const [visibles, setVisibles] = React.useState(false);
  //============================End Show/Hide =========================================
  //============================Show/Hide Speedial =========================================
  const [visibleSpeedial, setVisibleSpeedial] = React.useState(false);
  //============================End Show/Hide =========================================

  //==============================Modal Video Upload ===========================================
  const [valueUpload, setValueModalUpload] = useState(0);
  const handleChangeModalUpload = (event, newValue) => {
    setValueModalUpload(newValue);
  };

  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalUpload = () => {
    setOpenModalUpload(true);
  };
  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };
  //==============================Modal Video Upload ===========================================

  //==============================Modal Edit ===========================================
  const [valueEdit, setValueModalEdit] = useState(0);
  const handleChangeModalEdit = (event, newValue) => {
    setValueModalEdit(newValue);
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };
  //==============================End Modal Edit ===========================================

  //==============================Modal Record Screen ===========================================
  const [valueRecord, setValueModalRecord] = useState(0);
  const handleChangeModalRecord = (event, newValue) => {
    setValueModalRecord(newValue);
  };

  const [openModalRecord, setOpenModalRecord] = useState(false);
  const handleOpenModalRecord = () => {
    setOpenModalRecord(true);
  };
  const handleCloseModalRecord = () => {
    setOpenModalRecord(false);
  };
  //==============================End Modal Record Screen ===========================================

  //==============================Modal Camera ===========================================
  const [valueCamera, setValueModalCamera] = useState(0);
  const handleChangeModalCamera = (event, newValue) => {
    setValueModalCamera(newValue);
  };

  const [openModalCamera, setOpenModalCamera] = useState(false);
  const handleOpenModalCamera = () => {
    setOpenModalCamera(true);
  };
  const handleCloseModalCamera = () => {
    setOpenModalCamera(false);
  };
  //==============================End Modal  Camera ===========================================

  //============================== Import Link ===========================================
  const [openImportLink, setOpenImportLink] = useState(false);
  const handleOpenImportLink = () => setOpenImportLink(true);
  const handleCloseImportLink = () => setOpenImportLink(false);

  //============================== Bulk Import ===========================================
  const [openBulkImport, setOpenBulkImport] = useState(false);
  const handleOpenBulkImport = () => setOpenBulkImport(true);
  const handleCloseBulkImport = () => setOpenBulkImport(false);

  //============================== Input file ===========================================
  const inputFile = useRef(null);

  const fileUpload = () => {
    inputFile.current.click();
  };

  const actions = [
    {
      // icon: <BiImport onClick={handleOpenModalEdit} />,
      icon: <BiImport onClick={handleOpenBulkImport} />,
      name: "Bulk Import",
    },
    {
      icon: <BiLinkAlt onClick={handleOpenImportLink} />,
      name: "Import video from YouTube or Vimeo",
    },
    {
      icon: <CgRecord onClick={handleClicksRecord} />,
      name: "Record your screen / camera",
    },
    {
      icon: (
        <label onClick={handleClicks7}>
          <HiUpload />
        </label>
      ),
      name: "Upload or drag & drop video to platform",
    },
  ];

  //==============================End Speedial ===========================================

  //=========================================================================

  return (
    <div>
      <input type="file" id="file" ref={inputFile} style={{ display: "none" }} />
      {/*==============================Speedial ===========================================   */}
      {speedial && (
        <ThemeProvider theme={themes}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={hidden}
              icon={<SpeedDialIcon />}
              onClick={handleClick}
              open={open}
              color="secondary"
              style={{ marginLeft: 0 }}
              FabProps={{
                size: "large",
                style: { backgroundColor: "#7fc94f", color: "white" },
              }}>
              {actions.map((action) => (
                // {speedialAction && (
                <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} tooltipOpen tooltipPlacement="left" className={classes.speedialContainer} />
                // )}
              ))}
            </SpeedDial>
          </ClickAwayListener>
        </ThemeProvider>
      )}
      {/*==============================End Speedial ===========================================   */}
      {/*==============================Speedial Record===========================================   */}
      {speedialRecord && (
        <ThemeProvider theme={SpeedialRecordTheme}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={hidden}
              direction="right"
              icon={<CgRadioChecked />}
              // onClick={handleClicksRecord}
              open={open}
              color="secondary"
              style={{ marginLeft: 0, zIndex: 1500 }}
              FabProps={{
                size: "large",
                style: { backgroundColor: "#7fc94f", color: "white" },
              }}></SpeedDial>
          </ClickAwayListener>
        </ThemeProvider>
      )}
      {/*==============================Speedial Start Record===========================================   */}
      {speedialStartRecord && (
        <ThemeProvider theme={SpeedialRecordTheme}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={hidden}
              direction="right"
              icon={<IoStopOutline />}
              // onClick={handleClicksStartRecord}
              open={open}
              color="secondary"
              style={{ marginLeft: 0, zIndex: 1500 }}
              FabProps={{
                size: "large",
                style: { backgroundColor: "red", color: "white" },
              }}></SpeedDial>
          </ClickAwayListener>
        </ThemeProvider>
      )}

      {/*==============================Popover Speedial Record===========================================*/}
      <ThemeProvider theme={speedialPopoverRecord}>
        <Popover
          open={opensRecord}
          anchorEl={anchorElRecord}
          onClose={handleCloseRecord}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}>
          <Box
            style={{
              padding: "10px 10px 80px 10px",
              display: "flex",
              flexDirection: "column-reverse",
              justifyContent: "center",
              background: "#141C22",
            }}>
            <IconButton onClick={handleClicksCamera}>
              <AiFillCamera />
            </IconButton>
            <IconButton onClick={handleClicksSound}>
              <BsMusicNoteBeamed />
            </IconButton>
            <IconButton onClick={handleClicksSetting}>
              <BsGearFill />
            </IconButton>

            <IconButton onClick={handleCloseRecord}>
              <TiDelete style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Popover>
      </ThemeProvider>
      {/*========================================================================End Speedial Record=======================================================================================*/}
      {/*============================== Import Link Modal===========================================*/}
      <Modal open={openImportLink} onClose={handleCloseImportLink}>
        <Box>
          <Grid className={classes.importLinkContainer}>
            <Grid className={classes.importLinkNavbar}>
              <CgClose className={classes.importLinkCloseIcon} onClick={handleCloseImportLink} />
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Import Link
              </Typography>
            </Grid>

            <Grid className={classes.importLinkContent}>
              <TextField label="Enter Video Link" className={classes.importLinkTextField} variant="outlined" value={myValue} onChange={(e) => setValue(e.target.value)} />
              <Button variant="contained" size="small" placeholder="Enter Video Link" className={classes.importLinkButton} onClick={btnImportVideo}>
                Import
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/*============================== Bulk Import Popover===========================================*/}
      <Modal open={openBulkImport} onClose={handleCloseBulkImport}>
        <Box>
          <Grid className={classes.importLinkContainer}>
            <Grid className={classes.importLinkNavbar}>
              <CgClose className={classes.importLinkCloseIcon} onClick={handleCloseBulkImport} />
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Bulk Import
              </Typography>
            </Grid>

            <Grid className={classes.bulkImportContent}>
              <Typography className={classes.bulkImportText}>By using this tool, you can directly browse through the websites and import videos from there</Typography>
              <Grid xs={12} className={classes.bulkImportCardContainer}>
                <Card className={classes.bulkImportCard}>
                  <HiOutlineDocumentText className={classes.CardIconSize} />
                  <Typography>We DO NOT collect browser history</Typography>
                </Card>
                <Card className={classes.bulkImportCard}>
                  <GiPadlock className={classes.CardIconSize} />
                  <Typography>We DO NOT save your passwords</Typography>
                </Card>
              </Grid>
              <Grid xs={12} className={classes.bulkImportCheckboxDiv}>
                <Checkbox />
                <Typography className={classes.bulkImportText}> Don't show this again </Typography>
              </Grid>
              <Button variant="contained" size="small" className={classes.importLinkButton}>
                I understand
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/*==============================Camera Popover===========================================*/}
      <Popover
        id={idCamera}
        open={opensCamera}
        anchorEl={anchorElCamera}
        onChange={handleCloseCamera}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}>
        <Grid
          container
          style={{
            background: "#141C22",
            width: 280,
          }}>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleClicksStartRecord}>
              Screen
            </MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleOpenModalCamera}>
              Screen and Camera
            </MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }}>FaceTime HD Camera (Display) (05ac:1112)</MenuItem>
          </Grid>
        </Grid>
      </Popover>
      {/*==============================Sounds Popover===========================================*/}
      <Popover
        id={idSound}
        open={opensSound}
        anchorEl={anchorElSound}
        onClose={handleCloseSound}
        anchorOrigin={{
          vertical: "center",
          horizontal: "bottom",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}>
        <Grid
          container
          style={{
            background: "#141C22",
            width: 280,
          }}>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }}>Default - MacBook Pro Microphone (Built-in)</MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleClicksVoice}>
              Display Audio (05ac:1107)
            </MenuItem>
          </Grid>
          <Grid xs={12}>
            <MenuItem style={{ fontSize: "0.8rem" }}>MacBook Pro Microphone (Build-in)</MenuItem>
          </Grid>
        </Grid>
      </Popover>
      {/*==============================Setting Popover===========================================*/}
      <Popover
        id={idSetting}
        open={opensSetting}
        anchorEl={anchorElSetting}
        onClose={handleCloseSetting}
        anchorOrigin={{
          vertical: "center",
          horizontal: "bottom",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}>
        <Grid
          container
          style={{
            background: "#141C22",
            width: 320,
            padding: 10,
          }}>
          <Grid xs={12}>
            <Grid
              container
              style={{
                background: "#141D22",
                padding: 10,
                borderRadius: 16,
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}>
              <Grid xs={8} style={{ paddingLeft: 5, paddingRight: 5 }}>
                <FormControl>
                  <TextField placeholder="Write your note here..." variant="outlined" size="small" />
                </FormControl>
              </Grid>
              <Grid xs={4} style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Button variant="contained" className={classes.ButtonContained}>
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "0.8rem", paddingLeft: 5, paddingRight: 5 }}>
              Average Bitrate(Kbps): 76 Latest Bitrate(Kbps): 108 Packets Lost: 3 Jitter (Secs): 0.00200
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "0.8rem", paddingLeft: 5, paddingRight: 5 }}>
              Round Trip Time (Secs): 0.0310 Source Width x Height: 2560 x 1440 On-going Width x Height: 2560 x 1440 On-going FPS: 17.2
            </Typography>
          </Grid>
        </Grid>
      </Popover>
      {/*==============================Voice Popover===========================================*/}
      <Popover
        id={idVoice}
        open={opensVoice}
        anchorEl={anchorElVoice}
        onClose={handleCloseVoice}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}>
        <Card style={{ padding: 10, width: 320 }}>
          <Grid container>
            <Grid xs={12}>
              <audio src={audioURL} controls />
            </Grid>
            <Grid xs={6} style={{ display: "flex", justifyContent: "center" }}>
              <Button size="small" style={{ background: "#7fc94f" }} onClick={startRecordings} disabled={isRecording}>
                start recording
              </Button>
            </Grid>
            <Grid xs={6} style={{ display: "flex", justifyContent: "center" }}>
              <Button size="small" style={{ background: "#7fc94f" }} onClick={stopRecordings} disabled={!isRecording}>
                {" "}
                stop recording
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Popover>
      {/*============================== Start Record Popover ===========================================*/}
      <ThemeProvider theme={speedialPopoverStartRecord}>
        <Popover
          id={idStartRecord}
          open={opensStartRecord}
          anchorEl={anchorElStartRecord}
          onClose={handleCloseStartRecord}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}>
          <Box
            style={{
              padding: "10px 10px 80px",
              display: "flex",
              justifyContent: "center",
              background: "#141C22",
            }}>
            <IconButton onClick={handleCloseStartRecord}>
              <TiDelete style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Popover>
      </ThemeProvider>
      {/*==============================Upload Popover==========================================*/}
      <ThemeProvider theme={speedialPopoverUpload}>
        <Popover
          id={id7}
          open={opens7}
          anchorEl={anchorEl7}
          onClose={handleClose7}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          style={{ marginTop: -200, marginLeft: 30 }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#141C22",
              border: "3px dashed #f29600",
              width: 300,
              height: 300,
              zIndex: 1500,
            }}>
            <UploadDropZone {...props} />
          </Box>
        </Popover>
      </ThemeProvider>
      {/*===========================================================================================MODAL==================================================================================*/}
      {/*==============================Modal Edit===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalEdit}
        onClose={handleCloseModalEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalEdit}>
          <div className={classes.papers}>
            <Typography variant="h5" style={{ color: "white", marginBottom: 30 }}>
              <h5> Import Assistant</h5>
              <Typography variant="subtitle1" style={{ color: "white", marginBottom: 30 }}>
                By using this tool, you can directly browse through the websites
                <br /> and import videos from there
              </Typography>
            </Typography>
            <Grid
              container
              align="center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 50,
              }}>
              <Grid xs={4} style={{ padding: 10 }}>
                <Card className={classes.card}>
                  <Grid container style={{ padding: 40 }}>
                    <Grid xs={12}>
                      <HiOutlineDocumentText className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12}>
                      <Typography>We DO NOT collect browser history</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid xs={4} style={{ padding: 10 }}>
                <Card className={classes.card}>
                  <Grid container style={{ padding: 40 }}>
                    <Grid xs={12}>
                      <GiPadlock className={classes.CardIconSize} />
                    </Grid>
                    <Grid xs={12}>
                      <Typography>We DO NOT save your passwords</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>

            <Divider />
            <Grid container>
              <Grid xs={4}>
                <FormControlLabel style={{ color: "white" }} control={<Checkbox />} label="Don’t show this again" />
              </Grid>

              <Grid
                xs={8}
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Box mr={2}>
                  <Button variant="outlined">Cancel</Button>
                </Box>
                <Box>
                  <Button variant="contained" className={classes.ButtonContained}>
                    Create Playlist
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Edit===========================================*/}
      {/*==============================Modal Screen Record===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalRecord}
        onClose={handleCloseModalRecord}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalRecord}>
          <div className={classes.papers}>
            <Typography variant="h5" style={{ color: "white", marginBottom: 30 }}>
              <Grid container style={{ display: "flex", justifyContent: "center" }}>
                <Grid>
                  <Button
                    variant="outlined"
                    style={{ background: "#7fc94f" }}
                    onClick={() => {
                      handleclickRecord();
                      startRecording();
                    }}>
                    Start
                  </Button>
                  <Button variant="outlined" style={{ background: "#7fc94f" }} onClick={stopRecording}>
                    Stop
                  </Button>
                  <Button variant="outlined" style={{ background: "#7fc94f" }} onClick={clearBlobUrl}>
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Typography>
            <Grid container>
              <Grid xs={6}>{status === "stopped" && <Output screen={mode === "screen"} mediaBlobUrl={mediaBlobUrl} />}</Grid>
            </Grid>

            <Divider />
            <Grid container>
              <Grid
                xs={12}
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Box mr={2}>
                  <Button variant="outlined">Cancel</Button>
                </Box>
                <Box>
                  <Button variant="contained" className={classes.ButtonContained}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================MOdal Screen Record===========================================*/}

      {/*==============================Modal Camera===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalCamera}
        onClose={handleCloseModalCamera}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalCamera}>
          <div className={classes.papers} style={{ minWidth: 500 }}>
            <Grid container>
              <Grid xs={6}>
                <Typography variant="h5" style={{ marginBottom: 10 }}>
                  <h4>Record</h4>
                </Typography>
              </Grid>
              <Grid xs={6} style={{ display: "flex", justifyContent: "right" }}>
                <TiDeleteOutline fontSize="large" onClick={handleCloseModalUpload} />
              </Grid>
            </Grid>

            <Divider />

            <Grid container>
              <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  style={{
                    width: 500,
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}></Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                xs="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Typography>Record Your Camera</Typography>
              </Grid>

              <Grid
                xs="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}>
                <Fab color="secondary" style={{ background: "#7fc94f" }}>
                  <VscDebugStart />
                </Fab>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: 20 }} />
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: 20,
              }}>
              <Button variant="outlined" style={{ lor: "white", margin: 10 }}>
                Save
              </Button>
              <Button variant="outlined" style={{ color: "white", margin: 10 }}>
                Clear
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Camera===========================================*/}
    </div>
  );
}
