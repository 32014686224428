import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#0A0E11",
      height: "45px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
  },
  containers: {
    minHeight: "100vh",
    background: "#0a0e11",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: "2",
    fab: {
      position: "sticky",
      border: "8px solid #0a0e11",
    },
  },
  speedialBackshadowDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  speedialBackshadowCurved1: {
    position: "fixed",
    bottom: theme.spacing(17.5),
    left: theme.spacing(26.3),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderBottomRightRadius: "30px",
  },
  speedialBackshadowCurved2: {
    position: "fixed",
    bottom: theme.spacing(18),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadowCurved3: {
    position: "fixed",
    bottom: theme.spacing(5.5),
    left: theme.spacing(26.4),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderTopRightRadius: "30px",
  },
  speedialBackshadowCurved4: {
    position: "fixed",
    bottom: theme.spacing(7),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadow: {
    position: "fixed",
    bottom: theme.spacing(8.4),
    left: theme.spacing(25),
    zIndex: "1000",
    background: "#0a0e11",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
  videoDetailsDiv: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  videoDetailsContent: {
    width: "2300px",
    [theme.breakpoints.up("1280")]: {
      marginLeft: "230px",
    },
    [theme.breakpoints.up("1919")]: {
      width: "1600px",
      marginLeft: "200px",
    },
    [theme.breakpoints.up("2000")]: {
      width: "2280px",
    },
  },
  Header: {
    background: "#141c22",
    minHeight: 150,
    borderRadius: "10px",
    marginBottom: "10px",
    padding: "0 15px",
    [theme.breakpoints.down("xl")]: {
      borderRadius: "0px",
    },
  },
  Body1: {
    background: "#0a0e11",
    minHeight: 800,
    paddingRight: "10px ",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  Body2: {
    background: "#141c22",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
      padding: "10px",
    },
  },

  CardRow3: {
    backgroundColor: "#141C22",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  mediaTwo: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  ButtonAnalyze: {
    background: "#0098D9",
    color: "white",
    marginTop: 50,
  },
  ButtonThreeDots: {
    marginRight: 10,
  },
  ButtonShare: {
    background: "#0098D9",
    color: "white",
  },
  ButtonContained: {
    background: "#0098D9",
    color: "white",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
      margin: "10px",
      width: "100%",
    },
  },
  ButtonContainedThumbnail: {
    background: "#0098D9",
    color: "white",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
      margin: "10px",
      width: "93%",
    },
  },
  Share: {
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
    h1: {
      fontSize: "20px",
    },
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  oppositeContent: {
    // TODO: adjust this value accordingly
    flex: 0.01,
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  VideoDiv: {
    display: "flex",
    flexDirection: "column",
  },
  VideoTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "30px 30px 10px 30px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 5px 10px",
    },
  },

  videoHeaderTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "19px",
    },
  },
  videoToggleContainer: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  videoToggleDiv: {
    position: "fixed",
    width: "95%",
    height: "85px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px 10px",
    padding: "15px",
    background: "#141D22",
    borderRadius: "5px",
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    [theme.breakpoints.down("xs")]: {
      fontSize: "19px",
      // display: "block"
    },
  },
  videoToggleImg: {
    width: "800px !important",
    borderRadius: "5px",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  //MODAL

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  papers: {
    background: "#2a3135",
    borderRadius: 7,
    minHeight: 400,
    minWidth: 600,
    [theme.breakpoints.down("xs")]: {
      minHeight: 400,
      minWidth: 0,
      width: "280px !important",
    },
  },
  shareModalHeaderContainer: {
    background: "#141D22",
    borderTopRightRadius: "7px",
    borderTopLeftRadius: "7px",
    padding: "0 15px",
    boxShadow: "none",
  },
  shareModalHeaderDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex",
    padding: "15px",
  },
  shareModalCloseDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  shareModalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    background: "#2a3135",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    cursor: "pointer",
  },
  papersEdit: {
    background: "#2a3135",
    borderRadius: 7,
    padding: 20,
    minWidth: 500,
    [theme.breakpoints.down("xs")]: {
      minWidth: 250,
      padding: 10,
    },
  },
  changeNameTextfield: {
    width: "100%",
  },
  papersCreatePlaylist: {
    background: "#2a3135",
    borderRadius: 7,
    padding: 20,
    minHeight: 300,
    minWidth: 500,
    [theme.breakpoints.down("xs")]: {
      minWidth: 290,
    },
  },
  addPlaylistHeader: {
    color: "white",
    marginBottom: 30,
    display: "flex",
    justifyContent: "space-between",
  },
  playlistModalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    background: "#141D22",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    cursor: "pointer",
    marginTop: "-10px",
  },
  textFloat: {
    float: "Right",
    backgroundColor: "#2A3135",
    marginTop: 4,
  },
  creatorSpeeddial: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  creatorDrawer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  tabContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  addSearchDiv: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  addTagButton: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "120px",
      height: "50px",
      cursor: "pointer",
      background: "transparent",
      outline: "none",
      border: "1px solid #6A6E72",
      borderRadius: "8px",
      fontSize: "18px",
      color: "white",
      padding: "0",
    },
  },
  addVideoTags: {
    background: "#141D22",
    padding: "25px",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 10px 20px 0",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  addVideoTagsDiv: {
    display: "flex",
    flexDirection: "row",
  },
  addVideoTagsModalDiv: {
    display: "flex",
    flexDirection: "column",
  },
  addTagButtonDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addTagIcon: {
    fontSize: "25px",
    margin: "10px 12px 10px 0",
  },
  searchIcon: {
    fontSize: "40px",
    margin: "10px 30px",
  },
  tagContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  addTagContainer: {
    padding: "0 20px",
  },
  tags: {
    background: "#0098D9",
    padding: "8px 12px",
    margin: "10px 8px",
    borderRadius: "5px",
    color: "black",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  tagsDelete: {
    marginTop: "3px",
    cursor: "pointer",
  },
  changeThumbnailHeader: {
    background: "#141D22",
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    padding: "20px",
  },
  changeThumbnailContainer: {
    background: "#2a3135",
    borderRadius: 7,
    minHeight: 400,
    minWidth: 850,
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      width: "300px",
    },
  },
  modalLanguage: {
    background: "#2a3135",
    borderRadius: "7px",
    minWidth: "500px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "300px",
    },
  },
  modalLanguageContainer: {
    borderRadius: "7px",
  },
  languageModalHeader: {
    background: "#141D22",
    padding: "15px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    color: "white",
  },
  languageModalCloseDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  languageModalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    background: "#2a3135",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  languageCloseIcon: {
    fontSize: "25px",
  },
  languageModalContent: {
    padding: "20px 15px",
  },
  languageModalDiv: {
    width: "100%",
  },
  formControl: {
    width: "80%",
  },
  loadingDesign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingBottom: "270px",
  },
  loadingDesignVidDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingTop: "90px",
  },
  loadingSvg: {
    color: "#0098D9 !important",
  },
  videoOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    background: "rgba(0,0,0,0.5)",
    zIndex: "1",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  videoOverlayTitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  savePlaylistPaper: {
    background: "#141D22",
    padding: "0",
  },
  savePlaylistMenu: {
    padding: "0",
    color: "white",
  },
  savePlaylistCreate: {
    color: "#f29600",
    background: "#0A0E11",
    padding: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoPlaybackDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  videoOptionsToggle: {
    position: "absolute",
    background: "#2A3135",
    outline: "none",
    margin: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    borderRadius: "8px",
    cursor: "pointer",
    zIndex: "1500",
  },
  videoOptionsToggleIcon: {
    fontSize: "20px",
  },
  videoOptionsContainer: {
    background: "#141D22",
  },
  videoToggleIcon: {
    color: "#0098D9",
    fontSize: 40,
  },
  VideoHeaderBack: {
    display: "none",
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  VideoHeaderBackText: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  tagDeleteButton: {
    display: "flex",
    padding: "10px",
    justifyContent: "flex-end",
  },
  savePlaylistText: {
    display: "flex",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
  },
  savePlaylistAdd: {
    color: "#0098D9",
    marginRight: "10px",
    cursor: "pointer",
  },
  savePlaylistButton: {
    background: "#0098D9",
    outline: "none",
    border: "none",
    padding: "10px 20px",
    fontSize: "15px",
    fontWeight: "600",
    color: "white",
    borderRadius: "7px",
    cursor: "pointer",
  },
  DeleteVidModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    color: "white",
  },
  DeleteVidModalContainer: {
    background: "#2A3135",
    borderRadius: "7px",
  },
  DeleteVidModalHeader: {
    background: "#141D22",
    padding: "10px 15px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    outline: "none",
  },
  modalCloseDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  modalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    background: "#2A3135",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  DeleteVidModalContent: {
    padding: "20px",
  },
  DeleteVidModalButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  DeleteVidModalButtons: {
    background: "#0098D9 !important",
    margin: "0 10px !important",
    color: "white !important",
  },
  DeleteVidModalButtonCancel: {
    background: "transparent",
    margin: "0 10px !important",
    color: "white !important",
    border: "1px solid #6a6e72",
  },
}));
export { useStyles };
