import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function NotificationSnackbar(props) {

    const { openNotif, closeNotif, errTypeNotif, msgNotif, sizeNotif, durationNotif} = props;

    return (
        <>
            <Snackbar open={openNotif} autoHideDuration={durationNotif} onClose={closeNotif} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                <Alert severity={errTypeNotif} variant="filled" onClose={closeNotif} sx={{ width: sizeNotif }}>
                {msgNotif}
                </Alert>
            </Snackbar>
        </>
    )
}
