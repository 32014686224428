import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Drawer from "../Student/StudentDrawer";
import FooterNavBar from "../FooterNavBar";
import { useStyles } from "../Student/StudentStyle/StudentDashboardStyle";
import CircularProgress from "@mui/material/CircularProgress";
import SpeedialStudent from "../Student/SpeedialStudent";
import MobileSpeedialStudent from "../Student/MobileSpeedialStudent";
import { paymentGraphBar, iViewersGraphBar, RangeStatics, Statics, topVideosGrp } from "../../ApiComponent/getApiFunction";
import Analytic from "../../AppComponents/Dashboard/Analytic";
import Graph from "../../AppComponents/Dashboard/Graph";
import CurveDesign from "../../AppComponents/SideBar/CurveDesign";
import TopVideos from "../../AppComponents/Dashboard/TopVideos";

export default function Dashboard(theme) {
  const [isReload, setIsReload] = useState(false);
  const [dataRefresher, setDataRefresher] = useState("");
  const [callsHits, setCallHits] = useState("");
  const [playbackTime, setPlaybackTime] = useState("");
  const [revenue, setRevenuePrice] = useState("");
  const [count, setImpCount] = useState("");
  const [paymentTrans, setPayment] = useState("");
  const [inViewers, setViewers] = useState("");
  const [labelForPaymentGraph, setLabelForPaymentGraph] = useState([]);
  const [dataForPaymentGraph, setDataForPaymentGraph] = useState([]);
  const [labelForIViewerGraph, setLabelForIViewerGraph] = useState([]);
  const [dataForIViewerGraph, setDataForIViewerGraph] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [topVideosAnalytics, setTopVideosAnalytics] = useState([]);

  const getStatic = async () => {
    const statistic = await Statics();
    if (statistic.status !== 200) return false;
    const { Hits, PlayTime, Price } = statistic.data;
    setCallHits(Hits);
    setPlaybackTime(PlayTime);
    setRevenuePrice(Price);
  };

  const getRangeStatic = async () => {
    const rangeStatistic = await RangeStatics();
    if (rangeStatistic.status !== 200) return false;
    const { Count, PaymentTran, UsersCount } = rangeStatistic.data;
    setImpCount(Count);
    setPayment(PaymentTran);
    setViewers(UsersCount);
  };

  const getTopVideos = async () => {
    const topVideosStatistic = await topVideosGrp();
    if (topVideosStatistic.status !== 200) return false;
    topVideosStatistic.data.map((topVideosData) => {
      setTopVideosAnalytics([...topVideosAnalytics, topVideosData.thumbnail]);
    });
  };

  const paymentGraphRender = {
    labels: labelForPaymentGraph,
    datasets: [
      {
        label: [],
        data: dataForPaymentGraph,
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  const iViewerGraphRender = {
    labels: labelForIViewerGraph,
    datasets: [
      {
        label: [],
        data: dataForIViewerGraph,
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  const paymentTranscGraph = async () => {
    const graph = await paymentGraphBar();
    if (graph.status !== 200) return false;
    let labels = [];
    let data = [];
    graph.data.forEach((element) => {
      labels.push("");
      data.push(element.price);
    });
    setLabelForPaymentGraph(labels);
    setDataForPaymentGraph(data);
    setLoading(false);
  };

  const iViewersGraph = async () => {
    const graph = await iViewersGraphBar();
    if (graph.status !== 200) return false;
    let labels = [];
    let data = [];
    graph.data.forEach((element) => {
      labels.push("");
      data.push(element.countValue);
    });
    setLabelForIViewerGraph(labels);
    setDataForIViewerGraph(data);
    setLoading(false);
  };

  useEffect(() => {
    getStatic();
    getRangeStatic();
    getTopVideos();
    iViewersGraph();
    paymentTranscGraph();
  }, []);

  const classes = useStyles();
  const options = {
    plugins: {
      legend: { display: false },
      scales: {
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
        x: {
          stacked: true,
        },
      },
    },
  };

  return (
    <>
      <div className={classes.container}>
        <Grid className={classes.Speedial}>
          <SpeedialStudent setDataRefresher={setDataRefresher} isReload={isReload} setIsReload={setIsReload} />
          <CurveDesign />
        </Grid>

        <Grid className={classes.SpeedialMobile}>
          <MobileSpeedialStudent />
        </Grid>

        <Grid container className={classes.dashboardDiv}>
          <Grid xs={0} sm={2} md={3} lg={2} style={{ zIndex: 500 }}>
            <Drawer />
          </Grid>

          <Grid xs={12} sm={9} md={9} lg={9} style={{ marginLeft: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <h1 className={classes.Dashboard}>Dashboard</h1>
              </Grid>
              <Grid item sm={6} display="flex" className={classes.DashboardText1}></Grid>

              {isLoading ? (
                <div xs={12} className={classes.loadingDesign}>
                  <CircularProgress className={classes.loadingSvg} />
                </div>
              ) : (
                <>
                  {/*====================Numbers analytics==================== */}
                  <Analytic callsHits={callsHits} playbackTime={playbackTime} revenue={revenue} count={count} classes={classes} />
                  {/*====================Graph analytics==================== */}

                  <Graph paymentGraphRender={paymentGraphRender} iViewerGraphRender={iViewerGraphRender} options={options} inViewers={inViewers} paymentTrans={paymentTrans} classes={classes} />
                  {/*====================Top Videos==================== */}
                  <TopVideos topVideosAnalytics={topVideosAnalytics} />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <FooterNavBar />
      </div>
    </>
  );
}
