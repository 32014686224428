import React, { useState } from "react";
import { makeStyles, Toolbar, Button, Link, Divider, IconButton, Grid, Typography, AppBar } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import { HiMenu } from "react-icons/hi";
import history from "../history";
import VaipAi from "../img/vaipAi.png";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

function MyToggleButton(props) {
  const { color, ...other } = props;
  const classes = useStyles({ color });
  return <ToggleButton classes={classes} {...other} />;
}

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    return {
      color: "#9aa0a5",
      border: "1px solid #9aa0a5",
      borderRadius: "50px",
      fontSize: "0.8rem",
    };
  },
  selected: {
    "&&": {
      backgroundColor: "#7FC94F",
      color: "#fff",
    },
  },
  // Toggle Menu
  ToggleMenu: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    background: "#141D22",
    zIndex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  ToggleMenuContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
  },

  ToggleMenuButton: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },

  ToggleMenuNav: {
    display: "flex",
    justifyContent: "center",
  },

  ToggleSignInMenu: {
    width: "160px",
    height: "50px",
    [theme.breakpoints.down("xs")]: {
      height: "50px",
      width: "100px",
    },
  },

  ToggleSignUpMenu: {
    width: "160px",
    height: "50px",
    background: "#7FC94F",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      height: "50px",
      width: "100px",
    },
  },
  ToggleMenuList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "50px 0",
    fontSize: "15px",
    color: "#999B9D",
  },
  //
  Hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  signUpButtonToolbar: {
    color: "white",
    background: "#7FC94F",

    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      background: "transparent",
      boxShadow: "none",
      color: "#7FC94F",
    },
  },

  appbar: {
    background: "none",
    position: "fix",
  },
  appbarWrapper: {
    width: "80%",
    margin: "0 auto",
    marginBottom: 150,
    [theme.breakpoints.down("xs")]: {
      background: "#141D22",
      width: "100%",
    },
  },
  appbarTitle: {
    flexGrow: "1",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  title: {
    color: "#fff",
    fontSize: "4.5rem",
  },
  signInButtonToolbar: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menuButton: {
    display: "none",
    color: "#7FC94F",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  //===================Logo=========================================
  logo: {
    height: "30px",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      height: "19px",
      marginTop: 3,
      marginLeft: 50,
    },
  },
  logo2: {
    height: "30px",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      height: "19px",
      marginTop: 20,
      marginBottom: 20,
    },
  },
  logo3: {
    height: "30px",
    marginTop: 20,
    marginLeft: 140,
    [theme.breakpoints.down("md")]: {
      marginLeft: 15,
    },
  },

  // =================for scroll mobile view========================
  PricingScroll: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  ButtonSignInScroll: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,

      padding: 0,
    },
  },
  ButtonSignUpScroll: {
    textTransform: "none",
    background: "#7FC94F",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,

      padding: 0,
    },
  },
  Toggle: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      textTransform: "none",
      fontSize: "10px !important",
    },
  },

  // =================for XS mobile view========================
  Pricing: {
    textTransform: "none",
    color: "white",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  ButtonSignIn: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ButtonSignUp: {
    textTransform: "none",
    background: "#0098d9",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      boxShadow: "none",
      color: "#0098d9",
      fontSize: 14,
      padding: 4,
    },
  },
  // =================End for XS mobile view========================
}));
export default function ToolbarSignInBusiness(theme) {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);
  const [color, setColor] = React.useState("primary");
  const handleColor = (e, value) => setColor(value);
  return (
    <div>
      {/* Toggle Menu---------------------------------------------------------------------*/}
      {isOpened ? (
        <Grid xs={12} className={classes.ToggleMenu}>
          <Grid style={{ width: "100%" }}>
            <Grid xs={12} className={classes.ToggleMenuNav}>
              <IoClose style={{ color: "#7FC94F", fontSize: "30px", position: "absolute", top: "0", left: "0" }} onClick={() => setIsOpened(false)} />
              <img src={VaipAi} className={classes.logo2} alt="" />
            </Grid>
            <Divider />
          </Grid>
          <Grid xs={12} style={{ width: "100%", height: "auto" }} className={classes.ToggleMenuContent}>
            <Grid className={classes.ToggleMenuButton}>
              <Button variant="outlined" onClick={() => history.push("/sign-in/business")} className={classes.ToggleSignInMenu}>
                Sign-In
              </Button>
              <Button variant="contained" onClick={() => history.push("/sign-up/business")} className={classes.ToggleSignUpMenu}>
                Sign-Up
              </Button>
            </Grid>
            <Grid xs={12} className={classes.ToggleMenuList}>
              <Grid>
                <Link>
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/")}>
                    Home
                  </Typography>
                </Link>
                <Link>
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/business")}>
                    Business
                  </Typography>
                </Link>
                <Link>
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/student")}>
                    Student
                  </Typography>
                </Link>
                <Link>
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/")}>
                    Creators
                  </Typography>
                </Link>
              </Grid>
              <Typography variant="small">Company</Typography>
              <Grid>
                <Link>
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/pricingbusiness")}>
                    Pricing
                  </Typography>
                </Link>
                <Link href="/">
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                    About us
                  </Typography>
                </Link>
                <Link href="/">
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                    FAQ
                  </Typography>
                </Link>
                <Link href="/">
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                    Feature Request
                  </Typography>
                </Link>
              </Grid>
              <Typography variant="small">Legal</Typography>
              <Grid>
                <Link href="/">
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                    Impressum
                  </Typography>
                </Link>
                <Link href="/">
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                    AGB & Kundeninfo
                  </Typography>
                </Link>
                <Link href="/">
                  <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                    Datenschutzerklärung
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Toolbar className={classes.appbarWrapper}>
        <IconButton edge="start" className={classes.menuButton} color="#7FC94F" aria-label="menu" onClick={() => setIsOpened(true)}>
          <HiMenu />
        </IconButton>
        <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
          <img src={VaipAi} className={classes.logo} alt="" />
        </h1>
        <Button onClick={() => history.push("/pricingbusiness")} className={classes.Pricing} style={{ textTransform: "none" }}>
          Pricing
        </Button>
        <Button variant="outlined" onClick={() => history.push("/sign-in/business")} style={{ marginRight: 20, textTransform: "none" }} className={classes.signInButtonToolbar}>
          Sign-In
        </Button>
        <Button variant="contained" onClick={() => history.push("/sign-up/business")} style={{ textTransform: "none" }} className={classes.signUpButtonToolbar}>
          Sign-Up
        </Button>
      </Toolbar>
      <HideOnScroll>
        <AppBar style={{ backgroundColor: "#141c22" }}>
          <Toolbar className={classes.Hide}>
            <Grid xs={4} style={{ display: "flex", justifyContent: "center" }}>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setIsOpened(true)}>
                <HiMenu />
              </IconButton>
              <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
                <img src={VaipAi} className={classes.logo3} alt="" />
              </h1>
            </Grid>
            <Grid xs={4} style={{ display: "flex", justifyContent: "center" }}>
              <ToggleButtonGroup onChange={handleColor} value={color} exclusive>
                <MyToggleButton value="primary" onClick={() => history.push("/sign-in/business")} className={classes.Toggle}>
                  Business
                </MyToggleButton>
                <MyToggleButton value="2" onClick={() => history.push("/sign-in/student")} className={classes.Toggle}>
                  Student
                </MyToggleButton>
                <MyToggleButton value="1" onClick={() => history.push("/sign-in/creator")} className={classes.Toggle}>
                  Creator
                </MyToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid xs={4} style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={() => history.push("/pricingstudent")} style={{ color: "white", marginRight: 20 }} className={classes.PricingScroll}>
                Pricing
              </Button>
              <Button variant="outlined" style={{ marginRight: 20 }} onClick={() => history.push("/sign-in/business")} className={classes.ButtonSignInScroll}>
                {" "}
                Sign-In
              </Button>
              <Button variant="contained" color="primary" onClick={() => history.push("/sign-up/business")} className={classes.ButtonSignUpScroll}>
                Sign-Up
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  );
}
