import React, { useState } from "react";
import { Grid, Toolbar, Button, CardContent, Typography, Container, Card, CardHeader, Link, Divider, IconButton } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import { HiMenu } from "react-icons/hi";
import Footer from "./Footer";
import history from "./history";
import VaipAi from "../Landingpage/img/vaipAi.png";
import Ellipse1 from "../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../Landingpage/img/CreatorEllipse2.png";

import { FaCheck } from "react-icons/fa";
import { useStyles } from "./LandingpagesStyle/PricingStyle";

export default function Pricing(theme) {
  const classes = useStyles();

  const [isOpened, setIsOpened] = useState(false);
  return (
    <div>
      <Grid container className={classes.container}>
        <Grid className={classes.Ellipse1Div}>
          <img src={Ellipse1} className={classes.Ellipse1} />
        </Grid>
        <Grid className={classes.Ellipse2Div}>
          <img src={Ellipse2} className={classes.Ellipse2} />
        </Grid>

        <Toolbar className={classes.appbarWrapper}>
          <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
            <img src={VaipAi} className={classes.logo} alt="" />
          </h1>
          <Button onClick={() => history.push("/pricing")} style={{ color: "white", marginRight: 20, textTransform: "none" }} className={classes.pricingButtonNav}>
            Pricing
          </Button>
          <Button variant="outlined" onClick={() => history.push("/sign-in/creator")} style={{ marginRight: 20, textTransform: "none" }} className={classes.signInButtonToolbar}>
            Sign-In
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/sign-up/creatorcode")}
            style={{ color: "white", background: "#0098D9", textTransform: "none" }}
            className={classes.ButtonSignUp}>
            Sign-Up
          </Button>
        </Toolbar>

        <Grid container>
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h5">
              <h3>Pricing</h3>
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="h5">An attractive monthly subscription based on storage & AI processing</Typography>
          </Grid>
        </Grid>

        {/* Toggle Menu---------------------------------------------------------------------*/}
        {isOpened ? (
          <Grid xs={12} className={classes.ToggleMenu}>
            <Grid style={{ width: "100%" }}>
              <Grid xs={12} className={classes.ToggleMenuNav}>
                <IoClose style={{ color: "#f29600", fontSize: "30px", position: "absolute", top: "10px", left: "10px" }} onClick={() => setIsOpened(false)} />
                <img src={VaipAi} className={classes.logo} alt="" />
              </Grid>
              <Divider />
            </Grid>
            <Grid xs={12} style={{ width: "100%", height: "auto" }} className={classes.ToggleMenuContent}>
              <Grid className={classes.ToggleMenuButton}>
                <Button variant="outlined" onClick={() => history.push("/sign-in/business")} className={classes.ToggleSignInMenu}>
                  Sign-In
                </Button>
                <Button variant="contained" onClick={() => history.push("/sign-up/business")} className={classes.ToggleSignUpMenu}>
                  Sign-Up
                </Button>
              </Grid>
              <Grid xs={12} className={classes.ToggleMenuList}>
                <Grid>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/")}>
                      Home
                    </Typography>
                  </Link>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/business")}>
                      Business
                    </Typography>
                  </Link>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/student")}>
                      Student
                    </Typography>
                  </Link>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/")}>
                      Creators
                    </Typography>
                  </Link>
                </Grid>
                <Typography variant="small">Company</Typography>
                <Grid>
                  <Link>
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }} onClick={() => history.push("/pricing")}>
                      Pricing
                    </Typography>
                  </Link>
                  <Link href="https://xaas.farm/vaip-ai/" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      About us
                    </Typography>
                  </Link>
                  <Link href="https://xaasfarm.freshdesk.com/support/home" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      FAQ
                    </Typography>
                  </Link>
                  <Link href="https://shipright.community/vaip" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Feature Request
                    </Typography>
                  </Link>
                </Grid>
                <Typography variant="small">Legal</Typography>
                <Grid>
                  <Link href="https://xaas.farm/impressum-2" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Impressum
                    </Typography>
                  </Link>
                  <Link href="https://vaip-dev.xaasfarm.com/main/agb" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      AGB & Kundeninfo
                    </Typography>
                  </Link>
                  <Link href="https://vaip-dev.xaasfarm.com/main/Widerrufsbelehrung" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Widerrufsbelehrung
                    </Typography>
                  </Link>
                  <Link href="https://vaip-dev.xaasfarm.com/main/dsgvo" color="inherit">
                    <Typography variant="h5" style={{ color: "#ffffff", marginBottom: "15px" }}>
                      Datenschutzerklärung
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {/*First Container--------------------------------------------------------------------- */}

        <Grid container style={{ zIndex: "5" }} className={classes.pricingCardsDiv}>
          <Grid xs={11} lg={10} xl={9} container alignItems="flex-end" justifyContent="space-between">
            {/*First Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>Storage</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>Type Account</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>AI Processing</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>10% Fee for Sales and Payments Transactions</Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Second Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    FREE
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#ffff" }} className={classes.pricingPrice}>
                    0 €/m
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#0098D9",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>24h video time</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Single user</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Scheduled</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#0098D9" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Third Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    Basic
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#ffff" }} className={classes.pricingPrice}>
                    10 € / m
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#0098D9",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>1,200h video time</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Single user</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Prioritized</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#0098D9" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Fourth Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardHeader title="RECOMMENDED" style={{ background: "#0098D9" }} titleTypographyProps={{ align: "center", variant: "subtitle1" }} className={classes.pricingHeader} />
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    Team
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#0098D9" }} className={classes.pricingPrice}>
                    50 €/m
                  </Typography>
                  <Button
                    style={{
                      color: "#fff",
                      background: "#0098D9",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>

              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>1,200h video time</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Single user</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Prioritized</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#0098D9" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Fifth Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    Free
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#ffff" }} className={classes.pricingPrice}>
                    3000 €
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#0098D9",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Unlimited</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Team</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Immediate</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#0098D9" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/*Footer--------------------------------------------------------------------- */}
        <Footer />
      </Grid>
    </div>
  );
}
