import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

const theme = makeStyles({
  breakpoints: {
    values: {
      xs: 375,
      sm: 425,
      md: 992,
      lg: 1200,
      xl: 1500,
    },
  },
});

const speeddialActions = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontFamily: "Manrope",
  },
  overrides: {
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        backgroundColor: "white",
        width: 200,
        color: "black",
        fontSize: 13,
      },
      fab: {
        backgroundColor: "#f29600",
        color: "white",
        minWidth: 50,
        minHeight: 50,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "60px",
        zIndex: "980 !important",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  //Speedial

  list: {
    color: "white",
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(10),
    left: theme.spacing(20),
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-18),
    },
    [theme.breakpoints.down("376")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-14),
    },
    [theme.breakpoints.down("321")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-8),
    },
    [theme.breakpoints.down("281")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(-5),
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dragUpload: {
    [theme.breakpoints.down("376")]: {
      minWidth: "0 !important",
    },
  },
  importLinkContainer: {
    width: "100vw ",
    height: "100vh ",
    background: "#0A0E11 ",
  },
  importLinkNavbar: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "15px",
    background: "#141D22",
  },
  importLinkCloseIcon: {
    position: "absolute",
    left: "15px",
    top: "15px",
    fontSize: "30px",
    color: "#f29600",
  },
  importLinkContent: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  importLinkTextField: {
    width: "100%",
  },
  importLinkButton: {
    width: "100%",
    background: "#f29600",
    color: "white",
    margin: "15px 0",
    padding: "10px",
  },
  bulkImportContent: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bulkImportDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  bulkImportCard: {
    background: "#141c22",
    width: "100%",
    borderRadius: "10px",
    padding: "30px 15px",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "20px",
  },
  bulkImportCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "20px 0",
  },
  CardIconSize: {
    minWidth: 45,
    minHeight: 45,
    color: "#f29600",
    margin: "20px 0",
  },
  bulkImportText: {
    fontSize: "20px",
  },
  bulkImportCheckboxDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  recordPopover: {
    position: "relative",
    "& .MuiPopover-paper-4281": {
      margin: "0 !important",
    },
  },
}));
export { useStyles, speeddialActions };
