import { useState } from "react";
import { Grid, Button, Typography, TextField, FormControl, Link } from "@material-ui/core";
import ToolbarSignUpStudent from "../Toolbar/ToolbarSignUpStudent";
import Ellipse1 from "../../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../../Landingpage/img/BusinessEllipse2.png";
import { useStyles } from "../../Landingpage/StudentLogIn/StudentLoginStyle/StudentLoginStyle";
import { userInfo, sendOTP } from "./../../ApiComponent/getApiFunction";
import NotificationSnackbar from "../../AppComponents/Notification/Snackbar";
import history from "../../Landingpage/history";

export default function SignUpStudent(theme) {
  const classes = useStyles();

  const isValidEmail = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const sizeNotif = "100%";
  const durationNotif = 3000;
  const [isDisable, setIsDisable] = useState(false);
  const [msgNotif, setMsgNotif] = useState("");
  const [errTypeNotif, setErrTypeNotif] = useState("info");
  const [openNotif, setOpenNotif] = useState(false);
  const [email, setEmail] = useState("");

  const closeNotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif(false);
  };
  const EnterSignIn = (e) => {
    if (e.key === "Enter") {
      btnApiCall();
    }
  };
  const btnApiCall = async () => {
    if (email === "") {
      setMsgNotif("Please enter your email address!");
      setErrTypeNotif("info");
      setOpenNotif(true);
      return false;
    }

    if (!isValidEmail.test(email)) {
      setMsgNotif("Please enter valid email address!");
      setErrTypeNotif("info");
      setOpenNotif(true);
      return false;
    }

    setIsDisable(true);

    const resUser = await userInfo(email);
    if (resUser?.status !== 200) {
      setMsgNotif("Try again, unable to register email address!");
      setErrTypeNotif("error");
      setOpenNotif(true);
      return false;
    }

    const params = {
      Email: resUser?.data?.Email ?? "",
      Id: resUser?.data?.Id ?? "",
      Language: "en",
      New: false,
      Package: null,
    };

    const resOTP = await sendOTP(params);
    if (resOTP?.status !== 200) {
      setMsgNotif("Try again, unable to send OTP!");
      setErrTypeNotif("error");
      setOpenNotif(true);
      return false;
    }

    localStorage.setItem("CrowlingModel", resUser?.data?.CrowlingModel);
    localStorage.setItem("Email", resUser?.data?.Email);
    localStorage.setItem("Id", resUser?.data?.Id);
    localStorage.setItem("Language", resUser?.data?.Language);
    localStorage.setItem("Package", resUser?.data?.Package);
    localStorage.setItem("Public", resUser?.data?.Public);
    localStorage.setItem("Tour", resUser?.data?.Tour);
    localStorage.setItem("VaipVersion", resUser?.data?.VaipVersion);

    history.push({
      pathname: "/sign-in/studentcode",
      state: { email, data: resUser.data },
    });

    setIsDisable(false);
  };

  return (
    <div className={classes.container}>
      <Grid className={classes.Ellipse1Div}>
        <img src={Ellipse1} alt="" className={classes.Ellipse1} />
      </Grid>
      <Grid className={classes.Ellipse2Div}>
        <img src={Ellipse2} alt="" className={classes.Ellipse2} />
      </Grid>
      <ToolbarSignUpStudent />
      <Grid container>
        <Grid container justifyContent="center">
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h5">
              <h3>Sign Up</h3>
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="subtitle1">Please enter your email address</Typography>
          </Grid>
          <Grid container>
            <Grid xs={12} className="TextFieldContainer">
              <Grid className={classes.TextfieldSign}>
                <FormControl fullWidth variant="filled">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter your email address."
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={(e) => setEmail(e.target.value)}
                    onKeyPress={(e) => {
                      EnterSignIn(e);
                    }}
                  />
                  <Button variant="outlined" size="large" onClick={btnApiCall} disabled={isDisable} className={classes.signUpButton}>
                    Sign Up
                  </Button>
                  <Grid xs={12} className={classes.TextTwo}>
                    <Typography variant="subtitle1" className={classes.TextTwoTitle}>
                      You don’t have an account?{" "}
                      <Link onClick={() => history.push("/sign-up/student")} style={{ color: "#f29600" }}>
                        Create account
                      </Link>
                    </Typography>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <NotificationSnackbar openNotif={openNotif} closeNotif={closeNotif} errTypeNotif={errTypeNotif} msgNotif={msgNotif} sizeNotif={sizeNotif} durationNotif={durationNotif} />
      </Grid>
    </div>
  );
}
