import React from "react";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TimelineIcon from "@material-ui/icons/Timeline";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import TimerIcon from "@material-ui/icons/Timer";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AddIcon from "@material-ui/icons/Add";

export default function Analytic({ classes, callsHits, playbackTime, revenue, count }) {
  return (
    <>
      <Grid item sm={3} xs={12}>
        <Card className={classes.CardRow1}>
          <CardContent className={classes.CardRow1Content}>
            <Grid container>
              <Grid className={classes.CardIcon} xs={2}>
                <TimelineIcon className={classes.CardIconSvg} />
              </Grid>
              <Grid xs={7}>
                <Typography className={classes.title} color="white">
                  Calls
                </Typography>
                <Typography className={classes.pos}>{callsHits}</Typography>
              </Grid>
              <Grid className={classes.CardIconEnd} xs={2}>
                <ArrowForwardIosIcon className={classes.dashboardArrowIcon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={3} xs={12}>
        <Card className={classes.CardRow1}>
          <CardContent className={classes.CardRow1Content2}>
            <Grid container>
              <Grid className={classes.CardIcon} xs={2}>
                <TimerIcon className={classes.CardIconSvg} />
              </Grid>
              <Grid xs={7}>
                <Typography className={classes.title} color="white">
                  Playback Time
                </Typography>
                <Typography className={classes.pos}>{playbackTime}</Typography>
              </Grid>
              <Grid className={classes.CardIconEnd} xs={2}>
                <ArrowForwardIosIcon className={classes.dashboardArrowIcon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={3} xs={12}>
        <Card className={classes.CardRow1}>
          <CardContent className={classes.CardRow1Content}>
            <Grid container>
              <Grid className={classes.CardIcon} xs={2}>
                <LocalOfferIcon className={classes.CardIconSvg} />
              </Grid>
              <Grid xs={7}>
                <Typography className={classes.title} color="white">
                  Revenue
                </Typography>
                <Typography className={classes.pos}>{revenue} €</Typography>
              </Grid>
              <Grid className={classes.CardIconEnd} xs={2}>
                <ArrowForwardIosIcon className={classes.dashboardArrowIcon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={3} xs={12}>
        <Card className={classes.CardRow1}>
          <CardContent className={classes.CardRow1Content}>
            <Grid container>
              <Grid className={classes.CardIcon} xs={2}>
                <AddIcon className={classes.CardIconSvg} />
              </Grid>
              <Grid xs={7}>
                <Typography className={classes.title} color="white">
                  Impression
                </Typography>
                <Typography className={classes.pos}>{count}</Typography>
              </Grid>
              <Grid className={classes.CardIconEnd} xs={2}>
                <ArrowForwardIosIcon className={classes.dashboardArrowIcon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
