import { Grid, CardMedia, IconButton, Paper, Typography, Button } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import VideoDetailStyle from "../ImportStyles/VideoDetailStyle";
import CircularProgress from "@mui/material/CircularProgress";
import { BiShareAlt } from "react-icons/bi";
import { createTheme } from "@material-ui/core/styles";

const timeline = createTheme({
  overrides: {
    MuiTimelineOppositeContent: {
      root: {
        flex: "0.01  !important",
      },
    },
  },
});

export default function TextRecognition(props) {
  const { playDynamicSeconds, videoData, videoRecordData, isVidAnalyzed, setOpenLanguage, btnAnalyzeVideoStatus, loadingState, userType = "" } = props;

  const classes = VideoDetailStyle(userType);

  const openModal = () => {
    setOpenLanguage(true);
  };

  const timeToSeconds = (itemSeconds) => {
    const hms = itemSeconds;
    const [hours, minutes, seconds] = hms.split(":");
    const totalSeconds = +hours * 60 * 60 + +minutes * 60 + +seconds;
    return totalSeconds;
  };

  const renderAnalyzedData = () => {
    if (!videoData.vidId) {
      return (
        <div xs={12} className={classes.loadingDesign}>
          <CircularProgress className={classes.loadingSvg} />
        </div>
      );
    }

    if (!isVidAnalyzed) {
      return (
        <Typography align="center">
          <h1>Start analyzing</h1>
          <Typography align="center" style={{ marginTop: 10 }}>
            Before getting any data you need to start analyzing <br />
            the video. Push the button below.
          </Typography>
          <Button onClick={openModal} disabled={btnAnalyzeVideoStatus} startIcon={<BiShareAlt />} variant="contained" className={classes.ButtonAnalyze}>
            {loadingState.valueState}
          </Button>
        </Typography>
      );
    }

    return videoData?.vidScenes?.map((itemScene) => {
      let indicatorVideo = "none";

      const start = timeToSeconds(itemScene?.Start.split("/")[0]).toFixed();
      const end = timeToSeconds(itemScene?.End.split("/")[0]).toFixed();
      const played = playDynamicSeconds?.playedSeconds.toFixed();

      const startScene = parseInt(itemScene?.Start.split("/")[1]);
      const endScene = parseInt(itemScene?.End.split("/")[1]);

      let wordsList = [];
      videoRecordData.map((result_item) => {
        const position = parseInt(result_item.position.split("/")[0]);
        if (position >= startScene && position <= endScene) {
          wordsList = [...wordsList, ...result_item?.importantWords];
        }
      });

      const new_list = wordsList.reduce((arr, curr) => {
        return {
          ...arr,
          [curr]: "",
        };
      }, []);

      if (start <= played && end >= played) {
        indicatorVideo = "#222628";
      }

      return (
        <ThemeProvider theme={timeline}>
          <TimelineItem style={{ background: indicatorVideo, borderRadius: 16 }}>
            <TimelineOppositeContent className={classes.oppositeContent} color="textSecondary"></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Grid container>
                <Grid xs={4}>
                  <CardMedia className={classes.mediaTwo} image={itemScene?.Image} />
                </Grid>
                <Grid xs={8}>
                  <Grid container spacing={3} style={{ padding: 10 }}>
                    {Object.keys(new_list) &&
                      Object.keys(new_list).map((word) => (
                        <Grid item xs>
                          <Paper className={classes.paper}>{word}</Paper>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </TimelineContent>
          </TimelineItem>
        </ThemeProvider>
      );
    });
  };

  return (
    <>
      <IconButton aria-label="search" color="inherit">
        <SearchIcon />
      </IconButton>
      <Timeline align="left" className={classes.tabContent}>
        {renderAnalyzedData()}
      </Timeline>
    </>
  );
}
