import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { setUptimeString } from "./utils";
import mongoid from "mongoid-js";
import { UploadVideoToMimIo, UploadVideo } from "../../../ApiComponent/getApiFunction";
import "../../../Stylesheet/Student/UploadDropzone.scss";

const UploadDropZone = (props) => {
  let { setDataRefresher, isReload, setIsReload } = props;

  const [fileName, setFileName] = useState("");
  const [reloadConter, setReloadConter] = useState(0);

  const getUploadParams = async ({ file }) => {
    const length = file.name.lastIndexOf(".");
    const ending = file.name.substring(length + 1);
    const fileName = `${mongoid()}.${ending}`;
    const fileOrgName = file.name;

    const obj = {
      FileName: fileName,
    };
    setFileName(fileName);

    const response = await UploadVideoToMimIo(obj);

    if (!response?.data?.value) return;

    const fileUrl = response.data.value;
    const uploadUrl = response.data.value;

    return {
      body: file,
      meta: { fileName, fileOrgName, fileUrl },
      method: "put",
      url: uploadUrl,
    };
  };

  const handleChangeStatus = async ({ meta }, status, files) => {
    if (status === "headers_received") {
      const hours = Math.floor(meta.duration / 3600);
      const minutes = Math.floor((meta.duration - hours * 3600) / 60);
      const seconds = Math.round(meta.duration - hours * 3600 - minutes * 60);
      const durationTimeString = setUptimeString(hours, minutes, seconds);

      const obj = {
        FileName: fileName,
        FileSize: meta.size,
        FileType: `/vaipinput/${meta.fileName}`,
        UploadType: "manual",
        StatusUpload: "uploaded",
        videoDuration: durationTimeString,
      };

      await UploadVideo(obj);

      files.forEach((f) => f.remove());

      if (isReload) {
        setDataRefresher(Math.random());
      } else {
        window.location = "/#/student/video";
      }
    }
  };

  return <Dropzone getUploadParams={getUploadParams} onChangeStatus={handleChangeStatus} maxFiles={20} accept="video/*" multiple />;
};

export default UploadDropZone;
