import React, { useState } from "react";
import { Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import { useStyles } from "../../Dashboarad/Student/StudentStyle/StudentVideoDetailStyle";
import { FaTrashAlt } from "react-icons/fa";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { removePlaylist, insertVideoInPlaylist, removeVideoInPlaylist } from "../../ApiComponent/getApiFunction";

export default function Playlist({
  handleCloseMenuPlaylist,
  openMenuPlaylist,
  handleListKeyDown,
  playlistAlbum,
  handleOpenModalCNP,
  videoId,
  setPlayListRefresher,
  setMsgNotif,
  setErrTypeNotif,
  setSizeNotif,
  setOpenNotif,
}) {
  const classes = useStyles();

  const deletePlaylist = async (id) => {
    if (playlistAlbum.status !== 200) return false;
    await removePlaylist(id);
    setMsgNotif("Playlist successfully deleted!");
    setErrTypeNotif("success");
    setSizeNotif("100%");
    setOpenNotif(true);
    setPlayListRefresher(Math.random());
  };

  const checkPlaylist = async (e, id) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      const videoPlaylist = {
        ListId: id,
        VideoId: videoId,
      };

      await insertVideoInPlaylist(videoPlaylist);
      setMsgNotif("Video Playlist successfully added!");
      setErrTypeNotif("success");
      setSizeNotif("100%");
      setOpenNotif(true);
      setPlayListRefresher(Math.random());
    } else {
      await removeVideoInPlaylist(`${id}+${videoId}`);
      setMsgNotif("Video Playlist successfully removed!");
      setErrTypeNotif("success");
      setSizeNotif("100%");
      setOpenNotif(true);
      setPlayListRefresher(Math.random());
    }
  };

  return (
    <>
      <Paper
        className={classes.savePlaylistPaper}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <ClickAwayListener onClickAway={handleCloseMenuPlaylist}>
          <MenuList className={classes.savePlaylistMenu} autoFocusItem={openMenuPlaylist} onKeyDown={handleListKeyDown}>
            {playlistAlbum.data.value.map((playlistData) => (
              <MenuItem className={classes.savePlaylistMenuCheckbox}>
                {playlistData?.Entrys?.[0] ? (
                  <FormControlLabel control={<Checkbox checked onChange={(e) => checkPlaylist(e, playlistData.Id)} />} label={playlistData.Name} />
                ) : (
                  <FormControlLabel control={<Checkbox onChange={(e) => checkPlaylist(e, playlistData.Id)} />} label={playlistData.Name} />
                )}
                <FaTrashAlt style={{ color: "red" }} onClick={(id) => deletePlaylist(playlistData.Id)} />
              </MenuItem>
            ))}
            <MenuItem className={classes.savePlaylistCreate} onClick={handleOpenModalCNP}>
              Create New Playlist
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </>
  );
}
