import React from "react";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Bar } from "react-chartjs-2";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

export default function Graph({ classes, paymentGraphRender, iViewerGraphRender, options, inViewers, paymentTrans }) {
  return (
    <>
      <Grid item sm={6} xs={12}>
        <Card className={classes.CardRow2}>
          <CardContent>
            <Grid container>
              <Grid className={classes.CardIcon1} xs={2}>
                <LocalOfferIcon />
              </Grid>
              <Grid xs={9}>
                <Typography className={classes.title} color="white">
                  Payment Transaction
                </Typography>
                <Typography className={classes.pos}>{paymentTrans}</Typography>
              </Grid>
              <Grid xs={12}>
                <Bar data={paymentGraphRender} options={options} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Card className={classes.CardRow2}>
          <CardContent>
            <Grid container>
              <Grid className={classes.CardIcon1} xs={2}>
                <VisibilityOutlinedIcon />
              </Grid>
              <Grid xs={9}>
                <Typography className={classes.title} color="white">
                  Individual Viewers
                </Typography>
                <Typography className={classes.pos}>{inViewers}</Typography>
              </Grid>
              <Grid xs={12}>
                <Bar data={iViewerGraphRender} options={options} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
