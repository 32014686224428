import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    background: "#0a0e11",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: 50,
    paddingTop: 50,
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 100,
      paddingTop: 20,
    },
  },
  drawer: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  Speedial: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  SpeedialMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  speedialBackshadowDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  speedialBackshadowCurved1: {
    position: "fixed",
    bottom: theme.spacing(17.5),
    left: theme.spacing(26.3),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderBottomRightRadius: "30px",
  },
  speedialBackshadowCurved2: {
    position: "fixed",
    bottom: theme.spacing(18),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadowCurved3: {
    position: "fixed",
    bottom: theme.spacing(5.7),
    left: theme.spacing(26.4),
    background: "#141c22",
    width: "30px",
    height: "30px",
    zIndex: "1000",
    borderTopRightRadius: "30px",
  },
  speedialBackshadowCurved4: {
    position: "fixed",
    bottom: theme.spacing(7),
    left: theme.spacing(27.8),
    background: "#0a0e11",
    width: "20px",
    height: "20px",
    zIndex: "980",
  },
  speedialBackshadow: {
    position: "fixed",
    bottom: theme.spacing(8.5),
    left: theme.spacing(25.1),
    zIndex: "1000",
    background: "#0a0e11",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
  dashboardDiv: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  SettingsDiv: {
    width: "80%",
    padding: "20px 50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "20px 0",
    },
  },
  SettingsTextfieldDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  SettingsTextfield: {
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  SettingsSelect: {
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  SettingsSelectDiv: {
    border: "1px solid #6a6e72",
    borderRadius: "7px",
    color: "white !important",
  },
  SettingsTextarea: {
    background: "transparent",
    outline: "none",
    border: "none",
    border: "1px solid #6a6e72",
    borderRadius: "7px",
    resize: "none",
    fontSize: "15px",
    fontFamily: "manrope",
    color: "white",
    padding: "10px",
    margin: "10px 0",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  SettingsUpperButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 30px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  SettingsUpperButtonDiv2: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      margin: "10px 0",
    },
  },
  SettingsBottomButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0 30px",
  },
  SettingsButton: {
    background: "transparent",
    outline: "none",
    padding: "10px 20px",
    fontSize: "15px",
    fontWeight: "600",
    color: "#e4e7e9",
    borderRadius: "7px",
    border: "1px solid #6a6e72",
    cursor: "pointer",
    margin: "0 10px",
  },
  InviteButton: {
    background: "transparent",
    outline: "none",
    padding: "10px 20px",
    fontSize: "15px",
    fontWeight: "600",
    color: "#e4e7e9",
    borderRadius: "7px",
    border: "1px solid #6a6e72",
    cursor: "pointer",
    margin: "0 10px",
    [theme.breakpoints.down("xs")]: {
      width: "94%",
    },
  },
  SettingsButtonSave: {
    background: "#F29600",
    outline: "none",
    padding: "10px 20px",
    fontSize: "15px",
    fontWeight: "600",
    color: "#e4e7e9",
    borderRadius: "7px",
    border: "none",
    cursor: "pointer",
    margin: "0 10px",
  },
  contentDiv: {
    marginLeft: "50px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  //===================

  signOutDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
  },
  logoutModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logoutModalContainer: {
    background: "#2A3135",
    borderRadius: "7px",
    width: "40vw",
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },
  logoutModalHeader: {
    background: "#141D22",
    padding: "10px 15px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    outline: "none",
  },
  modalCloseDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  modalCloseButtonDiv: {
    width: "30px",
    height: "30px",
    background: "#2A3135",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutModalContent: {
    padding: "20px 40px",
  },
  logoutModalButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  logoutModalButtons: {
    background: "#F29600 !important",
    margin: "0 10px !important",
    color: "white !important",
  },
}));

export { useStyles };
