import { useRef, useState, useEffect } from "react";
import videojs from "video.js";
import "videojs-canvas-plugin";
import videojsqualityselector from "videojs-hls-quality-selector";
import "videojs-contrib-quality-levels";
import "video.js/dist/video-js.css";

export const VideoJS = (props) => {
  const { options, onReady, videoData, isVidAnalyzed } = props;
  const { vidTimeLabels, vidCaption } = videoData;
  const [videoProps, setVideoProps] = useState({
    playerTime: "0.00",
    playerDuration: "0",
    videoRecord: [],
    timeLabelDataDisp: [],
    Ocr: [],
  });

  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      player.VideoCanvasPlugin();
      player.hlsQualitySelector = videojsqualityselector;
      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      player.on("loadedmetadata", () => {
        const playerDuration = player.duration();
        const qualityLevels = player.qualityLevels();
        qualityLevels.selectedIndex_ = 0;
        qualityLevels.trigger({ type: "change", selectedIndex: 0 });

        if (playerDuration > 0) {
          const width = 640;
          const height = 360;
          const colors = [];
          try {
            const canvas = document.getElementsByTagName("canvas");
            const ctx = canvas[0].getContext("2d");
            ctx.drawImageOld = ctx.drawImage;
            ctx.drawImage = (video, x, y, w, h) => {
              ctx.drawImageOld(video, x, y, w, h);
              const playerTime = player.currentTime();
              setVideoProps({ playerTime });
              const finTags = [];

              getOcr();

              if (!vidTimeLabels) return false;

              vidTimeLabels.forEach((element) => {
                const a = element.VideoFrame.split("/"); // split it at the colons
                const framePos = a[0];
                const TotalFrames = a[1];
                const time = (framePos * playerDuration) / TotalFrames;
                let color;

                if (time > playerTime - 0.2 && time < playerTime + 0.2) {
                  const chColor = colors.find((item) => item.Name === element.Label);
                  if (chColor !== undefined) {
                    color = chColor.Color;
                  } else {
                    const newColor = `#${Math.random().toString(16).substr(-6)}`;
                    const obj = {};
                    obj.Name = element.Label;
                    obj.Color = newColor;
                    colors.push(obj);
                    color = newColor;
                  }
                  const obj = {};
                  obj.Name = element.Label;
                  obj.Color = color;

                  finTags.push(obj);
                }
                if (time > playerTime - 0.05 && time < playerTime + 0.05) {
                  const iW = parseFloat(element.ElementPosition.split(" ")[3]) * width;
                  const iH = parseFloat(element.ElementPosition.split(" ")[4]) * height;
                  const iX = parseFloat(element.ElementPosition.split(" ")[1]) * width - iW / 2;
                  const iY = parseFloat(element.ElementPosition.split(" ")[2]) * height - iH / 2;

                  ctx.beginPath();
                  ctx.rect(iX, iY, iW, iH);
                  ctx.strokeStyle = color;
                  ctx.lineWidth = 5;
                  ctx.stroke();
                }
              });
              const key = "Name";
              const arrayUniqueByKey = [...new Map(finTags.map((item) => [item[key], item])).values()];
              setVideoProps({ timeLabelDataDisp: arrayUniqueByKey });
            };
          } catch (error) {
            console.log("EERRRORR", error);
          }
        }
      });

      const getOcr = () => {
        const { videoRecord, playerDuration, playerTime } = videoProps;
        const ocr = [];
        if (playerDuration && playerTime) {
          videoRecord.forEach((element) => {
            const a = element.position.split("/"); // split it at the colons
            const framePos = a[0];
            const TotalFrames = a[1];
            const time = (framePos * playerDuration) / TotalFrames;
            if (time > playerTime - 0.2 && time < playerTime + 0.2) {
              const obj = {};
              obj.words = element.importantWords;
              obj.text = element.text;
              if (obj.words !== "" || obj.text !== "") {
                ocr.push(obj);
              }
            }
          });
          if (ocr.length > 0) {
            setVideoProps({ Ocr: ocr });
          }
        }
      };
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered vjs-fluid vjs-16-9">
        <track kind="captions" src={vidCaption} srcLang="en" label="English" default />
      </video>
    </div>
  );
};

export default VideoJS;
