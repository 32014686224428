import { useState } from "react";
import VideoDetailTabPanel from "../TabPanel/VideoDetailTabPanel";
import VideoDetailStyle from "../ImportStyles/VideoDetailStyle";
import { VimeoTags, AiLabelsHandler } from "../../ApiComponent/getApiFunction";
import SearchIcon from "@material-ui/icons/Search";
import { IoMdClose } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { Grid, Button, TextField, Box, FormControl, Modal, Typography } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationSnackbar from "../Notification/Snackbar";

export default function Tags(props) {
  const { tab_value, videoData, vidId, videoTags, setVideoTags, setTagName, setTagIndex, setVidPosition, setOpenPositionVideoTag, userType = "" } = props;

  const TabPanel = VideoDetailTabPanel(userType);
  const classes = VideoDetailStyle(userType);

  const [newTags, setNewTags] = useState("");
  const [btnStatusAddTag, setBbtnStatusAddTag] = useState(false);
  const [addTagsValidationMsg, setAddTagsValidationMsg] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [errTypeNotif, setErrTypeNotif] = useState("info");

  //!@@ set to Open modal to update title video
  const [openAddTag, setOpenAddTag] = useState(false);
  const handleOpenAddTag = () => {
    setOpenAddTag(true);
  };
  //!@@ set to Close modal of title video
  const handleCloseAddTag = () => {
    setOpenAddTag(false);
  };

  const closeNotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif(false);
  };

  const addTag = async () => {
    if (!newTags) {
      setAddTagsValidationMsg("Video tag field is required !");
      setOpenNotif(true);
      setErrTypeNotif("error");
      return false;
    } else {
      setBbtnStatusAddTag(true);
      setOpenAddTag(false);
    }

    try {
      const params = {
        Label: newTags,
        Position: "",
        VideoFrame: `0/${videoData?.vidTotalFrames ?? "0"}`,
        videoId: vidId,
      };

      await VimeoTags(params);

      setVideoTags([...videoTags, newTags]);
      setNewTags("");
    } catch (error) {}

    setBbtnStatusAddTag(false);
  };

  const onClickTag = async (e, index, tagName) => {
    setTagName(tagName);
    setTagIndex(index);

    const params = { vidId, tagName };

    const response = await AiLabelsHandler(params);

    if (!response?.data?.value) return setVidPosition(0);

    setVidPosition(response?.data?.value);
    setOpenPositionVideoTag(true);
  };

  if (!videoData.vidId) {
    return (
      <div xs={12} className={classes.loadingDesign}>
        <CircularProgress className={classes.loadingSvg} />
      </div>
    );
  }

  return (
    <>
      <Grid xs={12}>
        <TabPanel value={tab_value} index={0}>
          <Grid>
            <Grid container className={classes.addVideoTags}>
              <Grid xs={12} sm={11} className={classes.addVideoTagsDiv}>
                <FormControl fullWidth>
                  <TextField placeholder="Enter your own video tag" value={newTags} onChange={(e) => setNewTags(e.target.value)} variant="outlined" size="small" />
                </FormControl>
                <Button variant="contained" className={classes.ButtonContained} disabled={btnStatusAddTag} onClick={addTag}>
                  Add
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.addTagContainer}>
              <Grid className={classes.addSearchDiv}>
                <SearchIcon className={classes.searchIcon} />
                <button className={classes.addTagButton}>
                  <div className={classes.addTagButtonDiv} onClick={handleOpenAddTag}>
                    <AiOutlinePlus className={classes.addTagIcon} />
                    <p>Add tag</p>
                  </div>
                </button>
              </Grid>
              <Grid className={classes.tagContainer}>
                {videoTags.map((item, index) => {
                  return (
                    <Box className={classes.tags}>
                      {item}
                      <IoMdClose
                        className={classes.tagsDelete}
                        tagName={item}
                        onClick={(e) => {
                          onClickTag(e, index, item);
                        }}
                      />
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <NotificationSnackbar openNotif={openNotif} closeNotif={closeNotif} msgNotif={addTagsValidationMsg} errTypeNotif={errTypeNotif} />
      </Grid>
      {/*============================== Modal Add Tag===========================================*/}

      <Modal className={classes.modal} open={openAddTag} onClose={handleCloseAddTag}>
        <Box className={classes.modalLanguage}>
          <Grid className={classes.languageModalHeader}>
            <Grid className={classes.languageModalCloseDiv}>
              <Grid className={classes.languageModalCloseButtonDiv}>
                <CgClose className={classes.languageCloseIcon} onClick={handleCloseAddTag} />
              </Grid>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add tag
            </Typography>
          </Grid>
          <Grid className={classes.languageModalContent}>
            <Grid xs={12} sm={11} className={classes.addVideoTagsModalDiv}>
              <FormControl fullWidth>
                <TextField placeholder="Enter your own video tag" value={newTags} onChange={(e) => setNewTags(e.target.value)} variant="outlined" size="small" />
              </FormControl>
              <Button variant="contained" className={classes.ButtonContained} disabled={btnStatusAddTag} onClick={addTag}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
