import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Grid, Button, Typography, FormControl, Link } from "@material-ui/core";

import ToolbarSignUpStudent from "../Toolbar/ToolbarSignUpStudent";
import Ellipse1 from "../../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../../Landingpage/img/StudentEllipse2.png";
import { useStyles } from "../../Landingpage/StudentLogIn/StudentLoginStyle/StudentLoginStyle";

const SignUpStudentCodes = (props) => {
  const classes = useStyles();

  //======================OTP==============================
  const [code, setCode] = useState("");
  const handleChange = (code) => setCode(code);

  //======================End OTP========================

  //======================EndLogin==============================
  return (
    <div className={classes.container}>
      <Grid className={classes.Ellipse1Div}>
        <img src={Ellipse1} className={classes.Ellipse1} alt="Elipse" />
      </Grid>
      <Grid className={classes.Ellipse2Div}>
        <img src={Ellipse2} className={classes.Ellipse2} alt="Elipse" />
      </Grid>
      <ToolbarSignUpStudent />
      <Grid container>
        {/*First Container--------------------------------------------------------------------- */}

        <Grid container justifyContent="center">
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h5">
              <h3>Sign Up</h3>
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="subtitle1">Please check your inbox and enter the token below.</Typography>
          </Grid>
          <Grid xs={10} md={4} xl={2} className={classes.TextfieldSign}>
            <FormControl fullWidth variant="filled">
              <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <OtpInput
                  value={code}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span>-</span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "blue",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                />
              </Grid>
              <Button
                variant="outlined"
                style={{
                  marginTop: 30,
                  minHeight: 50,
                  color: "white",
                  background: "#F29600",
                }}>
                Sign Up
              </Button>
              <Grid xs={12} className={classes.TextTwo}>
                <Typography variant="subtitle1">
                  You didn’t received the token? <Link>Resend token</Link>
                </Typography>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {/*Footer--------------------------------------------------------------------- */}
    </div>
  );
};
export default SignUpStudentCodes;
