import "date-fns";
import React, { useEffect, useState } from "react";
import { Grid, Divider, Checkbox, TextField, Popover, MenuItem, InputLabel, ListItemIcon, ListItemText, Select, FormControl, InputAdornment, Fade } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CreatorDrawer from "./CreatorDrawer";

import { useStyles } from "./CreatorStyle/CreatorVideoStyle";
import SpeedialCreator from "./SpeedialCreator";
import MobileSpeedialCreator from "./MobileSpeedialCreator";
import CreatorFooterNavbar from "../CreatorFooterNavbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateRangePicker } from "materialui-daterange-picker";
import { AiOutlineCalendar } from "react-icons/ai";
import SearchIcon from "@material-ui/icons/Search";
import { vimeovideos, searchhistory, filterVideoViaSocMed } from "../../ApiComponent/getApiFunction";
import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { default as VideoGallery } from "../../AppComponents/Video/Gallery";

export default function CreatorDashboard(props) {
  const userType = "creator";
  const auth = localStorage.getItem("Auth") ?? "";
  const [selectedSocial, setSelectedSocial] = useState("");
  const [selectedDates, setSelectedDates] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [startSelectedDate, setStartSelectedDate] = useState(null);
  const [endSelectedDate, setEndSelectedDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const [videos, setVideos] = useState(null);
  const [searchHistoryList, setSearchHistory] = useState([]);
  const [getSearchHistoryList, setSearchHistoryList] = useState([]);
  const [dataRefresher, setDataRefresher] = useState("");
  const [isReload, setIsReload] = useState(true);

  const classes = useStyles();
  const Email = localStorage.getItem("Email");
  const popOpen = Boolean(anchorEl);
  const options = ["Duplicate", "Public", "Sold", "Bought", "Private"];
  const isAllSelected = options.length > 0 && selected.length === options.length;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  useEffect(() => {
    if (Object.keys(dateRange).length !== 0) {
      const startDate = dateRange?.startDate ? new Date(dateRange?.startDate) : "";
      const endDate = dateRange?.endDate ? new Date(dateRange?.endDate) : "";
      const rangeStart = formatDateRange(startDate);
      const rangeEnd = formatDateRange(endDate);
      const renderDates = `${rangeStart} - ${rangeEnd}`;
      setSelectedDates(renderDates);
      setStartSelectedDate(startDate?.toISOString() ?? "");
      setEndSelectedDate(endDate?.toISOString() ?? "");
      setAnchorEl(null);
      return true;
    }
  }, [dateRange]);

  useEffect(() => {
    getVideos();
    getSearchHistory();

    if (selectedSocial === "Tiktok") {
      fncFilterVideoViaSocMed("tiktok", auth);
    } else if (selectedSocial === "Youtube") {
      fncFilterVideoViaSocMed("youtube", auth);
    } else {
      fncFilterVideoViaSocMed("tiktok", auth);
      fncFilterVideoViaSocMed("youtube", auth);
    }
  }, [Search, selected, startSelectedDate, endSelectedDate, dataRefresher, selectedSocial]);

  const getVideos = async () => {
    setVideos(null);

    const params = {
      BoughtVideo: selected.indexOf("Bought") > -1 ? true : false,
      CompanyUrl: Email,
      Duplicated: selected.indexOf("Duplicate") > -1 ? true : false,
      EndDate: endSelectedDate,
      Id: "1",
      Limit: 12,

      Offset: 0,
      PageCount: 0,
      PublicShared: selected.indexOf("Public") > -1 ? true : false,
      SearchQuery: Search !== null ? Search : "",
      SearchType: "video",
      SellVideo: selected.indexOf("Sold") > -1 ? true : false,
      SharedVideo: selected.indexOf("Private") > -1 ? true : false,
      StartDate: startSelectedDate,
      getAll: true,
    };

    const response = await vimeovideos(params);

    if (!response?.data?.value) return;

    setVideos(response?.data?.value);

    return true;
  };

  const getSearchHistory = async () => {
    const params = {
      Id: "1",
      Offset: 0,
      PageCount: 0,
      SearchQuery: Search !== null ? Search : "",
      SearchType: "video",
    };

    const response = await searchhistory(params);

    if (!response?.data) return;

    setSearchHistory(response?.data);

    if (searchHistoryList.length !== 0) {
      var historySearch = [];
      for (let i = 0; i < searchHistoryList.length; i++) {
        historySearch.push(searchHistoryList[i].name);
      }
      setSearchHistoryList(historySearch);
    }

    if (Search === "") {
      setSearchHistoryList([]);
    }

    return true;
  };

  const fncFilterVideoViaSocMed = async (type, auth) => {
    await filterVideoViaSocMed(type, auth);
    return true;
  };

  const formatDateRange = (date) => {
    if (!date) return "";

    return moment(date).local().format("MM/DD/YYYY");
  };

  const searchHandle = (e) => {
    setSearch(e.target.value);
  };
  const onChangeSearchHandle = (event, value) => {
    setSearch(value);
  };
  const handleSocialMedia = (event) => {
    setSelectedSocial(event.target.value);
  };
  const openDateRangePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChanges = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const searchBar = createTheme({
    overrides: {
      MuiInput: {
        underline: {
          color: "white",
          "&:before": {
            color: "white",
            borderBottomColor: "#0A0E11",
          },
          "&:after": {
            color: "white",
            borderBottomColor: "#0A0E11",
          },
          "&:hover:before": {
            borderBottomColor: "#0A0E11",
          },
        },
      },
    },
  });

  const calendarContainer = createTheme({
    overrides: {
      MuiOutlinedInput: {
        adornedEnd: {
          paddingRight: "0px",
          color: "#fff",
          border: "1px solid #303030",
        },
      },
    },
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  function openSearchBar() {
    var titleDiv = document.getElementById("titleDivId");
    var searcBarDiv = document.getElementById("searchBarDivId");

    titleDiv.style.display = "none";
    searcBarDiv.style.display = "block";
  }

  function closeSearchBar() {
    var titleDiv = document.getElementById("titleDivId");
    var searcBarDiv = document.getElementById("searchBarDivId");

    titleDiv.style.display = "block";
    searcBarDiv.style.display = "none";
  }

  return (
    <div className={classes.containers}>
      <Grid className={classes.creatorSpeedial}>
        <SpeedialCreator setDataRefresher={setDataRefresher} isReload={isReload} setIsReload={setIsReload} userType={userType} />
        <Grid className={classes.speedialBackshadowDiv}>
          <Grid className={classes.speedialBackshadowCurved1}></Grid>
          <Grid className={classes.speedialBackshadowCurved2}></Grid>
          <Grid className={classes.speedialBackshadow}></Grid>
          <Grid className={classes.speedialBackshadowCurved3}></Grid>
          <Grid className={classes.speedialBackshadowCurved4}></Grid>
        </Grid>
      </Grid>
      <Grid className={classes.creatorSpeedialMobile} id="VideoSpeedDial">
        <Grid className={classes.speedialMobileBackshadowDiv}>
          <MobileSpeedialCreator setDataRefresher={setDataRefresher} isReload={isReload} userType={userType} />
        </Grid>
      </Grid>
      <Grid container className={classes.videoContainerDiv}>
        <Grid xs={0} md={3} lg={2} style={{ zIndex: 500 }}>
          <CreatorDrawer />
        </Grid>
        <Grid xs={12} sm={8} md={9} lg={10}>
          <Grid container style={{ display: "flex", alignItems: "center", height: "50px", marginBottom: 10 }}>
            <Grid xs={12} id="titleDivId">
              <Grid item xs={12} className={classes.titleDiv}>
                <h1 className={classes.Dashboard} style={{ marginTop: 10 }}>
                  My Videos
                </h1>
                <SearchIcon className={classes.searchIcon} onClick={openSearchBar} />
              </Grid>
            </Grid>
            <Grid xs={12} className={classes.titleDiv}>
              <Grid item xs={12} display="flex" className={classes.searchBarDiv} id="searchBarDivId">
                <Grid xs={12} className={classes.searchBarDivContainer}>
                  <ThemeProvider theme={searchBar}>
                    <Autocomplete
                      onKeyUp={searchHandle}
                      onChange={onChangeSearchHandle}
                      className={classes.searchBar}
                      freeSolo
                      size="small"
                      options={getSearchHistoryList}
                      renderInput={(params) => <TextField {...params} margin="normal" placeholder="Enter Your Search" />}
                    />
                    <HighlightOffIcon className={classes.searchBarClose} onClick={closeSearchBar} />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider variant="middle" className={classes.divider} />
          {/*===================================================Filter=======================*/}
          <Grid container className={classes.videosContainer} id="VideoFilter">
            <Grid item sm={2} xs={12} display="flex" alignItems="flex-start" className={classes.VideoFilterInput}>
              <ThemeProvider theme={calendarContainer}>
                <FormControl className={classes.formControl}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <AiOutlineCalendar style={{ cursor: "pointer", marginLeft: "20px", fontSize: "20px" }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Select date range"
                    variant="outlined"
                    onClick={openDateRangePicker}
                    size="small"
                    value={selectedDates}
                  />
                </FormControl>
              </ThemeProvider>
              <Popover
                id={"123"}
                open={popOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}>
                <DateRangePicker open={true} onChange={(range) => setDateRange(range)} toggle={handleClose} />
              </Popover>
            </Grid>
            <Grid item sm={2} xs={12} display="flex" alignItems="flex-start" className={classes.VideoFilterInput}>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <InputLabel shrink={false} style={{ color: "#727475", fontWeight: "bold" }}>
                  {selected == "" && "Status"}
                </InputLabel>
                <Select multiple value={selected} onChange={handleChanges} renderValue={(selected) => selected.join(", ")} MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}>
                    <ListItemIcon>
                      <Checkbox classes={{ indeterminate: classes.indeterminateColor }} checked={isAllSelected} indeterminate={selected.length > 0 && selected.length < options.length} />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={1} xs={12} display="flex" alignItems="flex-start" className={classes.VideoFilterInput}>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <InputLabel shrink={false}></InputLabel>
                <Select native value={selectedSocial} onChange={handleSocialMedia}>
                  <option selected value="" style={{ color: "#000" }}>
                    Source
                  </option>
                  <option selected value="Tiktok">
                    {" "}
                    TikTok
                  </option>
                  <option selected value="Youtube">
                    {" "}
                    Youtube{" "}
                  </option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/*===================================================End Filter=======================*/}
          <Grid id="VideoContent">
            <Grid container spacing={2} id="VideoContent">
              <VideoGallery userType={userType} videos={videos} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid id="VideoFooterNavBar">
        <CreatorFooterNavbar />
      </Grid>
    </div>
  );
}
