import React, { useState, useEffect, useRef } from "react";
import { Card, Grid, CardMedia, Typography, Button, IconButton, Paper, TextField, FormControl, FormGroup, FormControlLabel, Checkbox, Divider, Box, Link, InputLabel, Select } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import StudentDrawer from "../Student/StudentDrawer";
import image25 from "../img/image 25.png";
import image26 from "../img/image 26.png";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import { Stack } from "@mui/material";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SpeedialStudent from "./SpeedialStudent";
import FormLabel from "@material-ui/core/FormLabel";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useStyles } from "../../Dashboarad/Student/StudentStyle/StudentVideoDetailStyle";
import "../../Stylesheet/Student/StudentVideoDetails.scss";
import { Carousel } from "react-carousel-minimal";
import history from "../../Landingpage/history";
// DEV NEW COMPONENT
import ReactPlayerVideo from "./ReactPlayerVideo";
import VideoTags from "./VideoTags";
import VideoTextRecognition from "./VideoTextRecognition";
import VideoSpeechRecognition from "./VideoSpeechRecognition";
import { captionStyle, slideNumberStyle } from "./CarouselInfo";
import { ApiConfigHeaderV2, ApplicationHeader } from "../../ApiComponent/configHeaderAPI";
import { routesAPI, reprocessVideo, aivindexerLabels, AddVideoTranscription, changeThumbnail, savePlaylist, userPlayList } from "../../ApiComponent/getApiFunction";
import "../../Stylesheet/Student/StudentVideoDetails.scss";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import { default as VideoPlayer } from "../../AppComponents/Video/Player";
import { default as VideoDetails } from "../../AppComponents/Video/Details";
//====================Import Icons====================
import { BiEdit } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { BiShareAlt } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { FaClone } from "react-icons/fa";
import Playlist from "../../AppComponents/Video/Playlist";
import NotificationSnackbar from "../../AppComponents/Notification/Snackbar";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const GrayTypography = withStyles({
  root: {
    color: "#999B9D",
  },
})(Typography);

const timeline = createTheme({
  overrides: {
    MuiTimelineOppositeContent: {
      root: {
        flex: "0.01  !important",
      },
    },
  },
});

const shareModalTabs = createTheme({
  overrides: {
    PrivateTabIndicator: {
      root: {
        height: "50px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        background: "#2a3135 !important",
      },
    },
  },
});

const shareModalSocial = createTheme({
  overrides: {
    MuiBox: {},
  },
});

const tabs = createTheme({
  overrides: {
    MuiTabs: {
      fixed: {
        WebkitScrollbar: {
          display: "none",
        },
        overflow: "scroll !important",
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        background: "orange !important",
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: "13px !important",
      },
    },
  },
});
const tab1 = createTheme({
  overrides: {
    MuiBox: {
      root: {
        padding: 0,
      },
    },
    MuiInputBase: {
      root: {
        color: "white",
      },
      input: {
        border: "1px solid white",
        borderRadius: "8px",
      },
    },
  },
});

const playlistPopover = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#141D22 !important",
      },
    },
    // MuiList: {
    //   padding: {
    //     display: "none",
    //   },
    // },
  },
});

//====================================================== Main Tabs========================================================================
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//======================================================Modal Tabs========================================================================
function ModalTabPanel(props) {
  const { children, value1, index1, ...other1 } = props;
  return (
    <div role="tabpanel" hidden={value1 !== index1} id={`simple-tabpanel-${index1}`} aria-labelledby={`simple-tab-${index1}`} {...other1}>
      {value1 === index1 && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ModalTabPanel.propTypes = {
  children: PropTypes.node,
  index1: PropTypes.any.isRequired,
  value1: PropTypes.any.isRequired,
};

function ModalProps(index1) {
  return {
    id: `simple-tab-${index1}`,
    "aria-controls": `simple-tabpanel-${index1}`,
  };
}

export default function StudentVideoDetails() {
  const userType = "student";
  const vidId = localStorage.getItem("vidId");
  const [editTitle, setEditTitle] = useState("");
  const [titleVideo, setTitleVideo] = useState("");
  const [textTitleVideo, setTextTitleVideo] = useState("");
  const [textDescVideo, setTextDescVideo] = useState("");
  const [textDescVideoEdit, setTextDescVideoEdit] = useState("");
  const [statusBtnEditTitle, setStatusBtnEditTitle] = useState(false);
  const [speechToWordRecognition, setSpeechToWordRecognition] = useState("");
  const [dataTextRecognition, setDataTextRecognition] = useState({ scenes: [], videoRecord: [] });
  const [analyzedVideo, setAnalyzedVideo] = useState(false);
  const [distinctLabels, setDistinctLabels] = useState([]);
  const [playDynamicSeconds, setDynamicSeconds] = useState({ playedSeconds: 0, played: 0, loadedSeconds: 0, loaded: 0 });
  const socialShareUrl = "https://vaip-dev.xaasfarm.com/student/videodetails/";
  const GetCurrentUrl = window.location.href;
  const [isCopied, setCopied] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isSnackBar, setSnackBar] = useState(false);
  const [isDisabledSocialShare, setDisableSocialShare] = useState(true);
  const { id } = useParams();
  const embededCode = `<iframe width="560" height="315" src=${GetCurrentUrl} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  const [authorizedConfig, setAuthorizedConfig] = useState({ token: localStorage.getItem("token"), auth: localStorage.getItem("Auth"), Email: localStorage.getItem("Email") });
  const [dataRefresher, setDataRefresher] = useState("");
  const [isVidAnalyzed, setIsVidAnalyzed] = useState(false);
  const [videoTags, setVideoTags] = useState([]);
  const [videoRecordData, setVideoRecordData] = useState([]);
  const [videoData, setVideoData] = useState({});
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [value1, setValueModal] = useState(0);
  const [valueEdit, setValueModalEdit] = useState(0);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [valueCNP, setValueCNP] = useState(0);
  const [openModalCNP, setOpenModalCNP] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);
  const [valueTN, setValueModalTN] = useState(0);
  const [openModalTN, setOpenModalTN] = useState(false);
  const prevOpen = useRef(openMenu);
  const [openMenuPlaylist, setOpenMenuPlayList] = useState(false);
  const anchorRefplaylist = useRef(null);
  const prevOpenPlaylist = useRef(openMenuPlaylist);
  const [openMenuVideoOption, setOpenMenuVideoOption] = useState(false);
  const anchorRefVideoOption = useRef(null);
  const prevOpenVideoOption = useRef(openMenuVideoOption);
  const [isOpenDialogBox, setOpenDialog] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [isReload, setIsReload] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(0);
  const [isSnackbarDownload, setSnackbarDownlaod] = useState(false);
  const [createPlaylist, setCreatePlaylist] = useState("");
  const [playlistAlbum, setplayListAlbum] = useState([]);
  const [playListRefresher, setPlayListRefresher] = useState(null);
  const durationNotif = 2000;
  const [msgNotif, setMsgNotif] = useState("");
  const [sizeNotif, setSizeNotif] = useState("");
  const [errTypeNotif, setErrTypeNotif] = useState("info");
  const [openNotif, setOpenNotif] = useState(false);

  const closeNotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif(false);
  };
  //======================================================Close Snackbar Validation========================================================================

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar(false);
  };
  //======================================================Copy Clipboard========================================================================

  const copyToClipboard = () => {
    if (!isCopied) {
      setSnackBar(true);
      setValidationMessage("successfully Copy to Clipboard");
    }
  };
  //======================================================enabled the social share to public=======================================================================
  const publicSocialMediaHandler = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setDisableSocialShare(false);
    } else {
      setDisableSocialShare(true);
    }
  };

  //======================================================get public videos=======================================================================
  const getPublicVideo = async () => {
    const { data } = await ApplicationHeader().get(`${routesAPI.publicVideo}('${vidId}')`);
    try {
      if (!data) return;
      setVideoData({
        vidAnalyzed: data.AnalyzedDate ?? "",
        vidCaption: data?.Caption ?? "",
        vidSpeechToWord: data?.ComplVoiceTextResult,
        vidDesc: data?.Description ?? null,
        vidDistinctLabels: data?.DistinctLabels ?? "",
        vidId: data?.Id ?? "",
        vidLabels: data?.Labels ?? "",
        vidOrgFile: data?.OrgFile ?? "",
        vidScenes: data?.Scenes ?? "",
        vidSpeechAnalyzed: data?.SpeechAnalyzed ?? "",
        vidSource: data?.Source ?? "",
        vidStorageUrl: data?.StorageUrl ?? "",
        vidTextAnalyzed: data?.TextAnalyzed ?? "",
        vidThumbnail: data?.Thumbnail ?? "",
        vidTitle: data?.Title ?? "",
        vidTimeLabels: data?.TimeLabels ?? "",
        vidTotalFrames: data?.TotalFrames ?? "",
        vidUploaded: data?.UploadedDate ?? "",
        vidVideoType: data?.VideoType ?? "",
        VidDuration: data?.VideoDuration ?? "",
      });
      setTitleVideo(data.Title ?? "");
      setTextTitleVideo(data.Title ?? "");
      setTextDescVideo(data.Description ?? "");
      setTextDescVideoEdit(data.Description ?? "");
      setSpeechToWordRecognition(data.ComplVoiceTextResult ?? "");
      setVideoTags(data.DistinctLabels ?? []);

      if (data?.SpeechAnalyzed === true && data?.TextAnalyzed === true) {
        setIsVidAnalyzed(true);
      }
    } catch (e) {}
  };
  //======================================================Get Video Record By Id================================================================
  const getVideoRecordData = async () => {
    const videoRecordData = await ApplicationHeader().get(`${routesAPI.VideoRecord}('${vidId}')`);
    if (!videoRecordData) return;
    setVideoRecordData(videoRecordData?.data?.value ?? []);
  };
  //======================================================initials getPublicVideos Declaration function==========================================

  useEffect(() => {
    getPublicVideo();
  }, [dataRefresher]);
  //======================================================initials getVideoRecordData Declaration function=======================================
  useEffect(() => {
    getVideoRecordData();
  }, []);
  //======================================================onchange change tab panel==============================================================

  const onChangeHadleTab = (event, newValue) => {
    setValue(newValue);
  };
  //======================================================onchange modal Tab======================================================================

  const handleChangeModal = (event, newValue) => {
    setValueModal(newValue);
  };
  //======================================================set to Open modal to update title video=================================================

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  //======================================================set to Close modal of title video======================================================

  const handleClose = () => {
    setOpen(false);
  };
  //======================================================Modal Change Language==================================================================z

  const [openLanguage, setOpenLanguage] = useState(false);
  const handleCloseLanguage = () => setOpenLanguage(false);
  const [btnAnalyzeVideoStatus, setBtnAnalyzeVideoStatus] = useState(false);
  const [loadingState, setLoadingState] = useState({
    readyState: false,
    valueState: "Start analyzing",
  });

  const startAnalyzingVideo = async () => {
    setOpenLanguage(false);
    setBtnAnalyzeVideoStatus(true);

    const params = { vidId, selectedLanguage };

    await AddVideoTranscription(params);

    setLoadingState({
      readyState: true,
      valueState: "Please wait..........",
    });

    setTimeout(() => {
      setBtnAnalyzeVideoStatus(false);
      setIsVidAnalyzed(true);
    }, 5000);

    setDataRefresher(Math.random());

    return true;
  };
  //====================Modal Delete Video tag====================
  const [tagName, setTagName] = useState("");
  const [tagIndex, setTagIndex] = useState("");
  const [vidPosition, setVidPosition] = useState(0);
  const [openPositionVideoTag, setOpenPositionVideoTag] = useState(false);
  const handleClosePositionVideoTag = () => setOpenPositionVideoTag(false);
  const [btnDeleteTagStatus, setBtnDeleteTagStatus] = useState(false);

  const onClickDeleteTag = async () => {
    setBtnDeleteTagStatus(true);

    const params = { vidId, tagName };

    await aivindexerLabels(params);

    let newTags = videoTags;
    newTags.splice(tagIndex, 1);

    setVideoTags(newTags);

    setBtnDeleteTagStatus(false);
    setOpenPositionVideoTag(false);
  };
  //====================onChange Language get Value====================
  const changeLanguageHandler = (event) => {
    setSelectedLanguage(event.target.value);
  };

  //====================get value of input value in modal and set value in variable====================
  const handleChangeModalEdit = (event, newValue) => {
    setValueModal(newValue);
  };

  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };
  //====================Modal Create Playlist====================
  const handleChangeCNP = (event, newValue) => {
    setValueCNP(newValue);
  };
  const handleOpenModalCNP = () => {
    setOpenModalCNP(true);
    console.log("it's working!");
  };
  const handleCloseModalCNP = () => {
    setOpenModalCNP(false);
  };
  //====================Modal set thumbnails====================
  const handleChangeModalTN = (event, newValue) => {
    setValueModal(newValue);
  };
  //====================Modal set delete video====================
  const [openDeleteVid, setOpenDeleteVid] = React.useState(false);
  const handleOpenDeleteVid = () => setOpenDeleteVid(true);
  const handleCloseDeleteVid = () => setOpenDeleteVid(false);

  //====================set to open Modal for Change thumbnail====================
  const [imageThumbnail, setImageThumbnail] = useState([]);
  const handleOpenModalTN = () => {
    setImageThumbnail([]);
    let storeArrayImageThumbnail = [];
    videoData?.vidScenes?.map((result_item) => {
      storeArrayImageThumbnail.push({ image: result_item.Image });
    });
    setImageThumbnail(storeArrayImageThumbnail);

    setOpenModalTN(true);
  };

  const [btnSaveThumbnail, setBtnSaveThumbnail] = useState(false);
  const saveThumbnail = async () => {
    setBtnSaveThumbnail(true);
    let saveImg = "";
    const images = document.getElementsByClassName("carousel-item fade");

    if (!images) {
      setBtnSaveThumbnail(false);
      return false;
    }

    for (let i = 0; i < images.length; i++) {
      if (images[i].attributes.style.value === "max-width: 850px; max-height: 500px; display: block;") {
        const divImage = images[i].getElementsByClassName("carousel-image");

        if (!divImage && !divImage[0].currentSrc) continue;
        saveImg = divImage[0].currentSrc;
        break;
      }
    }

    if (!saveImg) {
      setBtnSaveThumbnail(false);
      return false;
    }

    const params = {
      Id: videoData?.vidId,
      Thumbnail: saveImg.split("/").pop(),
    };
    await changeThumbnail(params);
    setBtnSaveThumbnail(false);
    handleCloseModalTN();
  };

  const handleCloseModalTN = () => {
    setOpenModalTN(false);
  };

  const handleEditOnTitle = async () => {
    setStatusBtnEditTitle(true);
    await ApplicationHeader().put(`${routesAPI.aivindexerLabels}/${vidId}`, {
      Description: null,
      Id: vidId,
      Name: editTitle,
      Notes: "",
      RegisseurId: "",
    });

    setEditTitle("");
    setStatusBtnEditTitle(false);
    setOpenModalEdit(false);
    setTitleVideo(textTitleVideo);
  };
  const handleEditTitleChange = (e) => {
    const { value = "" } = e.target;
    setEditTitle(value);
    setTextTitleVideo(value);
  };
  //====================Set to open menu Options====================
  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };
  //====================Set close menu Options====================
  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }
  //====================check if menu is open and set focus====================
  const CheckPrevMenu = () => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openMenu;
  };
  //====================Menu Option PlayList====================
  const handleTogglePlaylist = () => {
    setOpenMenuPlayList((prevOpenPlaylist) => !prevOpenPlaylist);
  };

  const handleCloseMenuPlaylist = (event) => {
    setOpenMenuPlayList(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuPlayList(false);
    }
  }
  //====================check if achorRef is open and set focus====================

  const anchorRefplaylistFunc = () => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openMenu;
  };
  //====================initialize CheckPrevMenu and anchorRefplaylistFunc Function====================
  useEffect(() => {
    CheckPrevMenu();
    anchorRefplaylistFunc();
  }, [openMenu, openMenuPlaylist]);

  //====================Video Toggle Manipulate Dom using vanilla JS====================
  function videoToggle() {
    var toggleButton = document.getElementById("videoToggle");
    var toggleContent = document.getElementById("videoContent");
    var videoHeader = document.getElementById("videoHeader");
    var videoBody1 = document.getElementById("body1");
    var videoToggleImage = document.getElementById("videoToggleImage");
    var videoToggleText = document.getElementById("videoToggleText");

    if (toggleButton.checked == true) {
      toggleContent.style.display = "block";
      toggleContent.style.height = "100vh";
      toggleContent.style.marginTop = "0 !important";
      videoHeader.style.display = "none";
      videoBody1.style.display = "none";
      videoToggleImage.style.display = "none";
      videoToggleText.innerHTML = "Tap to close video";
    } else {
      toggleContent.style.display = "none";
      videoHeader.style.display = "block";
      videoBody1.style.display = "block";
      videoToggleImage.style.display = "block";
      videoToggleText.innerHTML = "Swipe up to view video";
    }
  }

  //====================Video Option Playlist====================

  const handleToggleVideoOption = () => {
    setOpenMenuVideoOption((prevOpenVideoOption) => !prevOpenVideoOption);
  };

  const handleCloseMenuVideoOption = (event) => {
    if (anchorRefVideoOption.current && anchorRefVideoOption.current.contains(event.target)) {
      return;
    }
    setOpenMenuVideoOption(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuVideoOption(false);
    }
  }

  const menuVideoOptions = () => {
    if (prevOpenVideoOption.current === true && openMenuVideoOption === false) {
      anchorRefVideoOption.current.focus();
    }
    prevOpenVideoOption.current = openMenuVideoOption;
  };
  //====================check open MenuVideo Option====================

  useEffect(() => {
    menuVideoOptions();
  }, [openMenuVideoOption]);

  const handleOpenDialogBox = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };
  //====================Download Video====================

  const snackbarDownloadClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarDownlaod(false);
  };

  const downloadVideo = () => {
    setSnackbarDownlaod(true);

    handleToggle();
    const { vidOrgFile, vidTitle } = videoData;
    let xhr = new XMLHttpRequest();
    xhr.open("GET", vidOrgFile, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      let urlCreator = window.URL || window.webkitURL;
      let url = urlCreator.createObjectURL(this.response);
      let tag = document.createElement("a");
      tag.href = url;
      tag.target = "_blank";
      tag.download = vidTitle;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };

    xhr.onerror = function (err) {
      console.log("error");
    };

    xhr.onprogress = function (e) {
      var percent = (e.loaded / e.total) * 100;
      percent = Math.floor(percent);
      setDownloadPercent(percent);
    };
    xhr.send();
  };

  const reprocessVideos = async () => {
    reprocessVideo(id).then(() => {
      window.location = "/#/student/video";
    });
  };

  const deleteVideos = async () => {
    await ApiConfigHeaderV2(authorizedConfig?.token, authorizedConfig?.auth)
      .delete(`${routesAPI.deleteVideo}('${id}')`)
      .then(() => {
        window.location = "/#/student/video";
      });
  };

  const createPlaylistField = (e) => {
    const { value } = e.target;
    setCreatePlaylist(value);
  };

  const submitPlaylist = async () => {
    if (!createPlaylist) return false;
    const playlistPayload = {
      Name: createPlaylist,
      User: localStorage.getItem("Email"),
    };
    await savePlaylist(playlistPayload);
    setOpenModalCNP(false);
    setPlayListRefresher(Math.random());
  };

  const getPlaylist = async () => {
    const getUserPlaylist = await userPlayList(localStorage.getItem("Email"));
    setplayListAlbum(getUserPlaylist);
  };

  useEffect(() => {
    getPlaylist();
  }, [playListRefresher]);

  return (
    <div className={classes.containers}>
      <Grid className={classes.studentSpeeddial}>
        <SpeedialStudent setDataRefresher={setDataRefresher} isReload={isReload} setIsReload={setIsReload} />
        <Grid className={classes.speedialBackshadowDiv}>
          <Grid className={classes.speedialBackshadowCurved1}></Grid>
          <Grid className={classes.speedialBackshadowCurved2}></Grid>
          <Grid className={classes.speedialBackshadow}></Grid>
          <Grid className={classes.speedialBackshadowCurved3}></Grid>
          <Grid className={classes.speedialBackshadowCurved4}></Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.videoDetailsDiv}>
        <Grid xs={0} md={2} className={classes.studentDrawer}>
          <StudentDrawer />
        </Grid>
        <Grid xs={12} md={9} lg={10} xl={12} className={classes.videoDetailsContent}>
          <Grid xs={12} className={classes.Header} id="videoHeader">
            <Grid container className={classes.VideoDiv}>
              <Grid xs={12} md={12} className={classes.VideoTitle}>
                <Typography style={{ color: "#999B9D" }}>
                  <IoIosArrowBack className={classes.VideoHeaderBack} onClick={() => history.push("/business/video")} />
                  <h5 className={classes.VideoHeaderBackText} onClick={() => history.push("/business/video")}>
                    Back to My Videos
                  </h5>
                </Typography>
                <Grid>
                  <IconButton ref={anchorRef} aria-controls={open ? "menu-list-grow" : undefined} className={classes.ButtonThreeDots} onClick={handleToggle}>
                    <BsThreeDots />
                  </IconButton>
                  <Button startIcon={<BiShareAlt />} variant="contained" className={classes.ButtonShare} onClick={handleOpen}>
                    SHARE
                  </Button>
                </Grid>
              </Grid>

              <Grid xs={12} md={12} className={classes.Share}>
                <Typography style={{ color: "white" }}>
                  <h1 className={classes.videoHeaderTitle}>
                    {titleVideo}
                    <IconButton onClick={handleOpenModalEdit}>
                      <BiEdit />
                    </IconButton>
                  </h1>
                </Typography>

                <Typography style={{ color: "white" }}>
                  <h4>Sascha Ladewig . 1.523 views</h4>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={12} md={7} style={{ display: "flex", justifyContent: "flex-end" }}>
              <AppBar elevation={0} position="static" style={{ background: "#141c22", color: "white" }}>
                <Tabs value={value} onChange={onChangeHadleTab} aria-label="simple tabs example" className={classes.tabs}>
                  <Tab style={{ zIndex: 1 }} label="Video Tags" {...a11yProps(0)} />
                  <Tab style={{ zIndex: 1 }} label="Speech-to-Word Recognition" {...a11yProps(1)} />
                  <Tab style={{ zIndex: 1 }} label="Text Recognition" {...a11yProps(2)} />
                  <Tab style={{ zIndex: 1 }} label="Notes" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} md={7} className={classes.Body1} id="body1" style={{ color: "white" }}>
              {/* Tabpanel One*/}

              <ThemeProvider theme={tab1}>
                <VideoTags
                  tab_value={value}
                  videoData={videoData}
                  vidId={vidId}
                  videoTags={videoTags}
                  setVideoTags={setVideoTags}
                  setTagName={setTagName}
                  setTagIndex={setTagIndex}
                  setVidPosition={setVidPosition}
                  setOpenPositionVideoTag={setOpenPositionVideoTag}
                />
              </ThemeProvider>
              <TabPanel value={value} index={1}>
                <VideoSpeechRecognition
                  videoData={videoData}
                  isVidAnalyzed={isVidAnalyzed}
                  setOpenLanguage={setOpenLanguage}
                  btnAnalyzeVideoStatus={btnAnalyzeVideoStatus}
                  loadingState={loadingState}
                  speechToWordRecognition={speechToWordRecognition}
                />
              </TabPanel>
              <Grid xs={12}>
                <TabPanel value={value} index={2}>
                  <VideoTextRecognition
                    playDynamicSeconds={playDynamicSeconds}
                    videoData={videoData}
                    videoRecordData={videoRecordData}
                    isVidAnalyzed={isVidAnalyzed}
                    setOpenLanguage={setOpenLanguage}
                    btnAnalyzeVideoStatus={btnAnalyzeVideoStatus}
                    loadingState={loadingState}
                  />
                </TabPanel>
              </Grid>
              {/* Tabpanel Four*/}
              <ThemeProvider theme={tab1}>
                <Grid xs={12}>
                  <TabPanel value={value} index={3}>
                    <Grid container className={classes.addVideoTags}>
                      <Grid xs={12} sm={11} className={classes.addVideoTagsDiv}>
                        <FormControl fullWidth>
                          <TextField placeholder="Write your note here" variant="outlined" size="small" />
                        </FormControl>
                        <Button variant="contained" className={classes.ButtonContained}>
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    <ThemeProvider theme={timeline}>
                      <Timeline className={classes.oppositeContent}>
                        <TimelineItem>
                          <TimelineOppositeContent className={classes.oppositeContent} color="textSecondary"></TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Grid container>
                              <Grid xs={4}>
                                <CardMedia className={classes.mediaTwo} image={image25} />
                              </Grid>
                              <Grid xs={8}>
                                <Grid container spacing={3} style={{ padding: 30 }}>
                                  <Typography>
                                    A video description is a piece of metadata that helps YouTube understand the content of a video. Along with your video title and tags, YouTube uses your description
                                    to understand the content.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem style={{ background: "#222628", borderRadius: 16 }}>
                          <TimelineOppositeContent className={classes.oppositeContent} color="textSecondary"></TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Grid container>
                              <Grid xs={4}>
                                <CardMedia className={classes.mediaTwo} image={image26} />
                              </Grid>
                              <Grid xs={8}>
                                <Grid container spacing={3} style={{ padding: 30 }}>
                                  <Typography>
                                    A video description is a piece of metadata that helps YouTube understand the content of a video. Along with your video title and tags, YouTube uses your description
                                    to understand the content.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </ThemeProvider>
                  </TabPanel>
                </Grid>
              </ThemeProvider>
            </Grid>
            <Grid xs={12} md={5} className={classes.Body2} id="videoContent">
              <Card className={classes.CardRow3}>
                <VideoPlayer
                  classes={classes}
                  setDynamicSeconds={setDynamicSeconds}
                  videoData={videoData}
                  vidId={vidId}
                  anchorRefplaylist={anchorRefplaylist}
                  handleTogglePlaylist={handleTogglePlaylist}
                  userType={userType}
                  anchorRefVideoOption={anchorRefVideoOption}
                  handleToggleVideoOption={handleToggleVideoOption}
                  isVidAnalyzed={isVidAnalyzed}
                />
              </Card>
              <Grid container>
                <VideoDetails
                  classes={classes}
                  videoData={videoData}
                  anchorRefplaylist={anchorRefplaylist}
                  handleTogglePlaylist={handleTogglePlaylist}
                  textDescVideo={textDescVideo}
                  setTextDescVideo={setTextDescVideo}
                  textDescVideoEdit={textDescVideoEdit}
                  setTextDescVideoEdit={setTextDescVideoEdit}
                  userType={userType}
                />
              </Grid>
            </Grid>
            <Grid className={classes.videoToggleContainer}>
              <Grid className={classes.videoToggleDiv}>
                <Grid style={{ width: "120px" }}>
                  <Grid className={classes.videoToggleImage} id="videoToggleImage">
                    <img src={videoData.vidThumbnail} style={{ width: "100px", height: "55px" }} />
                  </Grid>
                </Grid>
                <Typography style={{ color: "#B3B6B8", display: "flex", alignItems: "center" }}>
                  <h3 id="videoToggleText" style={{ color: "#B3B6B8", display: "flex", alignItems: "center" }}>
                    Swipe up to view video
                  </h3>
                </Typography>
                <input style={{ display: "none" }} type="checkbox" id="videoToggle" onClick={videoToggle} />
                <label htmlFor="videoToggle" style={{ color: "#F29600", fontSize: 40 }}>
                  <IoIosArrowUp />
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar open={isSnackBar} autoHideDuration={3000} onClose={closeSnackBar} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert severity="success" variant="filled" onClose={closeSnackBar} sx={{ width: "100%" }}>
            {validationMessage}
          </Alert>
        </Snackbar>

        <Snackbar open={`${Math.round(downloadPercent)}` === "100" ? false : isSnackbarDownload} onClose={snackbarDownloadClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert severity="info" color="info" onClose={snackbarDownloadClose}>
            <Typography variant="body2" color="text.primary">
              Download Progress.... {`${Math.round(downloadPercent)}%`}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={downloadPercent} />
            </Box>
          </Alert>
        </Snackbar>
        <NotificationSnackbar openNotif={openNotif} closeNotif={closeNotif} errTypeNotif={errTypeNotif} msgNotif={msgNotif} sizeNotif={sizeNotif} durationNotif={durationNotif} />
      </Grid>
      {/*============================== Modal Language===========================================*/}
      <Modal className={classes.modal} open={openLanguage} onClose={handleCloseLanguage}>
        <Box className={classes.modalLanguage}>
          <Grid className={classes.languageModalHeader}>
            <Grid className={classes.languageModalCloseDiv}>
              <Grid className={classes.languageModalCloseButtonDiv}>
                <CgClose className={classes.languageCloseIcon} onClick={handleCloseLanguage} />
              </Grid>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Language
            </Typography>
          </Grid>
          <Grid className={classes.languageModalContent}>
            <Grid className={classes.languageModalDiv}>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <Select native className={classes.languageModalTextfield} onChange={changeLanguageHandler}>
                  <option value="en-US">English</option>
                  <option value="de-DE">Germany</option>
                </Select>
              </FormControl>
              <Button variant="contained" onClick={startAnalyzingVideo} className={classes.ButtonContained}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/*==============================End Modal Language===========================================*/}
      {/*============================== Modal Video Tag Position====================================*/}
      <Modal className={classes.modal} open={openPositionVideoTag} onClose={handleClosePositionVideoTag}>
        <Box className={classes.modalLanguage}>
          <Grid className={classes.languageModalHeader}>
            <Grid className={classes.languageModalCloseDiv}>
              <Grid className={classes.languageModalCloseButtonDiv}>
                <CgClose className={classes.languageCloseIcon} onClick={handleClosePositionVideoTag} />
              </Grid>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Confirmation
            </Typography>
          </Grid>
          <Grid className={classes.languageModalContent}>
            <Grid className={classes.languageModalDiv}>
              <Grid xs={12} style={{ padding: 10 }}>
                <Typography className={classes.TextDanger}>This Item was found at {vidPosition} positions.</Typography>
                <Typography>Are you sure you wish to delete this item?</Typography>
              </Grid>
              <Grid className={classes.tagDeleteButton}>
                <Button variant="contained" onClick={onClickDeleteTag} disabled={btnDeleteTagStatus} className={classes.ButtonContained}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/*==============================End Modal Video Tag Position===========================*/}
      {/*============================== Modal Share===========================================*/}
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.papers}>
            <AppBar position="static" className={classes.shareModalHeaderContainer}>
              <Grid className={classes.shareModalHeaderDiv}>
                <Grid className={classes.shareModalCloseDiv}>
                  <Grid className={classes.shareModalCloseButtonDiv}>
                    <CgClose onClick={handleClose} />
                  </Grid>
                </Grid>
                <h3>Share Video</h3>
              </Grid>
              <ThemeProvider theme={shareModalTabs}>
                <Tabs value={value1} onChange={handleChangeModal}>
                  <Tab style={{ zIndex: 1 }} label="Private " {...ModalProps(0)} />
                  <Tab style={{ zIndex: 1 }} label="Public" {...ModalProps(1)} />
                </Tabs>
              </ThemeProvider>
            </AppBar>
            <ModalTabPanel value1={value1} index1={0}>
              <Grid container>
                <Grid xs={12} sm={9}>
                  <FormControl fullWidth>
                    <TextField placeholder="Enter email address" variant="outlined" size="small" />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={3}>
                  <Button variant="contained" className={classes.ButtonContained}>
                    Send Invite
                  </Button>
                </Grid>
              </Grid>
              <Grid xs={12} align="center">
                <GrayTypography variant="h5" style={{ padding: 50 }}>
                  You didn’t share the video with anyone yet
                </GrayTypography>
              </Grid>
            </ModalTabPanel>
            <ThemeProvider>
              <ModalTabPanel value1={value1} index1={1}>
                <FormGroup>
                  <FormControlLabel style={{ color: "white", marginBottom: 30 }} control={<Checkbox onChange={(e) => publicSocialMediaHandler(e)} />} label="Select to make it public" />
                </FormGroup>

                <Stack direction="row" spacing={2} justify="center" style={{ marginBottom: 30 }}>
                  <EmailShareButton url={`${GetCurrentUrl}`} separator={""} disabled={isDisabledSocialShare}>
                    <EmailIcon size={50} round={true} />
                  </EmailShareButton>

                  <TelegramShareButton url={`${GetCurrentUrl}`} disabled={isDisabledSocialShare}>
                    <TelegramIcon size={50} round={true} />
                  </TelegramShareButton>

                  <WhatsappShareButton url={`${GetCurrentUrl}`} disabled={isDisabledSocialShare}>
                    <WhatsappIcon size={50} round={true} />
                  </WhatsappShareButton>

                  <FacebookShareButton url={socialShareUrl} quote={`vaip.ai - ${titleVideo}`} disabled={isDisabledSocialShare}>
                    <FacebookIcon size={50} round={true} />
                  </FacebookShareButton>

                  <TwitterShareButton url={socialShareUrl} via={`vaip.ai - ${titleVideo}`} disabled={isDisabledSocialShare}>
                    <TwitterIcon size={50} round={true} />
                  </TwitterShareButton>

                  <LinkedinShareButton url={socialShareUrl} source={`vaip.ai - ${titleVideo}`} disabled={isDisabledSocialShare}>
                    <LinkedinIcon size={50} round={true} />
                  </LinkedinShareButton>

                  <RedditShareButton url={`${GetCurrentUrl}`} disabled={isDisabledSocialShare}>
                    <RedditIcon size={50} round={true} />
                  </RedditShareButton>
                </Stack>
                <Divider style={{ marginBottom: "10px" }} />
                <CopyToClipboard text={GetCurrentUrl} onCopy={() => setCopied(isDisabledSocialShare)}>
                  <Link style={{ color: "#F29600", fontSize: 13, cursor: "pointer" }} onClick={copyToClipboard}>
                    Copy to clipboard
                  </Link>
                </CopyToClipboard>

                <Accordion
                  style={{ width: "400px", padding: "0px", margin: "0px" }}
                  disableGutters
                  elevation={0}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}>
                  <AccordionSummary gutters={false}>
                    <Typography style={{ fontSize: "13px", marginTop: "-10px" }}> Get embeded code</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CopyToClipboard text={embededCode} onCopy={() => setCopied(true)}>
                      <button style={{ float: "right", cursor: "pointer" }}>
                        <FaClone />{" "}
                      </button>
                    </CopyToClipboard>
                    <Typography style={{ fontSize: "13px", paddingTop: "30px", width: "400px", textAlign: "justifyContent" }} color="text.secondary">
                      {embededCode}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </ModalTabPanel>
            </ThemeProvider>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Share===========================================*/}
      {/*==============================Modal Edit===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalEdit}
        onClose={handleCloseModalEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalEdit}>
          <div className={classes.papersEdit}>
            <Grid container xs={12}>
              <Grid style={{ paddingRight: 10 }} xs={9} sm={10}>
                <FormControl className={classes.changeNameTextfield} fullWidth>
                  <TextField placeholder="Edit.." value={textTitleVideo} variant="outlined" size="small" onChange={handleEditTitleChange} />
                </FormControl>
              </Grid>
              <Grid xs={3} sm={2}>
                <Button variant="contained" className={classes.ButtonContained} disabled={statusBtnEditTitle} onClick={handleEditOnTitle}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Edit===========================================*/}
      {/*==============================Modal Create Playlist===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalCNP}
        onClose={handleCloseModalCNP}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalCNP}>
          <div className={classes.papersCreatePlaylist}>
            <Typography variant="h6" className={classes.addPlaylistHeader}>
              Create new playlist{" "}
              <Grid className={classes.playlistModalCloseButtonDiv}>
                <CgClose onClick={handleCloseModalCNP} />
              </Grid>
            </Typography>
            <Grid container>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <TextField placeholder="Enter new playlist name" variant="outlined" size="small" onChange={createPlaylistField} />
                </FormControl>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <FormControl component="fieldset" style={{ marginTop: 30, marginBottom: 30 }}>
                <FormLabel component="legend">Enter new playlist name</FormLabel>
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group" style={{ color: "white" }}>
                  <FormControlLabel value="Public" control={<Radio />} label="Public" />
                  <FormControlLabel value="Private" control={<Radio />} label="Private" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Divider />
            <Grid
              xs={12}
              style={{
                padding: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <Box mr={2}>
                <Button variant="outlined" onClick={handleCloseModalCNP}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button variant="contained" className={classes.ButtonContained} onClick={submitPlaylist}>
                  Create Playlist
                </Button>
              </Box>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Create Playlist===========================================*/}
      {/*==============================Modal Thumbnail===========================================*/}
      <Modal
        className={classes.modal}
        open={openModalTN}
        onClose={handleCloseModalTN}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalTN}>
          <div className={classes.changeThumbnailContainer}>
            <AppBar className={classes.changeThumbnailHeader} position="static">
              <Grid className={classes.shareModalCloseDiv}>
                <Grid className={classes.shareModalCloseButtonDiv}>
                  <CgClose onClick={handleCloseModalTN} />
                </Grid>
              </Grid>
              <h2>Change Thumbnail</h2>
            </AppBar>

            <Grid container>
              <Grid xs={12}>
                <Carousel
                  data={imageThumbnail}
                  time={2000}
                  width="850px"
                  height="500px"
                  captionStyle={captionStyle}
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  captionPosition="bottom"
                  automatic={false}
                  dots={true}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    maxHeight: "500px",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12}>
                <Button onClick={saveThumbnail} disabled={btnSaveThumbnail} variant="contained" className={classes.ButtonContainedThumbnail} style={{ float: "right", margin: "10px" }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*==============================End Modal Thumbnail===========================================*/}
      {/*Popper Menu*/}
      <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper style={{ background: "#141D22", zIndex: "1500" }}>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={openMenu} onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={() => reprocessVideos()}> Reprocess</MenuItem>
                  <Divider />
                  <MenuItem onClick={() => handleOpenModalTN()}>Change thumbnail</MenuItem>
                  <Divider />
                  <MenuItem>Label editor</MenuItem>
                  <Divider />
                  <MenuItem onClick={downloadVideo}>Download</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleOpenDeleteVid} style={{ color: "red" }}>
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* Delete Confirmation Dialog */}
      <Modal open={openDeleteVid} onClose={handleCloseDeleteVid} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={classes.DeleteVidModal}>
        <Box className={classes.DeleteVidModalContainer}>
          <Grid className={classes.DeleteVidModalHeader}>
            <Grid className={classes.modalCloseDiv}>
              <Grid className={classes.modalCloseButtonDiv}>
                <CgClose onClick={handleCloseDeleteVid} />
              </Grid>
            </Grid>
            <Typography variant="h6" component="h2">
              Confirmation Delete
            </Typography>
          </Grid>
          <Grid className={classes.DeleteVidModalContent}>
            <Typography sx={{ mt: 2 }}>Are you sure to Delete this Video?</Typography>
            <Grid className={classes.DeleteVidModalButtonDiv}>
              <Button autoFocus onClick={handleCloseDeleteVid} className={classes.DeleteVidModalButtonCancel}>
                <typography>Cancel</typography>
              </Button>
              <Button onClick={() => deleteVideos()} className={classes.DeleteVidModalButtons}>
                <typography>Yes</typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Popper open={openMenuPlaylist} anchorEl={anchorRefplaylist.current} role={undefined} transition disablePortal>
        {/* ======================================================================================================== */}
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Playlist
              handleCloseMenuPlaylist={handleCloseMenuPlaylist}
              openMenuPlaylist={openMenuPlaylist}
              handleListKeyDown={handleListKeyDown}
              playlistAlbum={playlistAlbum}
              handleOpenModalCNP={handleOpenModalCNP}
              classes={classes}
              userType={userType}
              videoId={id}
              setSnackBar={setSnackBar}
              setPlayListRefresher={setPlayListRefresher}
              setMsgNotif={setMsgNotif}
              setErrTypeNotif={setErrTypeNotif}
              setSizeNotif={setSizeNotif}
              setOpenNotif={setOpenNotif}
            />
          </Grow>
        )}
      </Popper>

      {/*====================End Popper Playlist====================*/}
      {/*====================Popper Video Options====================*/}

      <Popper open={openMenuVideoOption} anchorEl={anchorRefVideoOption.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper className={classes.videoOptionsContainer}>
              <ClickAwayListener onClickAway={handleCloseMenuVideoOption}>
                <MenuList autoFocusItem={openMenuVideoOption} onKeyDown={handleListKeyDown}>
                  <MenuItem>
                    {" "}
                    <FormControlLabel control={<Checkbox />} label="Object detaction" />
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label="Speech-to-Word Recognition" />
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <FormControlLabel control={<Checkbox />} label="Text Recognition" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/*====================End Popper Video Options====================*/}
    </div>
  );
}
