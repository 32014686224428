import React from "react";
import { Grid, Toolbar, Button, CardContent, Typography, Container, Card, CardHeader } from "@material-ui/core";
import Footer from "./../Footer";
import history from "./../history";
import VaipAi from "../../Landingpage/img/vaipAi.png";
import Ellipse1 from "../../Landingpage/img/CreatorEllipse1.png";
import Ellipse2 from "../../Landingpage/img/StudentEllipse2.png";
import { FaCheck } from "react-icons/fa";
import { useStyles } from "../StudentLogIn/StudentLoginStyle/StudentPricingStyle";

export default function Pricing(theme) {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.container}>
        <Grid className={classes.Ellipse1Div}>
          <img src={Ellipse1} className={classes.Ellipse1} alt="Elipse" />
        </Grid>
        <Grid className={classes.Ellipse2Div}>
          <img src={Ellipse2} className={classes.Ellipse2} alt="Elipse" />
        </Grid>

        <Toolbar className={classes.appbarWrapper}>
          <h1 className={classes.appbarTitle} onClick={() => history.push("/")}>
            <img src={VaipAi} className={classes.logo} alt="logo" />
          </h1>
          <Button onClick={() => history.push("/pricingstudent")} style={{ color: "white", marginRight: 20, textTransform: "none" }} className={classes.pricingButton}>
            Pricing
          </Button>
          <Button variant="outlined" style={{ marginRight: 20, textTransform: "none" }} className={classes.signInButton} onClick={() => history.push("/sign-in/student")}>
            {" "}
            Sign-In
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/sign-up/student")}
            style={{ color: "white", background: "#f29600", textTransform: "none" }}
            className={classes.ButtonSignUp}>
            Sign-Up
          </Button>
        </Toolbar>

        <Grid container>
          <Grid xs={12} className={classes.TextOne}>
            <Typography variant="h5">
              <h3>Pricing</h3>
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.TextTwo}>
            <Typography variant="h5">An attractive monthly subscription based on storage & AI processing</Typography>
          </Grid>
        </Grid>
        {/*First Container--------------------------------------------------------------------- */}

        <Grid container style={{ zIndex: "5" }} className={classes.pricingCardsDiv}>
          <Grid xs={11} lg={10} xl={9} container alignItems="flex-end" justifyContent="space-between">
            {/*First Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>Storage</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>Type Account</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>AI Processing</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardFirst}>
                <CardContent>
                  <Typography>10% Fee for Sales and Payments Transactions</Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Second Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    FREE
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#ffff" }} className={classes.pricingPrice}>
                    0 €/m
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#f29600",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>24h video time</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Single user</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Scheduled</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#f29600" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Third Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    Basic
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#ffff" }} className={classes.pricingPrice}>
                    10 € / m
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#f29600",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>1,200h video time</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Single user</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Prioritized</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#f29600" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Fourth Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardHeader title="RECOMMENDED" style={{ background: "#f29600" }} titleTypographyProps={{ align: "center", variant: "subtitle1" }} className={classes.pricingHeader} />
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    Team
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#f29600" }} className={classes.pricingPrice}>
                    50 €/m
                  </Typography>
                  <Button
                    style={{
                      color: "#fff",
                      background: "#f29600",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>

              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>1,200h video time</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Single user</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Prioritized</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#f29600" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*Fifth Card--------------------------------------------------------------------- */}
            <Grid sm={2} xs={12}>
              <Card className={classes.CardUpper}>
                <CardContent align="center" style={{ background: "#0A0E11", color: "#B3B6B8" }}>
                  <Typography variant="h5" gutterBottom className={classes.pricingTitle}>
                    Free
                  </Typography>
                  <Typography variant="h3" gutterBottom style={{ color: "#ffff" }} className={classes.pricingPrice}>
                    3000 €
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#f29600",
                      minWidth: 150,
                      minHeight: 50,
                      fontSize: "15px",
                    }}
                    className={classes.pricingButton}>
                    Register
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Unlimited</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Team</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardMiddle}>
                <CardContent align="center">
                  <Typography>Immediate</Typography>
                </CardContent>
              </Card>
              <Card className={classes.CardCheck}>
                <CardContent>
                  <Typography variant="h5">
                    <FaCheck style={{ color: "#f29600" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/*Footer--------------------------------------------------------------------- */}
        <Footer />
      </Grid>
    </div>
  );
}
