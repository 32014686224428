import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import Error from "../Dashboarad/img/404.png";
import history from "../../src/Landingpage/history";
import { useStyles } from "../Error-Pages/PageNotFoundCss";
export default function PageNotFound() {
  const classes = useStyles();
  return (
    <Grid container className={classes.Container}>
      <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={Error} alt="Logo" className={classes.Logo} style={{ margin: 0 }} />
      </Grid>
      <Grid xs={7}>
        <Typography variant="h5" className={classes.TextTitle}>
          The page you are looking for might have been removed had its name change or is temporarily unavailable.
        </Typography>
      </Grid>
      <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" size="medium" className={classes.ButtonGoHome} onClick={() => history.push("/")}>
          GO HOME
        </Button>
      </Grid>
    </Grid>
  );
}
