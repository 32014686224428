import React from "react";
import { Grid, CardMedia, Chip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { useStyles } from "../../Dashboarad/Student/StudentStyle/StudentDashboardStyle";
import history from "../../Landingpage/history";

export default function TopVideos({ topVideosAnalytics }) {
  const classes = useStyles();
  console.log(topVideosAnalytics);
  return (
    <>
      <Grid item xs={12}>
        <h1 className={classes.Dashboard}>Your Top Videos</h1>
      </Grid>

      <Grid container spacing={2}>
        {topVideosAnalytics.map((topVideos) => (
          <Grid item xs={12} md={3}>
            <Card className={classes.CardRow3} style={{ cursor: "pointer" }}>
              <CardMedia className={classes.media} image={topVideos} />
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
