import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import CssBaseline from "@material-ui/core/CssBaseline";
// REDUX
import { Provider } from "react-redux";
import Store from "./Store/Redux/ReduxIndex";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={Store}>
        <CssBaseline />
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  rootElement
);
