import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "../../Dashboarad/Student/StudentStyle/StudentDashboardStyle";

export default function CurveDesign() {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.speedialBackshadowDiv}>
        <Grid className={classes.speedialBackshadowCurved1}></Grid>
        <Grid className={classes.speedialBackshadowCurved2}></Grid>
        <Grid className={classes.speedialBackshadow}></Grid>
        <Grid className={classes.speedialBackshadowCurved3}></Grid>
        <Grid className={classes.speedialBackshadowCurved4}></Grid>
      </Grid>
    </>
  );
}
