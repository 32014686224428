import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    background: "#141D22",
  },
  FontWhite: {
    color: "white",
  },

  TextOne: {
    display: "flex",
    color: "white",
    margin: "auto",
    flexGrow: 1,
    textAlign: "center",

    justifyContent: "center",
  },
  TextTwo: {
    marginTop: 10,
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    color: "#999B9D",
    cursor: "pointer",
  },
  TextTwoTitle: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  TextfieldSign: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  multilineColor: {
    color: "#fff",
    border: "1px solid #B3B6B8",
  },

  Ellipse1Div: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
    width: "50%",
    [theme.breakpoints.up("xl")]: {
      width: "40%",
    },
  },
  Ellipse1: {
    width: "100%",
  },
  Ellipse2Div: {
    position: "absolute",
    top: theme.spacing(0),
    left: theme.spacing(0),
    zIndex: "0",
    width: "40%",
    [theme.breakpoints.up("xl")]: {
      top: theme.spacing(-15),
      left: theme.spacing(0),
      zIndex: "0",
      width: "33%",
    },
  },
  Ellipse2: {
    width: "100%",
  },
  signUpButton: {
    marginTop: 30,
    minHeight: 50,
    color: "white",
    background: "#F29600",
  },

  ButtonSignin: {
    background: "#F29600",
    marginTop: 30,
    minHeight: 50,
    color: "white",
    width: "100%",
    [theme.breakpoints.down(400)]: {
      width: "100%",
    },
  },
  resendTokenContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(400)]: {
      marginLeft: 10,
      fontSize: 12,
    },
  },
  OtpInput: {
    [theme.breakpoints.down(400)]: {
      marginLeft: 10,
    },
  },
  signinSubTitle: {
    marginTop: "10px",
    marginBottom: "40px",
    display: "flex",
    justifyContent: "center",
    color: "#999b9d",
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
    },
  },
  resendTokenMessage: {
    color: "#fff",
    fontWeight: "200 !important",
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
    },
  },
}));
export { useStyles };
